import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import CTCarousel from '../../common/CTCarousel';

const EventAll = ({ eventGeoLocation }) => {
  return (
    <CTCarousel
      title={'All Events'}
      subTitle={'Explore what’s going on around you!'}
      data={eventGeoLocation}
      link={'/allevents'}
    />
  );
};
const mapStateToProps = (state) => ({
  eventGeoLocation: get(state, 'event.eventGeoLocation', {}),
});
export default connect(mapStateToProps)(EventAll);
