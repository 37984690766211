import React, { useState } from 'react';
import SuccessMessage from './SuccessMessage';
//import LocationForm from './LocationForm';
import { BiBold, BiItalic, BiUnderline } from 'react-icons/bi';
import { BsIndent, BsRocket } from 'react-icons/bs';
//import { PiUploadBold } from 'react-icons/pi';
import { CTWrapper } from '../../common';
import { MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { sendFormData, uploadImage } from '../../../redux/actions/eventActions';
import { v4 as uuidv4 } from 'uuid';
import parse from 'html-react-parser';
import Event from '../../../Images/merchant/event-bg.jpg';
// import EventLocation from './EventLocation';

const EventForm = () => {
  // const [showLocationForm, setShowLocationForm] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [selectedVenueType, setSelectedVenueType] = useState('');
  const [currentSection, setCurrentSection] = useState(1);
  //  const [location, setLocation] = useState({ latitude: '', longitude: '' })
  const [sendCopyEnabled, setSendCopyEnabled] = useState(false);
  const [pocEnabled, setPocEnabled] = useState(false);
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    name: '',
    description: '',
    subdescription: '',
    eventCoverPhoto: null,
    eventCoverPhotoUrl: '',
    eventType: '',
    eventAddress: '',
    location: [],
    categoryDisplay: '',
    eventVideoLink: '',
    dateString: '',
    eventTime: '',
    duration: '',
    language: '',
    contentType: '',
    organiserName: '',
    organiserPhone: '',
    organiserEmail: '',
    organiserWebsite: '',
    pocName: '',
    pocPhone: '',
    pocEmail: '',
    pocGreet: false,
    sendPhone: '',
    sendEmail: '',
    artistName: '',
    artistDescription: '',
    artistImage: null,
    artistImageUrl: '',
    artistInstaUrl: '',
    artistFacebookUrl: '',
    artistTwitterUrl: '',
    ticketTypes: [],
    policies: [],
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'eventCoverPhoto' || name === 'artistImage') {
      const file = files[0];
      if (file) {
        const fileKey = uuidv4();
        const reader = new FileReader();
        reader.onload = () => {
          setValues({
            ...values,
            [name]: {
              actualFile: file,
              fileName: file.name,
              fileSize: file.size,
              fileType: file.type,
              fileKey: fileKey,
            },
            [`${name}Url`]: reader.result,
          });

          uploadImageAndReturnUrl(file, fileKey)
            .then((url) => {
              const imageUrl = url;
              console.log('Uploaded URL:', imageUrl[0]);
              setValues({
                ...values,
                [`${name}Url`]: imageUrl[0],
              });
            })
            .catch((error) => {
              console.error('Error uploading image:', error);
            });
        };
        reader.readAsDataURL(file);
      }
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };
  // const handleEventImageChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const data = new FormData();
  //     data.append('file', file);
  //     dispatch(uploadImage('eventCoverPhotoUrl', data));
  //   }
  // };

  // const handleArtistImageChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const data = new FormData();
  //     data.append('file', file);
  //     dispatch(uploadImage('artistImageUrl', data));
  //   }
  // };

  const uploadImageAndReturnUrl = (file, fileKey) => {
    const dto = {
      fileNames: [fileKey],
      folderType: 'EVENT_REQUESTS',
    };
    return dispatch(uploadImage(dto))
      .then((response) => {
        if (response && Array.isArray(response) && response.length > 0 && typeof response[0] === 'string') {
          return response[0];
        } else {
          throw new Error('Invalid response format');
        }
      })

      .catch((error) => {
        console.error('Error uploading image:', error);
        throw error;
      });
  };
  // const uploadImageAndReturnUrl = (file, fileKey) => {
  //   const dto = {
  //     fileNames: [fileKey],
  //     folderType: "EVENT_REQUESTS",
  //   };
  //   return dispatch(uploadImage(dto))
  //   .then(response => response.data || '');
  // };

  const [ticketType, setTicketType] = useState({
    code: '',
    name: '',
    desc: '',
    price: '',
    singleton: false,
    maxBookingsAllowed: '',
    maxSeats: '',
  });

  const [policiesType, setPoliciesType] = useState({
    title: '',
    description: '',
  });

  const handleAddTicketType = () => {
    setValues({
      ...values,
      ticketTypes: [...values.ticketTypes, ticketType],
    });
    setTicketType({
      code: '',
      name: '',
      desc: '',
      price: '',
      singleton: false,
      maxBookingsAllowed: '',
      maxSeats: '',
    });
  };

  const handleDeleteTicketType = (index) => {
    const updatedTicketTypes = values.ticketTypes.filter((_, i) => i !== index);
    setValues({ ...values, ticketTypes: updatedTicketTypes });
  };

  const handleAddPolicy = () => {
    setValues({
      ...values,
      policies: [...values.policies, policiesType],
    });
    setPoliciesType({ title: '', description: '' });
  };

  const handleDeletePolicy = (index) => {
    const updatedPolicies = values.policies.filter((_, i) => i !== index);
    setValues({ ...values, policies: updatedPolicies });
  };
  const navigate = useNavigate();

  // const uploadImageAndReturnUrl = (fileDetails) => {
  //   const { fileKey, actualFile } = fileDetails;
  //   const dto = {
  //     fileNames: [fileKey],
  //     folderType: EFolderType.EVENT_REQUESTS,
  //   };
  //   return dispatch(uploadImage(dto));
  // };

  // const handleImageUpload = (fileDetails, setImageUrl) => {
  //   uploadImageAndReturnUrl(fileDetails)
  //     .then((url) => {
  //       setImageUrl(url);
  //     })
  //     .catch((error) => {
  //       console.error("Error uploading image:", error);
  //     });
  // };

  const handleSubmit = (e, buttonClicked) => {
    e.preventDefault();
    if (buttonClicked === 'listEvent') {
      const formData = {
        ...values,
        eventCoverPhotoUrl: values.eventCoverPhotoUrl,
        categoryDisplay: values.categoryDisplay,
        eventVideoLink: values.eventVideoLink,
      };
      console.log('Form Data:', formData);
      dispatch(sendFormData(formData));
      setShowSuccessMessage(true);
    } else if (buttonClicked === 'next') {
      setCurrentSection((prev) => prev + 1);
    }
  };

  // const handleLocationButtonClick = () => {
  //   setShowLocationForm(true);
  // };

  // const handleLocationFormClose = (location) => {
  //   setLocation({ latitude: location.latitude, longitude: location.longitude });
  //   setShowLocationForm(false);
  // };

  const applyFormatting = (tag, field) => {
    const textarea = document.getElementById(field);
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    const selectedText = textarea.value.substring(startPos, endPos);

    if (!selectedText) {
      alert('Please select some text to apply formatting.');
      return;
    }
    let replacement;
    switch (tag) {
      case 'a':
        const url = prompt('Enter the URL for the link:');
        replacement = `<${tag} href="${url}">${selectedText}</${tag}>`;
        break;
      case 'b':
      case 'i':
      case 'u':
      case 'blockquote':
        replacement = `<${tag}>${selectedText}</${tag}>`;
        break;
      default:
        console.error(`Unsupported tag: ${tag}`);
        return;
    }
    const updatedField = values[field].substring(0, startPos) + replacement + values[field].substring(endPos);

    setValues({
      ...values,
      [field]: updatedField,
    });
  };

  return (
    <div className="relative bg-white/20 lg:-mt-[35px]">
      <div
        className="absolute inset-0 bg-white/20 opacity-70"
        style={{ backgroundImage: `url(${Event})`, backgroundSize: 'cover' }}
      />
      {/* <div style={{ backgroundImage: `url(${Event})`, backgroundSize: 'cover' }} className="bg-white/50 p-4 lg:-mt-[35px]" > */}
      <CTWrapper>
        <div className="z-[10] py-8">
          <header className="p-4 ">
            <div className=" relative flex w-full items-center justify-center  ">
              <h2 className="text-center text-xl  font-bold text-white lg:text-3xl">List Your Event</h2>
            </div>
            <div className="relative mt-4 flex flex-row gap-4 rounded-md  border border-[#FEDF89] bg-[#FFFCF5] p-4">
              <BsRocket className="size-12 rotate-45 rounded-full border-4 border-[#FEDF89] bg-[#F79009] p-2 text-[#FFFFFF]" />{' '}
              <p className="text-base text-[#F79009] lg:text-lg">
                Fill up these details or you can always contact us on{' '}
                <b>
                  {' '}
                  <a href="tel:8447734227">+91 84477 34227</a> / <a href="tel:9031716589">+91 90317 16589</a>
                </b>
                . We will handle everything for you so you can enjoy.
              </p>
            </div>
          </header>
          <div className="relative z-[10] p-4 ">
            <form onSubmit={handleSubmit} className="space-y-6 text-base">
              {currentSection === 1 && (
                <>
                  {/* <div className="flex relative flex-row gap-4 rounded-md  border border-[#FEDF89] bg-[#FFFCF5] p-4">
                    <BsRocket className="size-12 rotate-45 rounded-full border-4 border-[#FEDF89] bg-[#F79009] p-2 text-[#FFFFFF]" />{' '}
                    <p className="text-base text-[#F79009] lg:text-lg">
                      Fill up these details or you can always contact us on{' '}
                      <b>
                        {' '}
                        <a href="tel:8447734227">+91 84477 34227</a> / <a href="tel:9031716589">+91 90317 16589</a>
                      </b>
                      . We will handle everything for you so you can enjoy.
                    </p>
                  </div> */}
                  <div className="rounded-md bg-white p-4 shadow-md">
                    <label htmlFor="name" className="mb-2 block  text-base font-semibold ">
                      Event Name: <span className="font-bold text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={values.name}
                      required
                      onChange={handleInputChange}
                      className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF] px-3 py-2 text-base shadow"
                    />
                  </div>

                  <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                    {/* <div className="rounded-md bg-white p-4 shadow-md">
                      <label className="mb-2 mt-4 block text-base font-semibold text-[#344054]">
                        Event Cover Photo:
                      </label>
                      <div style={{ width: 'full', height: '200px', border: '1px solid #EAECF0', borderRadius: '4px' }}>
                        {values.eventCoverPhotoUrl ? (
                          <img
                            src={values.eventCoverPhotoUrl}
                            alt="Event Cover"
                            className="size-full rounded-md object-fill shadow"
                          />
                        ) : (
                          <div className="flex size-full items-center justify-center rounded-md border-[#EAECF0] bg-[#FFFFFF] p-4 text-center text-base text-gray-400 shadow">
                            Event Cover Photo <br /> (Not Mandatory at all, With Greet we all also handle all your
                            designs absolutely free of cost. Just Message Us.)
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label className="mb-2 mt-4 block text-base font-semibold text-[#344054]">Upload Photo:</label>
                      <label
                        htmlFor="eventCoverPhoto"
                        className=" flex min-h-[200px] w-full flex-col items-center justify-center rounded-md border border-[#EAECF0] bg-[#FFFFFF] px-3 py-2 text-center text-base  text-gray-400 shadow"
                      >
                        <input
                          type="file"
                          accept="image/*"
                          className="hidden"
                          id="eventCoverPhoto"
                          name="eventCoverPhoto"
                          onChange={handleEventImageChange}
                        />
                        <PiUploadBold className="size-9" />
                        <br />
                        <p className="font-bold text-rose-600">Click to upload</p> <br /> SVG, PNG, JPG or GIF (max.
                        800x400px)
                      </label>
                    </div> */}

                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label htmlFor="subdescription" className="mb-2 block text-base font-semibold text-[#344054]">
                        Short Event Description:
                      </label>
                      <div className="mb-2 flex items-center space-x-2">
                        <button
                          type="button"
                          className="font-bold"
                          onClick={() => applyFormatting('b', 'subdescription')}
                        >
                          <BiBold />
                        </button>
                        <button type="button" className="italic" onClick={() => applyFormatting('i', 'subdescription')}>
                          <BiItalic />
                        </button>
                        <button
                          type="button"
                          className="underline"
                          onClick={() => applyFormatting('u', 'subdescription')}
                        >
                          <BiUnderline />
                        </button>
                        {/* <button type="button" onClick={() => applyFormatting('a', 'subdescription')}><BiLink /></button> */}
                        <button type="button" onClick={() => applyFormatting('blockquote', 'subdescription')}>
                          <BsIndent />{' '}
                        </button>
                      </div>
                      <textarea
                        id="subdescription"
                        name="subdescription"
                        value={values.subdescription}
                        onChange={handleInputChange}
                        placeholder="Enter short event description (You can fill this we can fill this for you.)"
                        className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF] px-3  py-2 text-base shadow"
                        rows="8"
                      />
                      <div className="whitespace-pre text-base font-normal"> {parse(values.subdescription)} </div>
                    </div>
                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label htmlFor="description" className="mb-2 block text-base font-semibold text-[#344054]">
                        Event Details: <span className="font-bold text-red-500">*</span>
                      </label>
                      <div className="mb-2 flex items-center space-x-2">
                        <button type="button" className="font-bold" onClick={() => applyFormatting('b', 'description')}>
                          <BiBold />
                        </button>
                        <button type="button" className="italic" onClick={() => applyFormatting('i', 'description')}>
                          <BiItalic />
                        </button>
                        <button type="button" className="underline" onClick={() => applyFormatting('u', 'description')}>
                          <BiUnderline />
                        </button>
                        {/* <button type="button" onClick={() => applyFormatting('a', 'description')}><BiLink /></button> */}
                        <button type="button" onClick={() => applyFormatting('blockquote', 'description')}>
                          <BsIndent />{' '}
                        </button>
                      </div>
                      <textarea
                        id="description"
                        name="description"
                        value={values.description}
                        onChange={handleInputChange}
                        placeholder="Enter event details  (You can fill this we can fill this for you.)"
                        className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF] px-3  py-2 text-base shadow"
                        rows="8"
                      />
                      <div className="whitespace-pre text-base font-normal"> {parse(values.description)}</div>
                    </div>
                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label htmlFor="dateString" className="mb-2 block text-base font-semibold text-[#344054]">
                        Event Date:
                      </label>
                      <input
                        type="date"
                        id="dateString"
                        name="dateString"
                        value={values.dateString}
                        onChange={handleInputChange}
                        className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                      />
                    </div>
                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label htmlFor="eventTime" className="mb-2 block text-base font-semibold text-[#344054]">
                        Event Time:
                      </label>
                      <input
                        type="time"
                        id="eventTime"
                        name="eventTime"
                        value={values.eventTime}
                        onChange={handleInputChange}
                        className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                      />
                    </div>
                    <div className="w-full rounded-md bg-white p-4 shadow-md">
                      <label htmlFor="duration" className="mb-2 block text-base font-semibold text-[#344054]">
                        Event Duration:
                      </label>
                      <select
                        id="duration"
                        name="duration"
                        value={values.duration}
                        onChange={handleInputChange}
                        className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                      >
                        <option value="">Select Duration</option>
                        <option value="1 hour">1 hour</option>
                        <option value="2 hours">2 hours</option>
                        <option value="3 hours">3 hours</option>
                        <option value="Half day">Half day</option>
                        <option value="Full day">Full day</option>
                      </select>
                    </div>
                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label
                        htmlFor="eventVideoLink"
                        className="mb-2 block shrink-0 text-base font-semibold text-[#344054]"
                      >
                        Event Youtube Link:
                      </label>
                      <input
                        type="text"
                        id="eventVideoLink"
                        name="eventVideoLink"
                        value={values.eventVideoLink}
                        onChange={handleInputChange}
                        className="flex w-full rounded border border-gray-300 px-3 py-2"
                      />
                    </div>
                    <div className="w-full rounded-md bg-white p-4 shadow-md">
                      <label htmlFor="categoryDisplay" className="mb-2 block text-base font-semibold text-[#344054]">
                        Event Category:
                      </label>
                      <select
                        id="categoryDisplay"
                        name="categoryDisplay"
                        value={values.categoryDisplay}
                        onChange={handleInputChange}
                        className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                      >
                        <option value="">Select Category</option>
                        <option value="PARTIES">PARTIES</option>
                        <option value="HOLI">HOLI</option>
                        <option value="LIVE SHOWS">LIVE SHOWS</option>
                        <option value="ADVENTURE">ADVENTURE</option>
                        <option value="ENTERTAINMENT">ENTERTAINMENT</option>
                        <option value="CAMPUS">CAMPUS</option>
                        <option value="PROFESSIONAL">PROFESSIONAL</option>
                        <option value="NON PROFITS">NON PROFITS</option>
                        <option value="OTHERS">OTHERS</option>
                      </select>
                    </div>
                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label htmlFor="eventType" className="mb-2 block text-base font-semibold text-[#344054]">
                        Event Type: <span className="font-bold text-red-500">*</span>
                      </label>
                      <select
                        id="eventType"
                        name="eventType"
                        value={values.eventType}
                        required
                        onChange={handleInputChange}
                        className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                      >
                        <option value="">Select Event Type</option>
                        <option value="FCFS">FCFS</option>
                        <option value="FREE_FCFS">FREE_FCFS</option>
                        <option value="SEAT_BOOKING">Seat Booking Event</option>
                        <option value="CONTINUOUS">Continous Event</option>
                      </select>
                    </div>
                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label htmlFor="language" className="mb-2 block shrink-0 text-base font-semibold text-[#344054]">
                        Event Language:
                      </label>
                      <input
                        type="text"
                        id="language"
                        name="language"
                        value={values.language}
                        onChange={handleInputChange}
                        className="flex w-full rounded border border-gray-300 px-3 py-2"
                      />
                    </div>
                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label htmlFor="contentType" className="mb-2 block text-base font-semibold text-[#344054]">
                        Suitable Age Group:
                      </label>
                      <select
                        id="contentType"
                        name="contentType"
                        value={values.contentType}
                        onChange={handleInputChange}
                        className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF] px-3 py-2 shadow"
                      >
                        <option value="">Select Age Group</option>
                        <option value="Family Friendly">Family Friendly</option>
                        <option value="Kids">Kids</option>
                        <option value="Adults">Adults</option>
                      </select>
                    </div>
                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label htmlFor="venueType" className="mb-2 block text-base font-semibold text-[#344054]">
                        Is the Venue Fixed?:
                      </label>
                      <select
                        id="venueType"
                        name="venueType"
                        value={selectedVenueType}
                        onChange={(e) => setSelectedVenueType(e.target.value)}
                        className="w-full rounded border border-gray-300 px-3 py-2"
                      >
                        <option value="">Select </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  {selectedVenueType === 'Yes' && (
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                      <div className="rounded-md bg-white p-4 shadow-md">
                        <label
                          htmlFor="eventAddress"
                          className="mb-2 block shrink-0 text-base font-semibold text-[#344054]"
                        >
                          Event Venue:
                        </label>
                        <input
                          type="text"
                          id="eventAddress"
                          name="eventAddress"
                          value={values.eventAddress}
                          onChange={handleInputChange}
                          className="flex w-full rounded border border-gray-300 px-3 py-2"
                        />
                      </div>
                      {/* <div className='rounded-md bg-white p-4 shadow-md'>
                        <label htmlFor="onMap" className="block text-base font-semibold text-[#344054] flex-shrink-0 mb-2">
                          On Map:
                        </label>
                        <button
                          type="button"
                          onClick={handleLocationButtonClick}
                          className="bg-rose-500 hover:bg-rose-600 text-white font-bold py-2 px-4 rounded flex items-center"
                        >
                          <BiLocationPlus className='size-6' />
                          <span className="ml-2">Locate on Map</span>
                        </button>
                      </div> */}
                      {location.latitude && location.longitude && (
                        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                          <div className="rounded-md bg-white p-4 shadow-md">
                            <label className="mb-2 block text-base font-semibold text-[#344054]">Latitude:</label>
                            <input
                              type="text"
                              value={location.latitude}
                              className="w-full rounded border border-gray-300 px-3 py-2"
                              readOnly
                            />
                          </div>
                          <div className="rounded-md bg-white p-4 shadow-md">
                            <label className="mb-2 block text-base font-semibold text-[#344054]">Longitude:</label>
                            <input
                              type="text"
                              value={location.longitude}
                              className="w-full rounded border border-gray-300 px-3 py-2"
                              readOnly
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
              {currentSection === 2 && (
                <>
                  <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label htmlFor="organiserName" className="mb-2 block text-base font-semibold text-[#344054]">
                        Organiser Name:
                      </label>
                      <input
                        type="text"
                        id="organiserName"
                        name="organiserName"
                        value={values.organiserName}
                        onChange={handleInputChange}
                        className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                      />
                    </div>
                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label htmlFor="organiserPhone" className="mb-2 block text-base font-semibold text-[#344054]">
                        Organiser Phone: <span className="font-bold text-red-500">*</span>
                      </label>
                      <input
                        type="phone"
                        id="organiserPhone"
                        name="organiserPhone"
                        value={values.organiserPhone}
                        onChange={handleInputChange}
                        required
                        className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                      />
                    </div>
                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label htmlFor="organiserEmail" className="mb-2 block text-base font-semibold text-[#344054]">
                        Organiser Email-id:
                      </label>
                      <input
                        type="text"
                        id="organiserEmail"
                        name="organiserEmail"
                        value={values.organiserEmail}
                        onChange={handleInputChange}
                        className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                      />
                    </div>
                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label htmlFor="organiserWebsite" className="mb-2 block text-base font-semibold text-[#344054]">
                        Organiser Website:
                      </label>
                      <input
                        type="text"
                        id="organiserWebsite"
                        name="organiserWebsite"
                        value={values.organiserWebsite}
                        onChange={handleInputChange}
                        className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                      />
                    </div>
                  </div>
                  <label
                    htmlFor="poc"
                    className="mb-2 block rounded-md bg-white p-4 text-base font-semibold text-[#344054] shadow-md"
                  >
                    <input
                      type="checkbox"
                      id="poc"
                      name="poc"
                      checked={pocEnabled}
                      onChange={(e) => setPocEnabled(e.target.checked)}
                      className="mr-2"
                    />
                    Do you have any additonal point of contact to be dispalyed in your event page instead of organiser{' '}
                  </label>
                  {pocEnabled && (
                    <div className="flex flex-col gap-4 p-2 lg:flex-row">
                      <input
                        type="text"
                        id="pocName"
                        name="pocName"
                        value={values.pocName}
                        placeholder="Name"
                        onChange={handleInputChange}
                        className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                      />
                      <input
                        type="phone"
                        id="pocPhone"
                        name="pocPhone"
                        value={values.pocPhone}
                        placeholder="Phone"
                        onChange={handleInputChange}
                        className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                      />
                      <input
                        type="text"
                        id="pocEmail"
                        name="pocEmail"
                        value={values.pocEmail}
                        placeholder="Email"
                        onChange={handleInputChange}
                        className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                      />
                    </div>
                  )}
                  <label
                    htmlFor="pocGreet"
                    className="mb-2 block rounded-md bg-white p-4 text-base font-semibold text-[#344054] shadow-md"
                  >
                    <input
                      type="checkbox"
                      id="pocGreet"
                      name="pocGreet"
                      checked={values.pocGreet}
                      onChange={(e) => setValues({ ...values, pocGreet: e.target.checked })}
                      className="mr-2"
                    />
                    Do you want your customers to call directly at your number ? (If not don’t worry we have a 24/7
                    dedicated customer support team to handle all your event calls absolutely free of cost)
                  </label>
                  <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label htmlFor="artistName" className="mb-2 block text-base font-semibold text-[#344054]">
                        Artist Name:
                      </label>
                      <input
                        type="text"
                        id="artistName"
                        name="artistName"
                        value={values.artistName}
                        onChange={handleInputChange}
                        className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                      />
                    </div>

                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label htmlFor="artistFacebookUrl" className="mb-2 block text-base font-semibold text-[#344054]">
                        Artist Facebook Url:
                      </label>
                      <input
                        type="text"
                        id="artistFacebookUrl"
                        name="artistFacebookUrl"
                        value={values.artistFacebookUrl}
                        onChange={handleInputChange}
                        className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                      />
                    </div>
                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label htmlFor="artistInstaUrl" className="mb-2 block text-base font-semibold text-[#344054]">
                        Artist Insta Url:
                      </label>
                      <input
                        type="text"
                        id="artistInstaUrl"
                        name="artistInstaUrl"
                        value={values.artistInstaUrl}
                        onChange={handleInputChange}
                        className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                      />
                    </div>
                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label htmlFor="artistTwitterUrl" className="mb-2 block text-base font-semibold text-[#344054]">
                        Artist Twitter Url:
                      </label>
                      <input
                        type="text"
                        id="artistTwitterUrl"
                        name="artistTwitterUrl"
                        value={values.artistTwitterUrl}
                        onChange={handleInputChange}
                        className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                      />
                    </div>
                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label htmlFor="artistDescription" className="mb-2 block text-base font-semibold text-[#344054]">
                        Artist Description:
                      </label>
                      <div className="mb-2 flex items-center space-x-2">
                        <button
                          type="button"
                          className="font-bold"
                          onClick={() => applyFormatting('b', 'artistDescription')}
                        >
                          <BiBold />
                        </button>
                        <button
                          type="button"
                          className="italic"
                          onClick={() => applyFormatting('i', 'artistDescription')}
                        >
                          <BiItalic />
                        </button>
                        <button
                          type="button"
                          className="underline"
                          onClick={() => applyFormatting('u', 'artistDescription')}
                        >
                          <BiUnderline />
                        </button>
                        {/* <button type="button" onClick={() => applyFormatting('a', 'artistDescription')}><BiLink /></button> */}
                        <button type="button" onClick={() => applyFormatting('blockquote', 'artistDescription')}>
                          <BsIndent />{' '}
                        </button>
                      </div>
                      <textarea
                        type="text"
                        id="artistDescription"
                        name="artistDescription"
                        value={values.artistDescription}
                        onChange={handleInputChange}
                        className="h-[200px] w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                      />
                    </div>

                    {/* <div>
                      <label className="mt-4 block text-base font-semibold mb-2 text-[#344054]">Upload Artist Photo:</label>
                      <label htmlFor="artistImage" className=" w-full min-h-[200px] text-center flex flex-col border bg-[#FFFFFF] border-[#EAECF0] px-3 py-2 flex items-center justify-center rounded-md  shadow text-gray-400">
                        <input
                          type="file"
                          accept="image/svg+xml, image/png, image/jpeg, image/gif"
                          id="artistImage"
                          name="artistImage"
                          onChange={handleInputChange}
                          className="hidden"
                        />
                        <PiUploadBold className='size-9' /><br />
                        <p className='text-rose-600 font-bold'>Click to upload</p> <br /> SVG, PNG, JPG or GIF (max. 800x400px)
                      </label>
                    </div> */}
                    {/* 
                    <div>
                      <label className="mt-4 block text-base font-semibold text-[#344054] mb-2">Artist Image:</label>
                      <div style={{ width: 'full', height: '200px', border: '1px solid #EAECF0', borderRadius: '4px' }}>
                        {values.artistImageUrl ? (
                          <img
                            src={values.artistImageUrl}
                            alt="Artist Image"
                            className="w-full h-full object-fill rounded-md shadow"
                          />
                        ) : (
                          <div className="w-full h-full flex text-base text-center p-4 justify-center items-center text-gray-400 bg-[#FFFFFF] border-[#EAECF0] rounded-md shadow">
                            Artist Image <br /> (Not Mandatory at all, With Greet we all also handle all your designs absolutely free of cost. Just Message Us.)
                          </div>
                        )}
                      </div>
                    </div> */}

                    <div>
                      <label
                        htmlFor="sendCopy"
                        className="block items-center rounded-md  bg-white p-4 font-bold text-gray-700 shadow-md"
                      >
                        <input
                          type="checkbox"
                          id="sendCopy"
                          name="sendCopy"
                          checked={sendCopyEnabled}
                          onChange={(e) => setSendCopyEnabled(e.target.checked)}
                          className="mr-2"
                        />
                        Send a copy of every booking to:
                      </label>
                      {sendCopyEnabled && (
                        <div className="flex flex-col gap-4 p-2 lg:flex-row">
                          <div className="rounded-md bg-white p-4 shadow-md">
                            <label htmlFor="sendPhone" className="mb-2 block text-base font-semibold text-[#344054]">
                              Phone:
                            </label>
                            <input
                              type="text"
                              id="sendPhone"
                              name="sendPhone"
                              value={values.sendPhone}
                              onChange={handleInputChange}
                              className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF] px-3 py-2 shadow"
                            />
                          </div>
                          <div className="rounded-md bg-white p-4 shadow-md">
                            <label htmlFor="sendEmail" className="mb-2 block text-base font-semibold text-[#344054]">
                              Email:
                            </label>
                            <input
                              type="text"
                              id="sendEmail"
                              name="sendEmail"
                              value={values.sendEmail}
                              onChange={handleInputChange}
                              className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF] px-3 py-2 shadow"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              {currentSection === 3 && (
                <>
                  <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label className="mb-2  flex text-base font-semibold text-[#344054]">Ticket Types</label>
                      <div>
                        {values?.ticketTypes.map((val, i) => (
                          <div key={i} className="mb-2 flex flex-row items-center justify-between bg-amber-100 p-2 ">
                            <div className="">
                              <p>
                                <span>Name:</span>
                                {val.name}
                              </p>
                              <p>
                                <span>Price:</span>
                                {val.price}
                              </p>
                              <p>
                                <span>Max Seats:</span>
                                {val.maxSeats}
                              </p>
                            </div>
                            <button
                              onClick={() => handleDeleteTicketType(i)}
                              className="text-red-500 hover:text-red-700"
                            >
                              <MdDelete className="size-6" />
                            </button>
                          </div>
                        ))}
                      </div>
                      <div className="flex flex-col items-center justify-center gap-4">
                        <input
                          type="text"
                          placeholder="Name"
                          className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                          value={ticketType.name}
                          onChange={(e) => setTicketType({ ...ticketType, name: e.target.value })}
                        />
                        <textarea
                          type="text"
                          placeholder="Description"
                          className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                          value={ticketType.desc}
                          onChange={(e) => setTicketType({ ...ticketType, desc: e.target.value })}
                        />
                        <input
                          type="text"
                          placeholder="Price"
                          className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                          value={ticketType.price}
                          onChange={(e) => setTicketType({ ...ticketType, price: e.target.value })}
                        />
                        <input
                          type="text"
                          placeholder="Max Seats"
                          className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                          value={ticketType.maxSeats}
                          onChange={(e) => setTicketType({ ...ticketType, maxSeats: e.target.value })}
                        />
                        <button
                          onClick={handleAddTicketType}
                          className="mt-2 rounded bg-rose-500 px-4 py-2 font-bold text-white hover:bg-rose-600"
                        >
                          Add
                        </button>
                      </div>
                    </div>

                    <div className="rounded-md bg-white p-4 shadow-md">
                      <label className="mb-2  flex text-base font-semibold text-[#344054]">Policies</label>
                      <div>
                        {values?.policies.map((val, i) => (
                          <div key={i} className="mb-2 flex items-center justify-between bg-green-200 p-2">
                            <div className="">
                              <p>
                                <span>Title:</span>
                                {val.title}
                              </p>
                              <p>
                                <span>Description:</span>
                                {val.description}
                              </p>
                            </div>
                            <button onClick={() => handleDeletePolicy(i)} className="text-red-500 hover:text-red-700">
                              <MdDelete className="size-6" />
                            </button>
                          </div>
                        ))}
                      </div>
                      <div className="flex flex-col items-center justify-center gap-4">
                        <input
                          type="text"
                          placeholder="Title"
                          className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                          value={policiesType.title}
                          onChange={(e) => setPoliciesType({ ...policiesType, title: e.target.value })}
                        />
                        <textarea
                          type="text"
                          placeholder="Description"
                          className="w-full rounded-md border border-[#EAECF0] bg-[#FFFFFF]  px-3 py-2 shadow"
                          value={policiesType.description}
                          onChange={(e) => setPoliciesType({ ...policiesType, description: e.target.value })}
                        />
                        <button
                          onClick={handleAddPolicy}
                          className="mt-2 rounded bg-rose-500 px-4 py-2 font-bold text-white hover:bg-rose-600"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <footer className="p-4 ">
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => setCurrentSection((prev) => prev - 1)}
                    className="mr-2 rounded bg-gray-300 px-4 py-2 font-bold text-gray-800 hover:bg-gray-400"
                    disabled={currentSection === 1}
                  >
                    Previous
                  </button>
                  {currentSection !== (2 && 3) ? (
                    <button
                      type="submit"
                      onClick={(e) => handleSubmit(e, 'next')}
                      className="rounded bg-white px-4 py-2 font-bold text-rose-500 "
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="submit"
                      onClick={(e) => handleSubmit(e, 'listEvent')}
                      className="rounded bg-rose-500 px-4 py-2 font-bold text-white "
                    >
                      List Event
                    </button>
                  )}
                </div>
              </footer>
            </form>
          </div>
          {/* {showLocationForm && <EventLocation isOpen={showLocationForm} handleClose={handleLocationFormClose} autoFocus={true} />} */}
          {showSuccessMessage && (
            <SuccessMessage
              onClose={() => {
                setShowSuccessMessage(false);
                navigate('/organiser');
              }}
              autoFocus={true}
            />
          )}
        </div>
      </CTWrapper>
    </div>
  );
};

export default EventForm;
