import { get, isEmpty } from "lodash";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getEvent, payment } from "../../redux/actions/userActions";
import Loader from "../common/Loader";
import TransactionStatus from "../TransactionStatus";
import { useNavigate } from "react-router-dom";

const PaymentStatus = ({
  payment,
  seatVerify,
  paymentInfo,
  getEvent,
  loading,
  eventInfo,
  error,
}) => {
  const history = useNavigate();
  useEffect(() => {
    const getPayment = async () => {
      if (!isEmpty(seatVerify)) {
        await payment(seatVerify?.transactionId);
      }
    };
    getPayment();
  }, [payment, seatVerify]);
  const getStatus = async () => {
    if (
      !isEmpty(seatVerify) &&
      !isEmpty(paymentInfo) &&
      paymentInfo?.transactionPending
    ) {
      await payment(seatVerify?.transactionId);
    }
  };
  useEffect(() => {
    const timeout = setTimeout(() => getStatus(), 4000);
    return () => clearTimeout(timeout);
  }, [seatVerify, paymentInfo]);

  useEffect(() => {
    const getEventInfo = async () => {
      if (
        !isEmpty(paymentInfo) &&
        !paymentInfo?.transactionPending &&
        paymentInfo?.transactionSuccessful &&
        !isEmpty(eventInfo)
      ) {
        await getEvent({ eventId: eventInfo?._id, skip: 0 });
      }
    };
    getEventInfo();
  }, [getEvent, paymentInfo, eventInfo]);
  useEffect(() => {
    if (!isEmpty(error) && error.code === "PAYMENT_ERROR") {
      history("/error");
    }
  }, [error, history]);
  return (
    <>
      {loading && <Loader />}
      {paymentInfo ? (
        paymentInfo?.transactionPending ? (
          <Loader />
        ) : !paymentInfo?.transactionPending &&
          paymentInfo?.transactionSuccessful ? (
          <TransactionStatus />
        ) : (
          <TransactionStatus />
        )
      ) : (
        <Loader />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  seatVerify: get(state, "auth.seatVerify", null),
  loading: get(state, "auth.paymentLoading", false),
  user: get(state, "auth.user", null),
  paymentInfo: get(state, "auth.payment", null),
  error: get(state, "auth.error", {}),
  eventInfo: get(state, "event.eventInfo", {}),
});
export default connect(mapStateToProps, { payment, getEvent })(PaymentStatus);
