import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import CTCarousel from '../../common/CTCarousel';

const Liveshows = ({ eventsResults }) => {
  return (
    <div>
      <CTCarousel
        title={'Live Performances Events'}
        subTitle={'Explore what’s going on around you!'}
        data={eventsResults}
        link={'allevents/liveshows'}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  eventsResults: get(state, 'event.eventsResults', []),
});

export default connect(mapStateToProps)(Liveshows);
