import React, { useState, useEffect } from 'react';

const CouponForm = ({ total, onDiscountApply, onCouponApply, couponData }) => {
  const [applied, setApplied] = useState(false);
  const [appliedCoupon, setAppliedCoupon] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (appliedCoupon.trim() !== '') {
      handleApply();
    }
    setApplied(false);
    setAppliedCoupon('');
    onDiscountApply(0);
    setErrorMessage('');
  }, [total]);

  const handleApply = () => {
    if (appliedCoupon.trim() === '') {
      setErrorMessage('Please enter a coupon code');
      return;
    }

    const foundCoupon = couponData.find((c) => c.code === appliedCoupon);
    if (!foundCoupon) {
      setErrorMessage('Coupon not found or inactive');
      return;
    }

    if (total < foundCoupon.minTransactionAmount) {
      setErrorMessage('Total amount does not meet coupon requirements');
      return;
    }

    // if (foundCoupon.maxAttempt <=0) {
    //   setErrorMessage('Coupon max attempt exceeded');
    //   return;
    // }

    const couponCode = appliedCoupon;
    let discountAmount = 0;
    if (foundCoupon.eventDiscountPercentage) {
      discountAmount = Math.min(foundCoupon.maxDiscountAmount, (total * foundCoupon.eventDiscountPercentage) / 100);
    }

    setApplied(true);
    setErrorMessage('');
    onCouponApply(couponCode);
    onDiscountApply(discountAmount);
  };

  return (
    <div className="flex flex-col">
      <div
        className={`flex h-[2.5rem]  items-center justify-between rounded-xl bg-[#F2F4F7] pl-2 transition-colors ${
          applied ? 'bg-[#D1FADF]' : ''
        }`}
      >
        <div className="flex flex-row justify-between border-none focus:border-none">
          <input
            type="text"
            value={appliedCoupon}
            onChange={(e) => setAppliedCoupon(e.target.value)}
            placeholder={applied ? 'Applied' : 'Enter your coupon code here'}
            className={`bg-[#F2F4F7] p-2 focus:border-none focus:bg-none ${applied ? 'bg-[#D1FADF] p-2' : ''}`}
            disabled={applied}
            style={{ border: 'none' }}
          />
        </div>
        <button
          type="button"
          className="items-center rounded-lg bg-transparent p-1.5 text-sm text-rose-600  hover:text-rose-900"
          onClick={() => {
            setApplied(false);
            setAppliedCoupon('');
            onDiscountApply(0);
            setErrorMessage('');
          }}
        >
          <svg
            aria-hidden="true"
            className="size-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
        <div className="sm:hidden lm:flex">
          <button
            onClick={handleApply}
            className={`rounded bg-gray-500 p-2 text-white transition-colors ${applied ? 'bg-green-500' : ''}`}
          >
            {applied ? 'Applied ✔' : 'Apply'}
          </button>
        </div>
      </div>
      <div className="items-center justify-center pt-2 sm:flex lm:hidden">
        <button
          onClick={handleApply}
          className={`rounded bg-gray-500 p-2 text-white transition-colors ${applied ? 'bg-green-500' : ''}`}
        >
          {applied ? 'Applied ✔' : 'Apply'}
        </button>
      </div>
      {errorMessage && <p className="mt-2 text-xs text-red-500">{errorMessage}</p>}
    </div>
  );
};

export default CouponForm;
