import { useEffect, useState } from 'react';

const useGeoLocation = () => {
  const [location, setLocation] = useState({
    loaded: true,
    coordinates: { lat: '', lng: '' },
    error: '',
  });
  const onSuccess = (location) => {
    setLocation({
      loaded: false,
      coordinates: {
        lat: location.coords.latitude || 20.2771104,
        lng: location.coords.longitude || 85.7777244,
      },
      error: '',
    });
  };

  const onError = (error) => {
    setLocation({
      loaded: false,
      error,
    });
  };

  useEffect(() => {
    if (!('geolocation' in navigator)) {
      onError({
        code: 'GEO_LOCATION_ERROR',
        message: 'Geolocation not supported',
      });
    }
    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, []);

  return location;
};

export default useGeoLocation;
