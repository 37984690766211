import { useState, useEffect } from 'react';
export const screenSizes = {
  smallMobile: 300,
  mobile370: 370,
  mobile: 468,
  mobile600: 600,
  tablet: 768,
  desktop: 1024,
  mediumDesktop: 1280,
  largeDesktop: 1440,
  hugeDesktop: 1666,
};
const useMediaQuery = (breakpoint) => {
  const [width, setWidth] = useState(0);
  const updateWidth = () => setWidth(window?.innerWidth);

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return breakpoint ? [width >= +breakpoint, width] : [width];
};

export default useMediaQuery;
