import React from 'react';
import FaqSection from '../components/Merchant/FAQ/FaqSection';
import HeroSection from '../components/Merchant/Hero/HeroSection';
import FeatureSection from '../components/Merchant/Feature/FeatureSection';
import PricingSection from '../components/Merchant/Pricing/PricingSection';
import OrganiserSection from '../components/Merchant/Organiser/OrganiserSection';
import MetricsSection from '../components/Merchant/Metrics/MetricsSection';
import ReviewSection from '../components/Merchant/Review/ReviewSection';
import AppSection from '../components/Merchant/AppSection';
import Loader from '../components/common/Loader';

const Merchant = ({ loading }) => {
  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <HeroSection />
          <FeatureSection />
          <MetricsSection />
          <PricingSection />
          <AppSection />
          <ReviewSection />
          <OrganiserSection />
          <FaqSection />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Merchant;
