import React from 'react'
import { ErrorMessage, useField } from 'formik'

const SelectField = ({ label, item, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <div>
      {label && (
        <label
          htmlFor={label}
          className="mb-2 block text-sm font-medium text-gray-900"
        >
          {label}
        </label>
      )}
      <select
        className={`block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 ${
          meta.touched && meta.error && 'border-red-500'
        }`}
        {...field}
        {...props}
      >
        <option value="">Select Option </option>
        {item.map((item, index) => (
          <option key={index} values={item}>
            {item}
          </option>
        ))}
      </select>
      <ErrorMessage
        className="text-sm text-red-500"
        component={'p'}
        name={field.name}
      />
    </div>
  )
}

export default SelectField
