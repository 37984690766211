import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import TrendingCarousel from './TrendingCarousel';

const EventTrending = ({ eventTrending }) => {
  return (
    <TrendingCarousel title={'Trending Events'} subTitle={'Explore what’s HOT around you!'} data={eventTrending} />
  );
};
const mapStateToProps = (state) => ({
  eventTrending: get(state, 'event.eventTrending', {}),
});
export default connect(mapStateToProps)(EventTrending);
