import React from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import GreetFydo from '../../Images/home/greetOne.png';
import CTImage from './CTImage';

const CategoryCard = ({ _id, name, iconUrl, urlHidden }) => {
  const renderLink = !urlHidden ? `/category/${_id}` : '/';
  const imageUrl = !isEmpty(iconUrl) ? iconUrl : GreetFydo;
  React.useEffect(() => {
    const image = new Image();
    image.src = imageUrl;
  }, [imageUrl]);
  return (
    <Link to={renderLink} className="block h-24 w-full overflow-hidden rounded-lg shadow-md hover:shadow-xl md:h-36">
      <div className="relative h-full overflow-hidden rounded-lg">
        <CTImage src={imageUrl} alt={name} title={name} className="size-full rounded-lg object-cover" />
        <div className="absolute inset-0 rounded-lg bg-gradient-to-t from-black/30 via-black/20" />
        <div className="absolute bottom-2 left-2 text-sm text-white md:text-lg">
          <h2 className="overflow-hidden whitespace-nowrap text-wrap font-extrabold uppercase md:whitespace-pre-line md:pr-20 lg:pr-10">
            {name}
          </h2>
        </div>
      </div>
    </Link>
  );
};

export default CategoryCard;
