//import imageFydo from '../Images/logo-fydo.png';
import greetOne from '../Images/home/greetOne.png';
import greetLogo from '../Images/common/logo.png';
import greetFydo from '../Images/common/greet-fydo.png';
import homeImage2 from '../Images/home/homeImage2.png';
import appleStore from '../Images/common/apple-store.png';
import googlePlay from '../Images/common/google-play.png';
import appImage from '../Images/home/storeScreen_1.png';
import appImage1 from '../Images/home/home_google.png';
import card1 from '../Images/home/card1.png';
import card2 from '../Images/home/card2.png';
import card3 from '../Images/home/card3.png';
import card4 from '../Images/home/card4.png';
import card5 from '../Images/home/card5.png';
import eventImage from '../Images/home/carousel.jpg';
import newyear from '../Images/home/newyear.jpg';
import music from '../Images/home/music.jpg';
import workshop from '../Images/home/workshop.jpg';
import comedy from '../Images/home/comedy.jpg';
import courses from '../Images/home/courses.jpg';
import theater from '../Images/home/theater.jpg';
import { VscGlobe } from 'react-icons/vsc';
import { IoLocationOutline } from 'react-icons/io5';
import review1 from '../Images/merchant/review1.png';
import review2 from '../Images/merchant/review2.png';
import review3 from '../Images/merchant/review3.png';
import review4 from '../Images/merchant/review4.png';
import review5 from '../Images/merchant/review5.png';
import review6 from '../Images/merchant/review6.png';

import app1 from '../Images/merchant/pricing_logo1.png';
import app2 from '../Images/merchant/pricing_logo2.png';
import app3 from '../Images/merchant/pricing_logo3.png';

import eventaccept from '../Images/home/Event Accepted Tentatively.png';
import modeplay from '../Images/home/Circled Play.png';
import ioBell from '../Images/svg/ioBell';
import ioTicket from '../Images/svg/ioTicket';
import ioStar from '../Images/svg/ioStar';
import { PiBagDuotone, PiMagicWandDuotone } from 'react-icons/pi';
import { PiFlyingSaucerDuotone } from 'react-icons/pi';
import { PiConfettiDuotone } from 'react-icons/pi';
import { PiPhoneDuotone } from 'react-icons/pi';
import { TbUserFilled } from 'react-icons/tb';
import { RiPieChart2Fill } from 'react-icons/ri';
import { TicketIcon } from '@heroicons/react/solid';

export const navigation = [
  {
    name: 'Booking',
    link: '/',
  },
  {
    name: 'Partners',
    link: '/',
  },
  {
    name: 'Contacts',
    link: '/',
  },
];
export const home = {
  button1: 'Book Now',
  button2: 'Book Again',
  button3: 'View Tickets',
  seo: {
    title: 'Greet - Book events now!',
    description: 'Greet - Book events now!',
    keywords:
      'Greet Event booking,Local events,Nearby events,Event reservation,Event ticket booking,Event calendar,Event finder,Event scheduling,Event organizer,Event discovery,Event listings,Event Search,Upcoming events,Local event calendar,Event booking app,Event Registration,Event directory,Find events near me	,Fydo Bhubaneswar,Event booking in Bhubaneswar,Local events in Bhubaneswar,Nearby events in Bhubaneswar,Event reservation in Bhubaneswar,Event ticket booking in Bhubaneswar,Event calendar Bhubaneswar,Event finder Bhubaneswar,Event scheduling Bhubaneswar,Event organizer in Bhubaneswar,Event management Bhubaneswar,Fydo event planning,Event discovery in Bhubaneswar,Event listings in Bhubaneswar,Event search in Bhubaneswar,Upcoming events in Bhubaneswar,Local event calendar Bhubaneswar,Fydo event booking app,Event registration in Bhubaneswar, Event directory Bhubaneswar',
  },
  featureOne: {
    image: greetOne,
    title: 'Ready For The Events?',
    subTitle: 'Hey, Are You',
    imagePosition: 'right',
    icon: greetFydo,
    description: ['Event Made Easy', 'Event booking / management platfrom'],
    variants: {
      hidden: { x: -100, opacity: 0 },
      show: { x: 1, opacity: 1 },
    },
  },
  featureTwo: {
    image: homeImage2,
    title: 'Ready For The Events?',
    subTitle: 'Download our App',
    imagePosition: 'left',
    icon: greetFydo,
    description: ['Event Made Easy', 'Event booking/management platfrom'],
    iconDescription: [
      {
        head: 'Get updates',
        icon: ioBell,
      },
      {
        head: 'Easy booking',
        icon: ioTicket,
      },
      {
        head: 'Mark favourites',
        icon: ioStar,
      },
    ],
    rating: [
      {
        title: '4.8',
        subTitle: 'On App Store',
      },
      {
        title: '4.8',
        subTitle: 'On Google Play',
      },
    ],
    applestore: {
      image: appleStore,
      alt: 'Fydo App Store',
      link: 'https://apps.apple.com/in/app/fydo/id1612594091',
    },
    googleplay: {
      image: googlePlay,
      alt: 'Fydo Google Play',
      link: 'https://play.google.com/store/apps/details?id=com.letsdevelopit.lfydcustomer',
    },
    variants: {
      // hidden: { x: 100, opacity: 0 },
      // show: { x: 0, opacity: 1 },
    },
  },
  featureThree: {
    cards: [
      {
        image: card1,
        head: 'Events for everybody',
        subHead: 'Visit our website and get to know the latest events happening around you, for free!!!',
      },
      {
        image: card2,
        head: 'Register, the time is now',
        subHead: 'The prize could be yours, participate in the event. Register now!',
      },
      {
        image: card3,
        head: "Let's crack the event",
        subHead: "Memorable events don't just happen, they happen to be our business",
      },
      {
        image: card4,
        head: 'Huge pool of audience',
        subHead: 'Fydo has an ample amount of audience, which will be beneficial to your event',
      },
      {
        image: card5,
        head: 'Quality and professional services at affordable price',
        subHead: 'We will be assisting you with the best services at pocket-friendly prices',
      },
    ],
  },
};
export const pageNotFoundPage = {
  seo: {
    title: 'Page not found | Greet - Book events now!',
    description: 'Page not found, Greet - Book events now!',
    keywords:
      'Page not Found,Greet Event booking,Local events,Nearby events,Event reservation,Event ticket booking,Event calendar,Event finder,Event scheduling,Event organizer,Event discovery,Event listings,Event Search,Upcoming events,Local event calendar,Event booking app,Event Registration,Event directory,Find events near me	,Fydo Bhubaneswar,Event booking in Bhubaneswar,Local events in Bhubaneswar,Nearby events in Bhubaneswar,Event reservation in Bhubaneswar,Event ticket booking in Bhubaneswar,Event calendar Bhubaneswar,Event finder Bhubaneswar,Event scheduling Bhubaneswar,Event organizer in Bhubaneswar,Event management Bhubaneswar,Fydo event planning,Event discovery in Bhubaneswar,Event listings in Bhubaneswar,Event search in Bhubaneswar,Upcoming events in Bhubaneswar,Local event calendar Bhubaneswar,Fydo event booking app,Event registration in Bhubaneswar, Event directory Bhubaneswar',
  },
};
export const errorPage = {
  seo: {
    title: 'Error | Greet - Book events now!',
    description: 'Error, Greet - Book events now!',
    keywords:
      'Error,Greet Event booking,Local events,Nearby events,Event reservation,Event ticket booking,Event calendar,Event finder,Event scheduling,Event organizer,Event discovery,Event listings,Event Search,Upcoming events,Local event calendar,Event booking app,Event Registration,Event directory,Find events near me	,Fydo Bhubaneswar,Event booking in Bhubaneswar,Local events in Bhubaneswar,Nearby events in Bhubaneswar,Event reservation in Bhubaneswar,Event ticket booking in Bhubaneswar,Event calendar Bhubaneswar,Event finder Bhubaneswar,Event scheduling Bhubaneswar,Event organizer in Bhubaneswar,Event management Bhubaneswar,Fydo event planning,Event discovery in Bhubaneswar,Event listings in Bhubaneswar,Event search in Bhubaneswar,Upcoming events in Bhubaneswar,Local event calendar Bhubaneswar,Fydo event booking app,Event registration in Bhubaneswar, Event directory Bhubaneswar',
  },
};
export const myBookingPage = {
  seo: {
    title: 'Event Tickets | Greet - Book events now!',
    description: 'Event Tickets, Greet - Book events now!',
    keywords:
      'Events Tickets,Greet Event booking,Local events,Nearby events,Event reservation,Event ticket booking,Event calendar,Event finder,Event scheduling,Event organizer,Event discovery,Event listings,Event Search,Upcoming events,Local event calendar,Event booking app,Event Registration,Event directory,Find events near me	,Fydo Bhubaneswar,Event booking in Bhubaneswar,Local events in Bhubaneswar,Nearby events in Bhubaneswar,Event reservation in Bhubaneswar,Event ticket booking in Bhubaneswar,Event calendar Bhubaneswar,Event finder Bhubaneswar,Event scheduling Bhubaneswar,Event organizer in Bhubaneswar,Event management Bhubaneswar,Fydo event planning,Event discovery in Bhubaneswar,Event listings in Bhubaneswar,Event search in Bhubaneswar,Upcoming events in Bhubaneswar,Local event calendar Bhubaneswar,Fydo event booking app,Event registration in Bhubaneswar, Event directory Bhubaneswar',
  },
};
export const signin = {
  head: 'Log in to our platform',
  mobileInputLabel: 'Your mobile number',
  mobileInputPlaceholder: 'Enter mobile number',
  button: 'Login to your account',
};
export const aboutUs = {
  title: 'About the Event',
  description: '',
};
export const ticketPrices = {
  head: 'Price of tickets',
  prices: [
    {
      category: 'Per person',
      priceTag: '/- (ticket includes seat and food)',
      price: 399,
      priceType: '₹',
    },
  ],
  button1: 'Login',
  button2: 'Logout',
  button3: 'Buy Again',
  policies: [
    {
      title: 'General Terms',
      description: [
        '• All the guests are requested to follow the rules and regulations of the organising team.',
        '• Consumption of alcohol or any kind of drugs is strictly prohibited before and during the programme, you may be barred if found under such possession unless organiser have allowed it.',
      ],
    },
    {
      title: 'Cancellation / Refund Policy',
      description: [
        "• Tickets once booked aren't cancellable.",
        '• In case the amount has been deducted and the desired seat isn’t allocated, a full refund will be credited to original payment mode within 5-7 working days.',
      ],
    },
    {
      title: 'Covid-19 Policy',
      description: [
        'All the guests are mandatory to follow the expected COVID - 19 norms and COVID -appropriate behaviour.',
      ],
    },
  ],
};
export const event = {
  eventId: '62d192bb08755ff069a94220',
  eventName: 'JPB Nuakhai Bhetghat 2022',
};
export const razorpay = {
  name: 'FYDO',
  currency: 'INR',
  description: '',
  image: '',
};
export const footer = {
  title: 'Greet',
  image: greetLogo,
  url: 'https://fydo.in/',
  description:
    'Greet is a subsidiary of Fydo (Lfyd Services Pvt Ltd), your all-in-one event companion. From seamless ticket booking to personalized event recommendations, explore local happenings, unlock exclusive deals, and connect with the heartbeat of your city at the same time a one stop platform designed to streamline the process of organizing events.',
  button1: 'Download Now',
  button2: 'Download Now',
  button3: 'Download Now',
  links: [
    {
      name: 'Download Now',
      url: 'https://fydo.in/terms-and-conditions.html',
    },
    {
      name: 'License',
      url: 'https://fydo.in/terms-and-conditions.html',
    },
  ],
  contactTitle: 'Available from 9AM - 11PM',
  playTitle: 'Get the app',
  guidelines: 'For best experience, use Google Chrome, Apple Safari, Microsoft Edge, Firefox and Opera browsers.',
};
export const seatLayout = {
  screenTitle: 'All eyes this way please!',
  title: 'Reserved seating area',
  leftButtonText: 'Left Seating',
  rightButtonText: 'Right Seating',
};

export const loader = {
  title: 'Please wait',
};

export const homecategory = {
  title: 'Catagories',
  subTitle: 'Explore. Discover. Make a Plan',
  categories: [
    {
      id: 1,
      image: newyear,
      name: 'New Year Party',
    },
    {
      id: 2,
      image: music,
      name: 'Music',
    },
    {
      id: 3,
      image: workshop,
      name: 'Workshop',
    },
    {
      id: 4,
      image: comedy,
      name: 'Comedy',
    },
    {
      id: 5,
      image: courses,
      name: 'Courses',
    },
    {
      id: 6,
      image: theater,
      name: 'Theater & Arts',
    },
  ],
};
export const hometrending = {
  title: 'Trending Events',
  subTitle: 'Explore what’s HOT around you!',
  datelogo: eventaccept,
  modelogo: modeplay,
  event: [
    {
      head: 'Open Mic - Nitin choudhury 1',
      url: '/',
      description: 'lorem ipsum......',
      category: 'Music',
      date: 'Friday, Feb 23  •  7:30 PM',
      mode: 'Digital',
      image: eventImage,
      price: 'FREE',
      button: 'Register Now',
    },
    {
      head: 'Open Mic - Nitin choudhury 2',
      url: '/',
      description: 'lorem ipsum......',
      category: 'Music',
      date: 'Friday, Feb 23  •  7:30 PM',
      mode: 'Digital',
      image: eventImage,
      price: 'FREE',
      button: 'Register Now',
    },
    {
      head: 'Open Mic - Nitin choudhury 3',
      url: '/',
      description: 'lorem ipsum......',
      category: 'Music',
      date: 'Friday, Feb 23  •  7:30 PM',
      mode: 'Digital',
      image: eventImage,
      price: 'FREE',
      button: 'Register Now',
    },
    {
      head: 'Open Mic - Nitin choudhury 4',
      url: '/',
      description: 'lorem ipsum......',
      category: 'Music',
      date: 'Friday, Feb 23  •  7:30 PM',
      mode: 'Digital',
      image: eventImage,
      price: 'FREE',
      button: 'Register Now',
    },
  ],
};
export const homeevents = {
  title: 'All events',
  subTitle: 'Explore what’s going on around you!',
  datelogo: eventaccept,
  modelogo: modeplay,
  button: 'View all',
  event: [
    {
      head: 'Open Mic - Nitin choudhury 1',
      url: '/',
      description: 'lorem ipsum......',
      category: 'Music',
      date: 'Friday, Feb 23  •  7:30 PM',
      mode: 'Digital',
      image: eventImage,
      price: 'FREE',
      button: 'Register Now',
    },
    {
      head: 'Open Mic - Nitin choudhury 2',
      url: '/',
      description: 'lorem ipsum......',
      category: 'Music',
      date: 'Friday, Feb 23  •  7:30 PM',
      mode: 'Digital',
      image: eventImage,
      price: 'FREE',
      button: 'Register Now',
    },
    {
      head: 'Open Mic - Nitin choudhury 3',
      url: '/',
      description: 'lorem ipsum......',
      category: 'Music',
      date: 'Friday, Feb 23  •  7:30 PM',
      mode: 'Digital',
      image: eventImage,
      price: 'FREE',
      button: 'Register Now',
    },
    {
      head: 'Open Mic - Nitin choudhury 4',
      url: '/',
      description: 'lorem ipsum......',
      category: 'Music',
      date: 'Friday, Feb 23  •  7:30 PM',
      mode: 'Digital',
      image: eventImage,
      price: 'FREE',
      button: 'Register Now',
    },
    {
      head: 'Open Mic - Nitin choudhury 5',
      url: '/',
      description: 'lorem ipsum......',
      category: 'Music',
      date: 'Friday, Feb 23  •  7:30 PM',
      mode: 'Digital',
      image: eventImage,
      price: 'FREE',
      button: 'Register Now',
    },
    {
      head: 'Open Mic - Nitin choudhury 6',
      url: '/',
      description: 'lorem ipsum......',
      category: 'Music',
      date: 'Friday, Feb 23  •  7:30 PM',
      mode: 'Digital',
      image: eventImage,
      price: 'FREE',
      button: 'Register Now',
    },
  ],
};
export const homeexpired = {
  title: 'Expired Events',
  datelogo: eventaccept,
  modelogo: modeplay,
  event: [
    {
      head: 'Open Mic - Nitin choudhury 1',
      url: '/',
      description: 'lorem ipsum......',
      category: 'Music',
      date: 'Friday, Feb 23  •  7:30 PM',
      mode: 'Digital',
      image: eventImage,
      button: 'Ended',
    },
    {
      head: 'Open Mic - Nitin choudhury 2',
      url: '/',
      description: 'lorem ipsum......',
      category: 'Music',
      date: 'Friday, Feb 23  •  7:30 PM',
      mode: 'Digital',
      image: eventImage,
      button: 'Ended',
    },
    {
      head: 'Open Mic - Nitin choudhury 3',
      url: '/',
      description: 'lorem ipsum......',
      category: 'Music',
      date: 'Friday, Feb 23  •  7:30 PM',
      mode: 'Digital',
      image: eventImage,
      button: 'Ended',
    },
    {
      head: 'Open Mic - Nitin choudhury 4',
      url: '/',
      description: 'lorem ipsum......',
      category: 'Music',
      date: 'Friday, Feb 23  •  7:30 PM',
      mode: 'Digital',
      image: eventImage,
      button: 'Ended',
    },
    {
      head: 'Open Mic - Nitin choudhury 5',
      url: '/',
      description: 'lorem ipsum......',
      category: 'Music',
      date: 'Friday, Feb 23  •  7:30 PM',
      mode: 'Digital',
      image: eventImage,
      button: 'Ended',
    },
    {
      head: 'Open Mic - Nitin choudhury 6',
      url: '/',
      description: 'lorem ipsum......',
      category: 'Music',
      date: 'Friday, Feb 23  •  7:30 PM',
      mode: 'Digital',
      image: eventImage,
      button: 'Ended',
    },
  ],
};
export const appsection = {
  head: 'Got an Event?',
  tthead: 'Greet Got You Covered',
  subhead:
    'List your event today and experience the Greet advantage. Our user-friendly platform offers a range of features, including all your poster designing, on demand settlements and dedicated relationship manager.  Trust us on this, Once you try Greet, you’ll never look back.',
  title: 'Get the App',
  img: [
    {
      url: 'https://play.google.com/store/apps/details?id=com.fydo.eventorganiser',
      src: googlePlay,
      imgtitle: 'Google Play',
    },
    // {
    //   url: 'https://apps.apple.com/in/app/fydo/id1612594091',
    //   src: appleStore,
    //   imgtitle: 'App Store',
    // },
  ],
  appImg: appImage1,
  appImg1: appImage,
  appalt: 'App Images',
};

export const faqsection = {
  title: 'Frequently Asked Questions',
  faqs: [
    {
      question: 'What is Greet?',
      answer:
        'Greet is a subsidiary of Fydo (Lfyd Services Pvt Ltd), is an event booking platform designed to streamline the process of organizing and attending events. It offers a user-friendly interface for event organizers and attendees.',
    },
    {
      question: 'What’s Unique about Greet?',
      answer:
        'Although we do have a lot of distinctive features which makes us unique, what sets us apart is the personalised effort we give to make the event a grand success.',
    },
    {
      question: 'How can I book tickets for an event on Greet?',
      answer:
        'Booking tickets on Greet is easy! Just browse the available events, select the one you’re interested in, and follow the prompts to complete the ticket booking process.',
    },
    {
      question: 'Can I use cashback earned at partner shops to book event tickets?',
      answer:
        'Absolutely! Your cashback earned at partner shops can be applied towards discounts when booking event tickets on Greet, providing you with additional savings.',
    },
    {
      question: 'Can I sell tickets for my own event on Greet?',
      answer:
        'Yes, Greet provides a platform for event organisers to sell tickets for their events. Contact our team to learn more about listing your event on Greet.',
    },
    {
      question: 'What types of events are available on Greet?',
      answer:
        'Greet features a diverse range of events, including concerts, conferences, festivals, and more. You can explore different categories to find events that match your interests.',
    },
    {
      question: 'How can I stay updated on upcoming events and promotions?',
      answer:
        'Stay in the loop by subscribing to our newsletter or following us on social media. We regularly share updates on upcoming events, promotions, and exciting features.',
    },
    {
      question: 'What support is available if I encounter issues with the app or ticket booking?',
      answer:
        'Greet offers 24/7 customer support. If you encounter any issues with the app or ticket booking, feel free to contact our support team through the app or website.',
    },
    {
      question: 'What is the refund policy if I cancel my ticket?',
      answer:
        'We understand that plans can change, and each event on Greet may have its own refund and cancellation policy. The policies are set by the respective event organisers, and Greet does not handle refunds directly. To inquire about or request a refund, please contact the event organiser directly. You can find their contact information on the event page.',
    },
    {
      question: 'How many cities is Greet available in?',
      answer:
        'Greet is currently available pan India, catering to events and users across various cities. We’re excited to share that our expansion plans extend beyond borders, and we’re actively working towards bringing Greet to other countries.',
    },
  ],
};
export const carousel = {
  pictures: [
    {
      img: eventImage,
      link: '#',
    },
    {
      img: eventImage,
      link: '#',
    },
    {
      img: eventImage,
      link: '#',
    },
  ],
};
export const faqsection_merchant = {
  title: 'Frequently Asked Questions',
  faqs: [
    {
      question: 'How can I list my event on Greet?',
      answer:
        'Listing your event on Greet is easy! Simply click on "Create Event," and follow the prompts to provide details about your event, including date, venue, and ticket types or just call us on 8447734227 and let us do all the work for you :)',
    },
    {
      question: 'Why is Greet the best choice for your event ticketing platform ?',
      answer:
        'What sets Greet apart from Event Organizers is our commitment to your success. With Greet, you’ll have a dedicated relationship manager by your side, ensuring every aspect of your event is meticulously handled. From pre-event preparations to on-site management, our dedicated manager brings unparalleled dedication to make your event an undeniable success. Experience the difference with Greet’s personalized support for organizers.',
    },
    {
      question: 'What are the benefits of using Greet for our events?',
      answer:
        'Greet has a range of features beyond ticket booking, including event promotion tools, real-time analytics, on-site management, customizable ticket options, secure payments, and 24/7 customer support. Explore the full list in the Features section for a complete overview.',
    },
    {
      question: 'Can I customize ticket types and pricing for my event?',
      answer:
        'Yes, Greet provides organizers with the flexibility to create custom ticket types and set pricing based on their event’s requirements. You can easily manage this through the Organizer Dashboard.',
    },
    {
      question: 'How do I receive payments for ticket sales?',
      answer:
        'Payments for ticket sales are securely processed through Greet, and the funds are transferred to your designated account. As stated you can get your settlements whenever you demand, instantly.',
    },
    {
      question: 'Can I track attendee information and sales analytics?',
      answer:
        'Absolutely! Greet provides robust analytics tools on the Organizer Dashboard, allowing you to track ticket sales, attendee demographics, and other valuable insights to enhance your event planning.',
    },
    {
      question: 'How can I connect with Greet’s customer support for assistance?',
      answer:
        'Greet offers 24/7 customer support for event organizers. You can reach out through the Organizer Dashboard, and our dedicated support team will promptly assist you with any inquiries or issues.',
    },
    {
      question: 'Is Greet available for international events outside of India?',
      answer:
        'Yes, Greet is actively expanding its reach to other countries. If you’re planning an international event, contact our support team to explore the possibilities and discuss how Greet can support your event’s success.',
    },
  ],
};

export const featureData = [
  {
    id: 1,
    icon: <TicketIcon className="size-10 text-[#FF6250]" />,
    color: 'bg-[#FF6250]/20',
    name: 'Your Special Ticketing Partner',
    desc: 'What sets us apart is that we are not just an ordinary ticket booking platform, we come as a partner, so from designing posters to getting those sponsors  whatever is required to make your event successful. We will do it .',
  },
  {
    id: 2,
    icon: <PiMagicWandDuotone className="size-10 text-[#FF6250]" />,
    color: 'bg-[#FF6250]/20',
    name: 'Dedicated Relationship Manager',
    desc: "Elevate your event planning experience with Fydo's Dedicated Relationship Manager. From seamless setup to event day troubleshooting, our expert manager becomes your invaluable ally, providing personalised support and ensuring a stress-free journey for organisers",
  },
  {
    id: 3,
    icon: <PiFlyingSaucerDuotone className="size-10 pl-1 text-[#009379]" />,
    color: 'bg-[#009379]/20',
    name: 'On Demand Settlement',
    desc: 'Get flexibility like never before—request settlements whenever you need them. Take charge of your event’s finances with ease and on your terms, ensuring a stress-free and efficient event planning process.',
  },
  {
    id: 3,
    icon: <PiConfettiDuotone className="size-10 text-[#F8D57E]" />,
    color: 'bg-[#F8D57E]/20',
    name: 'Customer Analytics Data',
    desc: "Unlock actionable insights with Fydo's Customer Analytics Data feature. Gain a comprehensive understanding of your event attendees, their preferences, and behaviours. Leverage this valuable data to refine your strategies, enhance engagement, and create events that resonate with your audience.",
  },
  {
    id: 4,
    icon: <PiBagDuotone className="size-10 text-[#F2D52E]" />,
    color: 'bg-[#F8D52E]/20',
    name: '360 Degree Marketing & Branding',
    desc: "Transform your event's presence with Fydo's holistic marketing approach. From crafting eye-catching event posters to strategic promotions on platforms like Facebook, Instagram, WhatsApp, and email, we offer comprehensive 360-degree marketing support.",
  },
  {
    id: 4,
    icon: <PiPhoneDuotone className="size-10 text-[#8DEF5E]" />,
    color: 'bg-[#8DEF5E]/20',
    name: '24/7 Call Support',
    desc: "Experience unwavering support with Fydo's 24/7 On-Call Assistance. Whether you're an organiser seeking guidance or a customer with inquiries, our dedicated support team is just a call away. Count on us around the clock to ensure a smooth and worry-free event experience for everyone involved.",
  },

  {
    id: 5,
    icon: <TbUserFilled className="size-10 text-[#005E93]" />,
    color: 'bg-[#005E93]/20',
    name: 'On site representative on event day',
    desc: "Ensure a seamless event day experience with Fydo's on-site representative service. When needed, our dedicated representatives are there to assist, troubleshoot, and ensure everything runs smoothly, allowing you to focus on creating memorable moments for your attendees.",
  },

  {
    id: 6,
    icon: <RiPieChart2Fill className="size-10 text-[#35C56E]" />,
    color: 'bg-[#35C56E]/20',
    name: 'Exclusive App for Organisers',
    desc: "Transform event management with Greet, Fydo's Exclusive Organizer App. Effortlessly check in attendees and access the complete list of booked tickets, putting the power of precision in your hands. Real-time updates and comprehensive insights make Greet your go-to command center for seamless and successful events.",
  },
];
export const reviewData = {
  title: 'Our Clients Speak',
  subTitle: 'We have been working with clients around the world',
  reviews: [
    {
      id: 1,
      title: 'Efficient Collaborating',
      content:
        'Fydo’s event ticket booking platform had streamlined our ticketing process and attendee experience. The best part was the dedicated relationship manager, that guy Saroj, he was very helpful and helped me streamline the whole event with ease.',
      author: 'Farzaan',
      organization: 'FunHub Odisha',
      image: review1,
    },
    {
      id: 2,
      title: 'Personal Touch',
      content:
        'Felt happy to tie up with an Events booking platform from my own state, and best part I gotta know they sent their own representative on the event day to help us with coordination and event management.',
      author: 'Riten Pattnaik',
      organization: 'Komedy Countdown Odisha',
      image: review5,
    },
    {
      id: 3,
      title: 'Exclusive Features',
      content:
        'As event organisers, having control over our funds was crucial. Fydo’s on-demand  settlements worked very well,  we asked for our funds in  batches and they sent us whenever we needed. Best of  luck Fydo, We will keep using your service for our future events for sure.',
      author: 'Satyabrat Mohapatra',
      organization: 'Comedy Highway',
      image: review2,
    },
    {
      id: 4,
      title: 'Ease of Use',
      content:
        'As an individual event organiser, Fydo has been a game-changer. The platform’s simplicity and efficiency in ticket management have saved my valuable time and effort. The real-time analytics and easy customization make it the perfect companion for organising success',
      author: 'Alok Shukla',
      organization: 'Art & Craft Workshop',
      image: review4,
    },
    {
      id: 5,
      title: 'Guranteed Satisfaction ',
      content:
        'It was the first time I organised an event ,talked with several event booking platforms and nobody was helping me with poster designs and luckily I got a recommendation from my friend, not only they helped me with design but also personally helped me to organise the whole event with ease.',
      author: 'Arvind Das & Team',
      organization: 'Dandiya Maniya',
      image: review3,
    },
    {
      id: 6,
      title: 'Mindblowing Service',
      content:
        "Fydo’s event ticketing is a lifesaver for our music festivals. The platform's versatility in handling large-scale events and providing detailed insights into ticket sales have significantly contributed to the success of our festivals.",
      author: 'Ravi Agarwal',
      organization: 'Greatway Event Management',
      image: review6,
    },
  ],
};
export const merchantappsection = {
  apps: [
    {
      img: app1,
      alt: 'Fydo',
      rating: 4.8,
    },
    {
      img: app2,
      alt: 'Fydo Partner',
      rating: 4.7,
    },
    {
      img: app3,
      alt: 'Greet',
      rating: 5,
    },
  ],
};
export const pricing = {
  title: 'Pricing',
  subTitle: 'Starting from 5% commission Lowest price guaranteed',
  price: [
    {
      icon: <VscGlobe className="size-10 text-[#565656]" />,
      head: 'For individuals',
      mode: 'Online',
      description: 'We sell tickets for online events and you can host event at any platform of your choice.',
    },
    {
      icon: <IoLocationOutline className="size-10 text-[#565656]" />,
      head: 'For individuals',
      mode: 'On Ground',
      description: 'We sell tickets for online events and you can host event at any platform of your choice.',
    },
  ],
};
