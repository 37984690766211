import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getEventSlider, getCategory1Event } from '../redux/actions/eventActions';
import useGeoLocation from '../utils/useGeoLocation';
import { get, isEmpty } from 'lodash';
import Loader from '../components/common/Loader';
import EventSlider from '../components/Home/EventSlider';
import AllCTCarousel from '../components/common/AllCTCarousel';

const HoliAllEvents = ({ loading, getEventSlider, eventSlider, getCategory1Event, category1Events }) => {
  const location = useGeoLocation();
  const skip = 0;
  const limit = 100;
  const categoryName = 'HOLI';

  useEffect(() => {
    if (!isEmpty(location) && !location?.loaded) {
      const coordinates = [location?.coordinates?.lng, location?.coordinates?.lat];
      getEventSlider({ coordinates, skip, limit });
      getCategory1Event({ categoryName, coordinates, skip: 0 });
    }
  }, [location, skip, limit]);

  return (
    <div>
      {!isEmpty(eventSlider) && <EventSlider />}
      {loading && <Loader initial={true} />}
      {!loading && (
        <AllCTCarousel
          title={'Holi Events'}
          msg={
            "World Is Full Of Excitement, And You've Explored Just A Fraction Of What We Have To Offer. Keep Exploring For Other Exciting Events."
          }
          data={category1Events}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: get(state, 'event.loading', false),
  eventSlider: get(state, 'event.eventSlider', []),
  category1Events: get(state, 'event.category1Events', []),
  // campusEvents: get(state, 'event.categoryEvents.campus', []),
});

export default connect(mapStateToProps, {
  getEventSlider,
  getCategory1Event,
})(HoliAllEvents);
