import React, { useRef, useEffect, useState, useMemo } from 'react';
import NewEventCard from './NewEventCard';
import CTWrapper from './CTWrapper';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

const CTInnerCarousel = ({ data, title, subTitle, urlHidden, link }) => {
  const carouselContainerRef = useRef();
  const [repeatIndex, setRepeatIndex] = useState(0);

  const scrollCarousel = () => {
    const container = carouselContainerRef.current;
    if (!container) return; // Add null check for the ref
    const cardWidth = container?.firstChild?.offsetWidth + 10;
    const scrollAmount = container.scrollLeft + cardWidth;
    const maxScroll = container.scrollWidth - container.clientWidth;

    if (scrollAmount >= maxScroll) {
      resetCarousel();
    } else {
      container.scrollTo({
        left: scrollAmount,
        behavior: 'smooth',
      });
      setRepeatIndex((prevIndex) => (prevIndex === data.length - 1 ? 0 : prevIndex + 1));
    }
  };

  const resetCarousel = () => {
    const container = carouselContainerRef.current;
    if (!container) return; // Add null check for the ref
    const lastCardWidth = container.lastChild.offsetWidth;
    const maxScroll = container.scrollWidth - container.clientWidth;

    if (maxScroll - container.scrollLeft < lastCardWidth) {
      container.scrollTo({
        left: 0,
        behavior: 'smooth',
      });
      setRepeatIndex(0);
    } else {
      container.scrollTo({
        left: 0,
        behavior: 'smooth',
      });
      setRepeatIndex(0);
    }
  };

  useEffect(() => {
    if (isEmpty(data) || !Array.isArray(data)) return; // Ensure data is valid before setting the interval
    const intervalId = setInterval(() => {
      scrollCarousel();
    }, 3000);

    return () => clearInterval(intervalId);
  }, [data]);

  const memoizedData = useMemo(() => {
    if (isEmpty(data) || !Array.isArray(data)) return null;

    return data.map((item, index) => (
      <div key={index} className="mb-4 mr-5 shrink-0 md:mr-8 lg:gap-[20px]">
        <NewEventCard {...item} urlHidden={urlHidden} repeat={repeatIndex === index} />
      </div>
    ));
  }, [data, repeatIndex, urlHidden]);

  return (
    <section className="bg-white text-black lg:pt-10">
      <CTWrapper className="flex flex-col">
        <header className="flex flex-row items-center justify-between">
          <h2 className="text-lg font-bold text-[#222222] hover:text-btnPrimary lg:text-xl ">{title}</h2>

          <div className="flex flex-row items-center justify-center gap-2 ">
            <Link to={link}>
              <button className="flex h-[32px] items-center text-nowrap rounded-lg border border-rose-600 bg-rose-600 p-1 text-xs font-semibold uppercase text-white md:p-2 lg:h-[40px] lg:text-sm">
                View All
              </button>
            </Link>
          </div>
        </header>
        <div className="mb-6 flex justify-between md:mt-0">
          <h4 className="text-base font-medium tracking-normal text-[#7A7C82]">{subTitle}</h4>
        </div>
        <div className="flex w-full flex-col pb-5">
          <div className="relative">
            <div
              className="flex scroll-p-8 p-1"
              ref={carouselContainerRef}
              style={{
                overflowX: 'auto',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
              }}
            >
              {memoizedData}
            </div>
          </div>
        </div>
      </CTWrapper>
    </section>
  );
};

export default CTInnerCarousel;
