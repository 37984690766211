import React, { useState, useCallback, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CTLoader from './CTLoader';
import imageFydo from '../../Images/common/logo-fydo.png';

const CTImage = ({ src, className, alt = 'fydo', title = 'fydo', placementSrc, showLoader = true }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const setLoadingTrue = useCallback(() => {
    setLoaded(true);
  }, []);

  const setErrorTrue = useCallback(() => {
    setError(true);
  }, []);
  useEffect(() => {
    const img = new Image();

    if (src) {
      img.src = src;

      setLoaded(false);
      setError(false);

      img.addEventListener('load', setLoadingTrue);
      img.addEventListener('error', setErrorTrue);
    }

    return () => {
      img.removeEventListener('load', setLoadingTrue);
      img.removeEventListener('error', setErrorTrue);
    };
  }, [src, setLoadingTrue, setErrorTrue]);

  //   const imgLoaded = loaded && !error;
  const imgLoading = src && !loaded && !error;
  const imgFailed = !src || error;
  const _renderImage = () => {
    if (showLoader && imgLoading) {
      return (
        <div className={className || ''}>
          <CTLoader />
        </div>
      );
    }
    return (
      <LazyLoadImage
        className={className || ''}
        alt={alt}
        title={title}
        src={imgFailed ? placementSrc || imageFydo : src}
      />
    );
  };
  return _renderImage();
};

export default CTImage;
