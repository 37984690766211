import React, { useRef, useState, useEffect, useMemo } from 'react';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import NewTrendingEventCard from './NewTrendingEventCard';
import CTWrapper from '../../common/CTWrapper';

const TrendingCarousel = ({ data, title, subTitle, urlHidden }) => {
  const carouselContainerRef = useRef();
  const [repeatIndex, setRepeatIndex] = useState(0);

  const scrollCarousel = () => {
    const container = carouselContainerRef.current;
    const cardWidth = container.firstChild.offsetWidth + 10;
    const scrollAmount = container.scrollLeft + cardWidth;
    const maxScroll = container.scrollWidth - container.clientWidth;

    if (scrollAmount >= maxScroll) {
      resetCarousel();
    } else {
      container.scrollTo({
        left: scrollAmount,
        behavior: 'smooth',
      });
      setRepeatIndex((prevIndex) => (prevIndex === data.length - 1 ? 0 : prevIndex + 1));
    }
  };

  const resetCarousel = () => {
    const container = carouselContainerRef.current;
    const lastCardWidth = container.lastChild.offsetWidth;
    const maxScroll = container.scrollWidth - container.clientWidth;

    if (maxScroll - container.scrollLeft < lastCardWidth) {
      container.scrollTo({
        left: 0,
        behavior: 'smooth',
      });
      setRepeatIndex(0);
    } else {
      container.scrollTo({
        left: 0,
        behavior: 'smooth',
      });
      setRepeatIndex(0);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      scrollCarousel();
    }, 15000);

    return () => clearInterval(intervalId);
  }, [data]);

  const navigation = (dir) => {
    const container = carouselContainerRef.current;
    const cardWidth = container.firstChild.offsetWidth + 10;
    const scrollAmount = dir === 'left' ? container.scrollLeft - cardWidth : container.scrollLeft + cardWidth;

    container.scrollTo({
      left: scrollAmount,
      behavior: 'smooth',
    });
  };

  const TrendingEventCardMemo = useMemo(
    () =>
      data.map((item, index) => (
        <div key={index} className="mb-4 mr-5 shrink-0 md:mr-7 lg:mr-10">
          <NewTrendingEventCard {...item} urlHidden={urlHidden} repeat={repeatIndex === index} />
        </div>
      )),
    [data, repeatIndex, urlHidden],
  );

  return (
    <section className="bg-gradient-to-r from-[#001F3F] to-[#00122F] py-10 text-white">
      <CTWrapper className="flex flex-col py-4">
        <div className="gap-4">
          {/* <div className="rounded-full p-4"> </div> */}
          <header className="flex flex-row justify-between">
            <h2 className="text-xl font-bold text-white md:text-2xl">{title}</h2>
            <div className="flex flex-row items-center justify-center gap-2 ">
              <div className="right-20 flex">
                <div className="group">
                  <div
                    onClick={() => navigation('left')}
                    className="mx-1 flex size-10 cursor-pointer items-center justify-center rounded-full bg-white shadow-[0_3px_6px_#0000001a] group-hover:bg-red-500"
                  >
                    <MdArrowBackIosNew className="text-black group-hover:text-white" />
                  </div>
                </div>
                <div className="group">
                  <div
                    onClick={() => navigation('right')}
                    className="mx-1 flex size-10 cursor-pointer items-center justify-center rounded-full bg-white shadow-[0_3px_6px_#0000001a] group-hover:bg-red-500"
                  >
                    <MdArrowForwardIos className="text-black group-hover:text-white" />
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="mb-6 mt-2 flex justify-between md:mt-0">
            <h4 className="text-base font-medium tracking-normal text-white">{subTitle}</h4>
          </div>
        </div>
        <div className="flex w-full flex-col">
          <div className="relative">
            <div
              className="flex scroll-p-8 p-1"
              ref={carouselContainerRef}
              style={{
                overflowX: 'auto',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
              }}
            >
              {TrendingEventCardMemo}
            </div>
          </div>
        </div>
      </CTWrapper>
    </section>
  );
};

export default TrendingCarousel;
