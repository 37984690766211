import React from 'react';
import { CTWrapper, CTAccordion } from '../../common';
import { faqsection } from '../../../data';

const HomeFAQ = () => {
  return (
    <CTWrapper className={'bg-white pb-10'}>
      <div id="FAQ" className="mx-auto max-w-screen-md py-10 lg:pb-16 lg:pt-20 xl:max-w-[960px]">
        <h1 className="mt-10 text-center text-xl font-extrabold text-[#333333] md:text-2xl lg:mt-0 lg:text-center lg:text-[32px]">
          {faqsection.title}
        </h1>
        <div className="mt-0 py-6">
          {faqsection.faqs.map((faq, index) => (
            <CTAccordion key={index} title={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </CTWrapper>
  );
};

export default HomeFAQ;
