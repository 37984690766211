import React, { useState, useEffect, useRef } from 'react';
import { CTWrapper } from '../../common';
import { reviewData } from '../../../data';
import ReviewCard from './ReviewCard';

const ReviewSection = () => {
  const carouselContainerRef = useRef();
  const [repeatIndex, setRepeatIndex] = useState(0);

  const scrollCarousel = () => {
    const container = carouselContainerRef.current;
    const cardWidth = container.firstChild.offsetWidth + 10;
    const scrollAmount = container.scrollLeft + cardWidth;
    const maxScroll = container.scrollWidth - container.clientWidth;

    if (scrollAmount >= maxScroll) {
      resetCarousel();
    } else {
      container.scrollTo({
        left: scrollAmount,
        behavior: 'smooth',
      });
      setRepeatIndex((prevIndex) => (prevIndex === reviewData.reviews.length - 1 ? 0 : prevIndex + 1));
    }
  };

  const resetCarousel = () => {
    const container = carouselContainerRef.current;
    container.scrollTo({
      left: 0,
      behavior: 'smooth',
    });
    setRepeatIndex(0);
  };

  useEffect(() => {
    const intervalId = setInterval(scrollCarousel, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <section>
      <CTWrapper className="flex flex-col py-16 md:py-20">
        <div className="flex flex-col gap-2 text-center md:gap-4">
          <h1 className="text-xl font-extrabold text-[#18191F] md:text-2xl lg:text-[32px] xl:text-5xl">
            {reviewData.title}
          </h1>
          <p className="text-sm font-normal text-[#2D2D2D] md:text-base xl:text-lg">{reviewData.subTitle}</p>
        </div>
        <div
          className="mt-10 flex overflow-x-auto"
          ref={carouselContainerRef}
          style={{
            overflowX: 'auto',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          }}
        >
          {reviewData.reviews.map((item, index) => (
            <ReviewCard key={index} item={item} repeat={repeatIndex === index} />
          ))}
        </div>
      </CTWrapper>
    </section>
  );
};

export default ReviewSection;
