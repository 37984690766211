import React from 'react';
import { CTImage } from '../../common';
import success from '../../../Images/merchant/success.png';
const SuccessMessage = ({ onClose }) => {
  return (
    <div className="absolute inset-0 z-[15] m-2 flex items-center justify-center bg-black bg-opacity-50">
      <div className="rounded-lg bg-white p-4">
        <CTImage src={success} className={'h-[253px] w-[407px] items-center justify-center'} />
        <h2 className="mb-2 text-center text-xl font-bold">Event Created Successfully!</h2>
        <p className="mb-4 text-center text-base font-normal">
          Sit back and relax now. <br />
          Someone from Greet will call you in a moment.
        </p>

        <div className="mt-4 flex justify-center">
          <button onClick={onClose} className="rounded bg-[#FE3838] px-4 py-2 font-bold text-white hover:bg-[#FE3838]">
            Okay !
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessMessage;
