import React from 'react';
import graphic1 from '../../../Images/merchant/graphic1.png';
import cal from '../../../Images/merchant/noto_party-popper.png';
import share from '../../../Images/merchant/flat-color-icons_share.png';
import organiser from '../../../Images/merchant/organiser.png';
import { CTImage, CTWrapper } from '../../common';

const OrganiserSection = () => {
  return (
    <>
      <div className="relative w-full bg-[#FE3838] py-10">
        <CTImage className="absolute size-full object-cover" src={graphic1} alt="icon" />

        <CTWrapper className="grid grid-cols-1 lg:grid-cols-2">
          <div className="flex flex-col justify-center">
            <h1 className="mt-10 text-center text-xl font-extrabold text-white md:text-2xl lg:mt-0 lg:text-left lg:text-[32px] xl:text-5xl">
              Exclusive App For Organiser
            </h1>
            <div className="flex flex-col md:flex-row md:gap-4 lg:flex-col lg:gap-0">
              <div className="mt-10 rounded-[32px] border-[0.787px] border-[#E5F4F2] bg-white px-4 py-[30px] text-black shadow-sm md:px-12 md:py-10 lg:mt-6 lg:p-6 xl:mt-10 xl:px-16 xl:py-10">
                <div className="flex flex-col items-center gap-[14px] md:flex-row">
                  <div className="size-[56px] items-center justify-center rounded-lg border border-[#ECEBFF] p-2">
                    <CTImage src={cal} width={56} height={56} alt="icon" />
                  </div>

                  <h3 className="text-center text-base font-bold text-[#2D2D2D] md:text-left lg:text-2xl">
                    Seamless Event Check-In with Greet
                  </h3>
                </div>
                <p className="mt-4 text-center text-sm leading-6 text-[#2D2D2D] md:text-left xl:text-base">
                  Effortlessly manage event attendance! Greet’s QR code scanning feature empowers organizers to swiftly
                  confirm attendee presence at the event venue, ensuring a smooth and hassle-free check-in experience
                  for everyone involved.
                </p>
              </div>
              <div className="mt-[22px] rounded-[32px] border-[0.787px] border-[#E5F4F2] bg-white px-4 py-[30px] text-black shadow-sm md:mt-10 md:px-12 md:py-10 lg:mt-[22px] lg:p-6 xl:mt-12 xl:px-16 xl:py-10">
                <div className="flex flex-col items-center gap-[14px] md:flex-row">
                  <div className="size-[56px] items-center justify-center rounded-lg border border-[#ECEBFF] p-2">
                    <CTImage src={share} width={56} height={56} alt="icon" />
                  </div>

                  <h3 className="text-center text-base font-bold text-[#2D2D2D] md:text-left lg:text-2xl">
                    Empower Your Events with Real-Time Insights
                  </h3>
                </div>
                <p className="mt-4 text-center text-sm leading-6 text-[#2D2D2D] md:text-left xl:text-base">
                  Unlock the potential of your events’ success! Greet provides event organizers with a comprehensive
                  analytics dashboard, offering real-time data on sales, ticket bookings, and more.
                </p>
              </div>
            </div>
          </div>
          <div className="md:mt-10 lg:mt-0">
            <CTImage src={organiser} className="bottom-0 -mb-10 md:-my-10 lg:-mb-10" alt="organiser" />
          </div>
        </CTWrapper>
      </div>
    </>
  );
};

export default OrganiserSection;
