import React, { useState } from 'react';
const CTAccordion = ({ title, answer }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <>
      <div className="border-b border-[#E4E7EC] py-4 md:py-6">
        <button onClick={() => setAccordionOpen(!accordionOpen)} className="flex w-full justify-between rtl:text-right">
          <span className="text-left text-[1rem] font-semibold text-[#333333] md:text-[1.25rem]">{title}</span>
          {accordionOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="size-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="size-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
            </svg>
          )}
        </button>
        <div
          className={`grid overflow-hidden text-sm text-[#73818E] transition-all duration-300 ease-in-out ${
            accordionOpen ? 'mt-4 grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'
          }`}
        >
          <div className="overflow-hidden">{answer}</div>
        </div>
      </div>
    </>
  );
};

export default CTAccordion;
