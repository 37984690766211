import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import Box from '../../common/Box';

const EventCategory = ({ eventCategory }) => {
  return <Box title={'Event Categories'} subTitle={'Explore. Discover. Make a Plan'} data={eventCategory} urlHidden />;
};
const mapStateToProps = (state) => ({
  eventCategory: get(state, 'event.eventCategory', {}),
});
export default connect(mapStateToProps)(EventCategory);
