export const SEND_OTP_REQUEST = 'SEND_OTP_REQUEST';
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_FAIL = 'SEND_OTP_FAIL';

export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL';

export const EVENT_REQUEST = 'EVENT_REQUEST';
export const EVENT_SUCCESS = 'EVENT_SUCCESS';
export const EVENT_FAIL = 'EVENT_FAIL';

export const EVENT_INFO_REQUEST = 'EVENT_INFO_REQUEST';
export const EVENT_INFO_SUCCESS = 'EVENT_INFO_SUCCESS';
export const EVENT_INFO_FAIL = 'EVENT_INFO_FAIL';

export const CATEGORY_INFO_REQUEST = 'CATEGORY_INFO_REQUEST';
export const CATEGORY_INFO_SUCCESS = 'CATEGORY_INFO_SUCCESS';
export const CATEGORY_INFO_FAIL = 'CATEGORY_INFO_FAIL';

export const FETCH_EVENTS_REQUEST = 'FETCH_EVENTS_REQUEST';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE';

export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const UPLOAD_PROFILE_PIC_REQUEST = 'UPLOAD_PROFILE_PIC_REQUEST';
export const UPLOAD_PROFILE_PIC_SUCCESS = 'UPLOAD_PROFILE_PIC_SUCCESS';
export const UPLOAD_PROFILE_PIC_FAILURE = 'UPLOAD_PROFILE_PIC_FAILURE';

export const APPLY_COUPON_REQUEST = 'APPLY_COUPON_REQUEST';
export const APPLY_COUPON_SUCCESS = 'APPLY_COUPON_SUCCESS';
export const APPLY_COUPON_FAILURE = 'APPLY_COUPON_FAILURE';

export const FETCH_ACTIVE_DATES_REQUEST = 'FETCH_ACTIVE_DATES_REQUEST';
export const FETCH_ACTIVE_DATES_SUCCESS = 'FETCH_ACTIVE_DATES_SUCCESS';
export const FETCH_ACTIVE_DATES_FAILURE = 'FETCH_ACTIVE_DATES_FAILURE';

export const FETCH_ACTIVE_TICKETS_REQUEST = 'FETCH_ACTIVE_TICKETS_REQUEST';
export const FETCH_ACTIVE_TICKETS_SUCCESS = 'FETCH_ACTIVE_TICKETS_SUCCESS';
export const FETCH_ACTIVE_TICKETS_FAILURE = 'FETCH_ACTIVE_TICKETS_FAILURE';

export const CATEGORY_BY_EVENT_REQUEST = 'CATEGORY_BY_EVENT_REQUEST';
export const CATEGORY_BY_EVENT_SUCCESS = 'CATEGORY_BY_EVENT_SUCCESS';
export const CATEGORY_BY_EVENT_FAIL = 'CATEGORY_BY_EVENT_FAIL';

export const CATEGORY1_BY_EVENT_REQUEST = 'CATEGORY1_BY_EVENT_REQUEST';
export const CATEGORY1_BY_EVENT_SUCCESS = 'CATEGORY1_BY_EVENT_SUCCESS';
export const CATEGORY1_BY_EVENT_FAIL = 'CATEGORY1_BY_EVENT_FAIL';

export const EVENT_TRENDING_REQUEST = 'EVENT_TRENDING_REQUEST';
export const EVENT_TRENDING_SUCCESS = 'EVENT_TRENDING_SUCCESS';
export const EVENT_TRENDING_FAIL = 'EVENT_TRENDING_FAIL';

export const EVENT_ALLEXPIRED_REQUEST = 'EVENT_ALLEXPIRED_REQUEST';
export const EVENT_ALLEXPIRED_SUCCESS = 'EVENT_ALLEXPIRED_SUCCESS';
export const EVENT_ALLEXPIRED_FAIL = 'EVENT_ALLEXPIRED_FAIL';

export const EVENT_GEOLOCATION_REQUEST = 'EVENT_GEOLOCATION_REQUEST';
export const EVENT_GEOLOCATION_SUCCESS = 'EVENT_GEOLOCATION_SUCCESS';
export const EVENT_GEOLOCATION_FAIL = 'EVENT_GEOLOCATION_FAIL';

export const EVENT_SLIDER_REQUEST = 'EVENT_SLIDER_REQUEST';
export const EVENT_SLIDER_SUCCESS = 'EVENT_SLIDER_SUCCESS';
export const EVENT_SLIDER_FAIL = 'EVENT_SLIDER_FAIL';

export const SEARCH_EVENT_REQUEST = 'SEARCH_EVENT_REQUEST';
export const SEARCH_EVENT_SUCCESS = 'SEARCH_EVENT_SUCCESS';
export const SEARCH_EVENT_FAILURE = 'SEARCH_EVENT_FAILURE';

export const EVENT_CATEGORY_REQUEST = 'EVENT_CATEGORY_REQUEST';
export const EVENT_CATEGORY_SUCCESS = 'EVENT_CATEGORY_SUCCESS';
export const EVENT_CATEGORY_FAIL = 'EVENT_CATEGORY_FAIL';

export const EVENT_LIVE_REQUEST = 'EVENT_LIVE_REQUEST';
export const EVENT_LIVE_SUCCESS = 'EVENT_LIVE_SUCCESS';
export const EVENT_LIVE_FAIL = 'EVENT_LIVE_FAIL';

export const EVENT_EXPIRED_REQUEST = 'EVENT_EXPIRED_REQUEST';
export const EVENT_EXPIRED_SUCCESS = 'EVENT_EXPIRED_SUCCESS';
export const EVENT_EXPIRED_FAIL = 'EVENT_EXPIRED_FAIL';

export const LIST_EVENT_REQUEST = 'LIST_EVENT_REQUEST';
export const LIST_EVENT_SUCCESS = 'LIST_EVENT_SUCCESS';
export const LIST_EVENT_FAIL = 'LIST_EVENT_FAIL';

export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAIL = 'UPLOAD_IMAGE_FAIL';

export const NEW_USER_REQUEST = 'NEW_USER_REQUEST';
export const NEW_USER_SUCCESS = 'NEW_USER_SUCCESS';
export const NEW_USER_FAIL = 'NEW_USER_FAIL';

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAIL = 'USER_PROFILE_FAIL';

export const SEAT_DETAIL_REQUEST = 'SEAT_DETAIL_REQUEST';
export const SEAT_DETAIL_SUCCESS = 'SEAT_DETAIL_SUCCESS';
export const SEAT_DETAIL_FAIL = 'SEAT_DETAIL_FAIL';

export const SEAT_VERIFY_REQUEST = 'SEAT_VERIFY_REQUEST';
export const SEAT_VERIFY_SUCCESS = 'SEAT_VERIFY_SUCCESS';
export const SEAT_VERIFY_FAIL = 'SEAT_VERIFY_FAIL';
export const SEAT_VERIFY_STATUS_FALSE = 'SEAT_VERIFY_STATUS_FALSE';

export const PAYMENT_REQUEST = 'PAYMENT_REQUEST';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_FAIL = 'PAYMENT_FAIL';

export const SINGLE_TICKET_REQUEST = 'SINGLE_TICKET_REQUEST';
export const SINGLE_TICKET_SUCCESS = 'SINGLE_TICKET_SUCCESS';
export const SINGLE_TICKET_FAIL = 'SINGLE_TICKET_FAIL';

export const AUTHENTICATED = 'AUTHENTICATED';
export const ADD_EVENT_NEW_ITEM = 'ADD_EVENT_NEW_ITEM';
export const REMOVE_EVENT_NEW_ITEM = 'REMOVE_EVENT_NEW_ITEM';

export const ADD_NEW_ANSWER = 'ADD_NEW_ANSWER';
export const REMOVE_NEW_ANSWER = 'REMOVE_NEW_ANSWER';

export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_ERRORS = 'SET_ERRORS';
