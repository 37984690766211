import React from 'react'
import ReactDOM from 'react-dom/client'
import './style/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './redux/store'
import { BrowserRouter } from 'react-router-dom'
import Loader from './components/common/Loader'
import { HelmetProvider } from 'react-helmet-async'

const rootNode = document.getElementById('root')
ReactDOM.hydrateRoot(
  rootNode,
  <Provider store={store}>
    <React.StrictMode>
      <React.Suspense fallback={<Loader />}>
        <HelmetProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </HelmetProvider>
      </React.Suspense>
    </React.StrictMode>
  </Provider>,
)

reportWebVitals()
