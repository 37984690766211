import React from 'react';
import { CTWrapper } from '../../common';
const MetricsSection = () => {
  return (
    <>
      <CTWrapper className="max-w-full bg-[#0B0D17] py-10 xl:py-[120px]">
        <div className="flex flex-col gap-2 text-center text-white md:gap-4">
          <h1 className="text-xl font-extrabold md:text-2xl lg:text-[32px] xl:text-5xl">Our Metrics Tell the Story</h1>
          <p className="text-sm font-normal md:text-base xl:text-lg">
            Explore Compelling Insights Uncovered by Our Metrics and Data Analysis
          </p>
        </div>

        <div className="mx-auto mt-6 grid max-w-[1320px] grid-cols-1 gap-[24px] px-5 text-center md:grid-cols-3 md:px-10 lg:gap-[30px]">
          <div className="flex flex-col items-center rounded-t-[20px] border-b-[16px] border-[#FE3838] bg-white px-4 py-12 md:gap-[10px] xl:gap-[5px] ">
            <p className="text-[1.5rem] font-bold lg:text-[2.375rem]">500+</p>
            <p className="text-[1rem] font-normal">Happy Organisers</p>
          </div>

          <div className="flex flex-col items-center gap-[5px] rounded-t-[20px] border-b-[16px] border-[#FE3838] bg-white px-4 py-12 ">
            <p className="text-[1.5rem] font-bold lg:text-[2.375rem]">2,500+</p>
            <p className="text-[1rem] font-normal">Successful Events Organised</p>
          </div>

          <div className="flex flex-col items-center gap-[5px] rounded-t-[20px] border-b-[16px] border-[#FE3838] bg-white px-4 py-12 ">
            <p className="text-[1.5rem] font-bold lg:text-[2.375rem]">50,000+</p>
            <p className="text-[1rem] font-normal">Tickets Sold</p>
          </div>
        </div>
      </CTWrapper>
    </>
  );
};

export default MetricsSection;
