import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getEventSlider, getCategoryEvent } from '../redux/actions/eventActions';
import useGeoLocation from '../utils/useGeoLocation';
import { get, isEmpty } from 'lodash';
import Loader from '../components/common/Loader';
import EventSlider from '../components/Home/EventSlider';
import AllCTCarousel from '../components/common/AllCTCarousel';

const CampusAllEvents = ({ loading, getEventSlider, eventSlider, getCategoryEvent, categoryEvents }) => {
  const location = useGeoLocation();
  const skip = 0;
  const limit = 100;
  const categoryName = 'CAMPUS';

  useEffect(() => {
    if (!isEmpty(location) && !location?.loaded) {
      const coordinates = [location?.coordinates?.lng, location?.coordinates?.lat];
      getEventSlider({ coordinates, skip, limit });
      getCategoryEvent({ categoryName, coordinates, skip: 0 });
    }
  }, [location, skip, limit]);

  return (
    <div>
      {!isEmpty(eventSlider) && <EventSlider />}
      {loading && <Loader initial={true} />}
      {!loading && (
        <AllCTCarousel
          title={'Campus Events'}
          msg={
            "World Is Full Of Excitement, And You've Explored Just A Fraction Of What We Have To Offer. Keep Exploring For Other Exciting Events."
          }
          data={categoryEvents}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: get(state, 'event.loading', false),
  eventSlider: get(state, 'event.eventSlider', []),
  categoryEvents: get(state, 'event.categoryEvents', []),
});

export default connect(mapStateToProps, {
  getEventSlider,
  getCategoryEvent,
})(CampusAllEvents);
