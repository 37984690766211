import React from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { BsFacebook, BsWhatsapp, BsTelephone, BsLinkedin, BsInstagram } from 'react-icons/bs';
import playStore from '../../Images/common/google-play.png';
import appStore from '../../Images/common/apple-store.png';
import footerImage from '../../Images/common/footer.png';
import footerImageXL from '../../Images/common/footer-xl.png';
import { CTImage, CTWrapper } from '../common';
import { footer } from '../../data';
import { useMediaQuery, screenSizes } from '../../hooks';

const CTFooter = () => {
  const [isDesktop] = useMediaQuery(screenSizes?.mediumDesktop);

  return (
    <footer
      id="footer"
      className="bg-[#0B0D17] bg-top bg-no-repeat text-white md:bg-left"
      style={{ backgroundImage: `url(${isDesktop ? footerImageXL : footerImage})` }}
    >
      <CTWrapper className={'flex flex-col py-10 lg:flex-row lg:justify-between'}>
        <div className="gap-6 text-left text-white lg:flex lg:max-w-[520px] lg:flex-col xl:max-w-[580px]">
          <a href={footer?.url} className="flex">
            <CTImage className="h-9 w-36 md:w-40 lg:h-12" src={footer?.image} alt={footer?.title} />
          </a>
          <p className="mt-5 text-xs font-normal leading-6 tracking-wide lg:mt-0">{footer?.description}</p>
          <div className="hidden flex-wrap items-center gap-6 text-base font-normal leading-7 tracking-wide text-white lg:flex">
            <a
              className="hover:text-red-500"
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.fydo.eventorganiser"
            >
              Download Now
            </a>
            <a
              className="hover:text-red-500"
              target="_blank"
              rel="noopener noreferrer"
              href="https://fydo.in/privacy-policy.html"
            >
              License
            </a>
            <h2 className="items-center justify-center text-lg font-medium text-white md:text-base">Find us on:</h2>
            <div className="flex items-center justify-center gap-2 space-x-2 md:mb-0">
              <a href="https://www.facebook.com/fydoin">
                <BsFacebook size="30" className="text-white hover:text-red-500" />
              </a>
              <a href="https://www.linkedin.com/company/fydoin">
                <BsLinkedin size="30" className="text-white hover:text-red-500" />
              </a>
              <a href="https://www.instagram.com/greet.fydo/">
                <BsInstagram size="30" className="text-white hover:text-red-500" />
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col text-left md:flex-row lg:gap-4">
          <div className="w-full text-sm font-normal leading-7 tracking-wider text-white md:w-1/2 md:gap-2 md:text-base  lg:w-full">
            <h2 className="mb-2 mt-5 text-nowrap lg:mt-3">{footer.contactTitle}</h2>
            <nav className="mb-1 list-none">
              <ol>
                <li className="group">
                  <a className="mb-2 flex items-center  group-hover:text-red-500" href="mailto:support@fydo.in">
                    <AiOutlineMail size="20" className=" group-hover:text-red-500" />
                    <p className="ml-2">{'support@fydo.in'}</p>
                  </a>
                </li>
                <li className="group">
                  <a
                    className="mb-2 flex items-center  group-hover:text-red-500"
                    target="_blank"
                    rel="noreferrer"
                    href="https://wa.me/918447734227"
                  >
                    <BsWhatsapp size="20" className=" group-hover:text-red-500" />
                    <p className="ml-2">{'8447734227'}</p>
                  </a>
                </li>
                <li className="group">
                  <a className="mb-2 flex items-center  group-hover:text-red-500" href="tel:8447734227">
                    <BsTelephone className="group-hover:text-red-500" size="20" />
                    <p className="ml-2">{'8447734227'}</p>
                  </a>
                </li>
              </ol>
            </nav>
            <h2 className="text-base font-medium leading-7 tracking-wide text-[#D9DBE1] md:my-5 md:text-lg lg:my-2">
              {footer.playTitle}
            </h2>
            <div className="my-2 flex items-center gap-2 md:gap-4">
              <div className="h-[39px] w-[135px]">
                <a
                  href="https://play.google.com/store/apps/details?id=com.letsdevelopit.lfydcustomer"
                  rel="noreferrer"
                  target="_self"
                >
                  <img src={playStore} alt="Google Play" />
                </a>
              </div>
              <div className="h-[40px] w-[135px]">
                <a href="https://apps.apple.com/in/app/fydo/id1612594091" rel="noreferrer" target="_self">
                  <img src={appStore} alt="App Store" />
                </a>
              </div>
            </div>
          </div>
          <div className="gap-6 md:hidden">
            <div className="mt-4 flex flex-row items-center gap-5 text-base font-normal leading-7 tracking-wide">
              <a
                className="hover:text-red-500"
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.fydo.eventorganiser"
              >
                Download Now
              </a>
              <a
                className="hover:text-red-500"
                target="_blank"
                rel="noopener noreferrer"
                href="https://fydo.in/privacy-policy.html"
              >
                License
              </a>
            </div>
            <div className="flex flex-row text-base font-normal leading-7 tracking-wide">
              <h2 className="mr-2 mt-3 items-center">Find us on:</h2>
              <div className="flex items-center gap-2 space-x-2">
                <a href="https://www.facebook.com/fydoin">
                  <BsFacebook size="30" className="mt-3 text-white hover:text-red-500" />
                </a>
                <a href="https://www.linkedin.com/company/fydoin">
                  <BsLinkedin size="30" className="mt-3 text-white hover:text-red-500" />
                </a>
                <a href="https://www.instagram.com/greet.fydo/">
                  <BsInstagram size="30" className="mt-3 text-white hover:text-red-500" />
                </a>
              </div>
            </div>
          </div>
          <div className="mt-10 hidden flex-col pl-10 md:flex lg:hidden">
            <div className=" mt-4 flex flex-col gap-6 text-base font-normal leading-7 tracking-wide text-white">
              <a
                className="hover:text-red-500"
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.fydo.eventorganiser"
              >
                Download Now
              </a>
              <a
                className="hover:text-red-500"
                target="_blank"
                rel="noopener noreferrer"
                href="https://fydo.in/privacy-policy.html"
              >
                License
              </a>
            </div>
            <div className="mt-4 flex flex-row items-center">
              <h2 className="mr-2 items-center text-left text-base font-normal tracking-wide text-white">
                Find us on:
              </h2>
              <div className="flex items-center justify-center gap-2 space-x-2">
                <a href="https://www.facebook.com/fydoin">
                  <BsFacebook size="30" className="text-white hover:text-red-500 md:size-8" />
                </a>
                <a href="https://www.linkedin.com/company/fydoin">
                  {' '}
                  <BsLinkedin size="30" className="text-white hover:text-red-500 md:size-8" />
                </a>
                <a href="https://www.instagram.com/greet.fydo/">
                  {' '}
                  <BsInstagram size="30" className=" text-white hover:text-red-500 md:size-8" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </CTWrapper>
    </footer>
  );
};

export default CTFooter;
