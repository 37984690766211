import React from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { verfiyOtp } from '../../redux/actions/userActions'
import TextField from '../../utils/TextField'
import { signin } from '../../data'
import { get } from 'lodash'
const INITIAL_FORM_STATE = {
  otp: '',
}
const FORM_VALIDATION = Yup.object().shape({
  otp: Yup.number()
    .integer()
    .typeError('Please enter a valid OTP')
    .required('Required OTP'),
})
const OtpNumber = ({ otpId, loading, error, verfiyOtp }) => {
  return (
    <>
      <Formik
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={async (values, { resetForm }) => {
          const userdata = { otp: values.otp, otpId: otpId, role: 'C' }
          await verfiyOtp(userdata)
          resetForm({ values: '' })
        }}
      >
        <Form className="space-y-6">
          <TextField
            label="Your OTP"
            name="otp"
            type="text"
            placeholder="Enter OTP"
            inputFocus={true}
          />
          <button
            type="submit"
            className="w-full text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            disabled={loading}
          >
            {loading ? '...' : signin.button}
          </button>
        </Form>
      </Formik>
      {error && error?.code === 'VERFIY_OTP_ERROR' && (
        <p className="m-2 text-sm font-medium text-red-500">{error?.message}</p>
      )}
    </>
  )
}
const mapStateToProps = (state) => ({
  otpId: get(state, 'auth.otpId', null),
  error: get(state, 'auth.error', {}),
  loading: get(state, 'auth.loading', false),
})
export default connect(mapStateToProps, { verfiyOtp })(OtpNumber)
