import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getEventGeoLocation, getEventSlider } from '../redux/actions/eventActions';
import useGeoLocation from '../utils/useGeoLocation';
import { get, isEmpty } from 'lodash';
import Loader from '../components/common/Loader';
import EventSlider from '../components/Home/EventSlider';
import AllCTCarousel from '../components/common/AllCTCarousel';

const AllEvents = ({ loading, getEventGeoLocation, eventGeoLocation, getEventSlider, eventSlider }) => {
  const location = useGeoLocation();
  const skip = 0;
  const limit = 100;

  useEffect(() => {
    if (!isEmpty(location) && !location?.loaded) {
      const coordinates = [location?.coordinates?.lng, location?.coordinates?.lat];
      getEventGeoLocation({ coordinates, skip, limit });
      getEventSlider({ coordinates, skip, limit });
    }
  }, [location, skip, limit]);
  // console.log(eventGeoLocation);

  return (
    <div>
      {!isEmpty(eventSlider) && <EventSlider />}
      {loading && <Loader initial={true} />}
      {!loading && (
        <AllCTCarousel
          title={'All Events'}
          subTitle={'Explore what’s going on around you!'}
          msg={
            "World Is Full Of Excitement, And You've Explored Just A Fraction Of What We Have To Offer. Keep Exploring For Other Exciting Events."
          }
          data={eventGeoLocation}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: get(state, 'event.loading', false),
  eventGeoLocation: get(state, 'event.eventGeoLocation', []),
  eventSlider: get(state, 'event.eventSlider', []),
});

export default connect(mapStateToProps, {
  getEventGeoLocation,
  getEventSlider,
})(AllEvents);
