import React from 'react';
import { CTWrapper, CTImage } from '../../common';
import { appsection } from '../../../data';

const AppSection = () => {
  return (
    <CTWrapper className={'py-10'}>
      <div className="mt-10 gap-4 overflow-hidden rounded-[40px] bg-[#ef4444] px-6 pt-6 md:hidden">
        <h3 className="text-center text-xl font-bold leading-7 text-white">{appsection.head}</h3>
        <h3 className="text-center text-xl font-bold leading-7 text-white">{appsection.tthead}</h3>
        <p className="py-2 text-center text-xs font-normal leading-5 text-white">{appsection.subhead}</p>
        <div className="row flex gap-2">
          <div className="flex w-[50%] flex-col gap-2">
            <h4 className="pt-2 text-base font-medium leading-7 tracking-tight text-white">{appsection.title}</h4>
            {appsection.img.map((img, index) => (
              <a key={index} href={img.url} className="h-[30px] w-[100px]" rel="noreferrer" target="_self">
                <CTImage src={img?.src} alt={img?.imgtitle} className="mb-8" />
              </a>
            ))}
            <div className="relative -mb-32  h-[275px] w-full overflow-hidden  rounded-t-lg bg-transparent">
              <CTImage
                src={appsection?.appImg}
                className="absolute inset-0 h-[85%] w-full object-cover object-top p-0 shadow-lg shadow-black"
                alt={appsection.appalt}
              />
            </div>
          </div>
          <div className="w-[50%]">
            <div className="relative h-[300px] w-full 	overflow-hidden rounded-b-xl rounded-t-lg bg-transparent">
              <CTImage
                src={appsection?.appImg1}
                className="absolute inset-0 size-full object-fill shadow-lg shadow-black"
                alt={appsection.appalt}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="hidden overflow-hidden rounded-[40px] bg-[#ef4444]  md:block lg:py-5">
        <div className="row flex gap-2">
          <div className="flex w-[75%] flex-col gap-2 pl-14 lg:w-[85%] xl:pl-24">
            <h3 className="pt-12 text-3xl font-extrabold leading-10 text-white lg:hidden">{appsection.head}</h3>
            <h3 className="pb-2 text-3xl font-extrabold leading-10 text-white lg:hidden">{appsection.tthead}</h3>
            <div className="flex flex-row">
              <div className="w-[65%] xl:mr-12 xl:mt-20 xl:w-[60%]">
                <h3 className=" hidden pt-4 text-5xl font-extrabold leading-[1.2] text-white lg:flex  ">
                  {appsection.head}
                </h3>
                <h3 className="hidden pb-4 text-4xl font-extrabold leading-[1.2] text-white lg:flex">
                  {appsection.tthead}
                </h3>
                <p className="text-sm font-normal tracking-wider text-white lg:text-base xl:text-lg">
                  {appsection.subhead}
                </p>

                {/* <h4 className="mt-6 text-lg font-semibold leading-10 text-white ">{appsection.title}</h4> */}
                {/* <div className="mb-12 mt-2 flex flex-row gap-4 lg:mb-2 xl:mb-20">
                  {appsection.img.map((img, index) => (
                    <a key={index} href={img.url} className="md:h-[40px] md:w-[135px]" rel="noreferrer" target="_self">
                      <img src={img?.src} alt={img?.imgtitle} className="mb-3" />
                    </a>
                  ))}
                </div> */}
              </div>
              <div className="ml-4 w-[45%] lg:w-[35%] xl:w-[35%]">
                <div className="relative bottom-0 top-6 -mb-40 -mt-6 h-[400px] w-full overflow-hidden rounded-t-lg bg-transparent lg:top-16  lg:-mb-72 lg:h-[500px] xl:h-[600px]">
                  <CTImage
                    src={appsection?.appImg}
                    className="absolute inset-0 h-[65%] w-full object-cover object-top lg:h-[80%]"
                    alt={appsection?.appalt}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mr-2 w-[25%] lg:mr-10 lg:w-[30%] xl:w-[25%]">
            <div className="relative bottom-6 h-[400px] w-full overflow-hidden rounded-b-xl bg-transparent lg:bottom-20 lg:-mb-28 lg:h-[500px] xl:h-[600px]">
              <CTImage
                src={appsection?.appImg1}
                className="absolute inset-0 h-[90%] w-full rounded-b-3xl  bg-transparent object-cover object-bottom"
                alt={appsection?.appalt}
              />
            </div>
          </div>
        </div>
      </div>
    </CTWrapper>
  );
};

export default AppSection;
