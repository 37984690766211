import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { CTImage } from '../../common';
import { useMediaQuery, screenSizes } from '../../../hooks';

const EventSlider = ({ eventSlider }) => {
  const [isTablet] = useMediaQuery(screenSizes?.tablet);
  const [isDesktop] = useMediaQuery(screenSizes?.mediumDesktop);
  const [centerSlidePercentage, setCenterSlidePercentage] = useState(100);

  useEffect(() => {
    if (isDesktop) {
      setCenterSlidePercentage(55);
    } else if (isTablet) {
      setCenterSlidePercentage(75);
    } else {
      setCenterSlidePercentage(100);
    }
  }, [isTablet, isDesktop]);

  if (!eventSlider || eventSlider.length === 0) {
    return null;
  }

  return (
    <div className="relative mb-[5px] mt-[2px] pb-5 text-[20px] text-white md:py-5 lg:-mt-[20px]">
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        showIndicators={true}
        showStatus={false}
        centerMode={true}
        centerSlidePercentage={centerSlidePercentage}
        selectedItem={1}
        swipeable={true}
        emulateTouch={true}
        showArrows={false}
        className="w-full"
      >
        {eventSlider?.map((item, index) => (
          <Link to={`/${item?.eventCode}`} key={index}>
            <div className="cursor-pointer bg-white shadow-md md:mx-4 md:rounded-xl md:border md:border-gray-200">
              {item?.poster?.[0]?.url && <CTImageWrapper url={item.poster[0].url} alt={item.name} />}
            </div>
          </Link>
        ))}
      </Carousel>
      <style>
        {`
          .carousel .control-dots .dot.selected {
            background-color: red;
            width: 20px;
            border-radius: 0.375rem;
            bottom: 20px;
          }
          .carousel .control-dots .dot {
            background-color: white;
          }
          @media (max-width: 768px) {
            .carousel .control-dots .dot {
              width: 6px;
              height: 6px;
              margin: 0 4px;
            }
            .carousel .control-dots .dot.selected {
              width: 16px;
              height: 8px;
            }
          }
        `}
      </style>
    </div>
  );
};

const CTImageWrapper = ({ url, alt }) => {
  if (!url) return null;

  return (
    <div className="aspect-[18/10] overflow-hidden md:aspect-[25/10] md:rounded-xl">
      <CTImage className="size-full object-fill" src={url} alt={alt} title={alt} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  eventSlider: get(state, 'event.eventSlider', []),
});

export default connect(mapStateToProps)(EventSlider);
