/* eslint-disable dot-notation */
import axios from 'axios'
import { URL_IV, URL_KEY } from '../config/endPoints'
import { logOut, setErrors } from '../redux/actions/userActions'
import store from '../redux/store'
const CryptoJS = require('crypto-js')
const { dispatch } = store
const instance = axios.create({
  baseURL: '',
})
instance.interceptors.request.use(
  async (request) => {
    const token = await localStorage.getItem('token')
    if (token) {
      request.headers.Authorization = `Bearer ${token}`
    }

    const createdAt = new Date().valueOf()
    const password = `${URL_KEY}${createdAt}`
    const apiKey = CryptoJS.AES.encrypt(password, URL_KEY, {
      iv: URL_IV,
    }).toString()

    request.headers['Content-Type'] = 'application/json'
    request.headers['Access-Control-Allow-Methods'] =
      'GET, PUT, POST, DELETE, OPTIONS'
    request.headers['appType'] = 'Customer'
    request.headers['createdAt'] = createdAt
    request.headers['apikey'] = apiKey
    return request
  },
  (error) => {
    console.log('apiinstance', error)
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { statusCode } = error?.response?.data
    if (statusCode === 401) {
      await dispatch(
        setErrors({
          message: 'Unauthorized user',
          code: 'USER_UNAUTHORIZED_ERROR',
        }),
      )
      await dispatch(logOut())
      window.open('/', '_self')
    } else {
      return Promise.reject(error)
    }
  },
)

export default instance
