import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import PageSeo from '../components/common/PageSeo';
import { home } from '../data';
import {
  getEventGeoLocation,
  getEventCategory,
  getEventTrending,
  getCategoryEvent,
  getCategory1Event,
  getEventSlider,
  searchEvent,
} from '../redux/actions/eventActions';
import useGeoLocation from '../utils/useGeoLocation';
import EventCategory from '../components/Home/EventCategory';
import AppSection from '../components/Home/AppSection';
import Loader from '../components/common/Loader';
import HomeFAQ from '../components/Home/Faq';
import EventTrending from '../components/Home/EventTrending';
import EventAll from '../components/Home/EventAll';
import EventSlider from '../components/Home/EventSlider';
import Entertainment from '../components/Home/Entertainment';
import Liveshows from '../components/Home/Liveshows';
import Holi from '../components/Home/Holi';
//import Holi from '../components/Home/Holi';

const Home = ({
  loading,
  eventCategory,
  eventGeoLocation,
  getEventSlider,
  eventSlider,
  eventTrending,
  categoryEvents,
  eventsResults,
  getEventCategory,
  getEventGeoLocation,
  getEventTrending,
  getCategoryEvent,
  getCategory1Event,
  category1Events,
  searchEvent,
}) => {
  const location = useGeoLocation();
  const categoryName = 'CAMPUS';
  const categoryName1 = 'HOLI';
  const queryString = 'live performances';

  const homeScreenHandler = async () => {
    const coordinates = [location?.coordinates?.lng || '85.7777244', location?.coordinates?.lat || '20.2771104'];
    await Promise.all([
      !isEmpty(location) &&
        !location?.loaded &&
        (getEventGeoLocation({ coordinates, skip: 0, limit: 10 }),
        getEventSlider({ coordinates, skip: 0, limit: 10 }),
        getEventTrending({ coordinates, skip: 0 }),
        getCategoryEvent({ categoryName: categoryName, coordinates, skip: 0 }),
        getCategory1Event({ categoryName: categoryName1, coordinates, skip: 0 }),
        searchEvent({ queryString: String(queryString), coordinates })),
      getEventCategory({ skip: 0 }),
    ]);
  };
  useEffect(() => {
    homeScreenHandler();
  }, [queryString, location]);
  return (
    <React.Fragment>
      <PageSeo {...home.seo} />
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          {!isEmpty(eventSlider) && <EventSlider />}
          {/* <EventSlider /> */}
          {!isEmpty(eventCategory) && <EventCategory />}
          {!isEmpty(eventTrending) && <EventTrending />}
          {!isEmpty(eventGeoLocation) && <EventAll />}
          {!isEmpty(categoryEvents) && <Entertainment />}
          {!isEmpty(category1Events) && <Holi />}
          {!isEmpty(eventsResults) && <Liveshows />}
          <AppSection />
          <HomeFAQ />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  eventCategory: get(state, 'event.eventCategory', {}),
  eventGeoLocation: get(state, 'event.eventGeoLocation', {}),
  eventSlider: get(state, 'event.eventSlider', {}),
  eventTrending: get(state, 'event.eventTrending', {}),
  loading: get(state, 'event.loading', false),
  categoryEvents: get(state, 'event.categoryEvents', []),
  category1Events: get(state, 'event.category1Events', []),
  eventsResults: get(state, 'event.eventsResults', []),
});

export default connect(mapStateToProps, {
  getEventCategory,
  getEventGeoLocation,
  getEventTrending,
  getCategoryEvent,
  getCategory1Event,
  getEventSlider,
  searchEvent,
})(Home);
