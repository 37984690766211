import React from 'react';

const ReviewCard = ({ item }) => {
  return (
    <article className="m-4 flex h-[419px] w-[288px] shrink-0 flex-col items-center justify-center md:w-[400px]">
      <div className="h-[272px] w-[288px] items-center justify-center rounded-lg bg-white p-4 shadow-md md:w-[400px]">
        <p className="text-center text-lg font-semibold text-[#18191F]">{item.title}</p>
        <p className="mt-2 text-center text-sm font-normal text-[#18191F] md:text-base">{item.content}</p>
      </div>
      <div className="mt-auto flex h-[147px] w-[288px] flex-col items-center p-4">
        <img src={item.image} width={56} height={56} className="rounded-full border-2 shadow-lg" alt="icon" />
        <div className="text-center">
          <p className="text-lg font-bold text-[#18191F]">{item.author}</p>
          <p className="text-sm text-[#474A57]">{item.organization}</p>
        </div>
      </div>
    </article>
  );
};

export default ReviewCard;
