import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { searchEvent } from '../redux/actions/eventActions';
import useGeoLocation from '../utils/useGeoLocation';
import { get, isEmpty } from 'lodash';
import Loader from '../components/common/Loader';
import { useParams } from 'react-router-dom';
import AllCTCarousel from '../components/common/AllCTCarousel';

const SearchPage = ({ loading, searchEvent, eventsResults }) => {
  const location = useGeoLocation();
  const { queryString } = useParams();
  useEffect(() => {
    if (!isEmpty(location) && !location?.loaded) {
      const coordinates = [location?.coordinates?.lng || '85.7777244', location?.coordinates?.lat || '20.2771104'];
      searchEvent({ queryString: String(queryString), coordinates });
    }
  }, [queryString, location]);

  if (loading || !Array.isArray(eventsResults)) {
    return <Loader initial={true} />;
  }

  // console.log(eventsResults);
  return (
    <div>
      {loading && <Loader initial={true} />}
      {!loading && (
        <AllCTCarousel
          title={`Search ${eventsResults.length > 1 ? 'results' : 'result'} of '${queryString}'`}
          msg={`World Is Full Of Excitement, And You've Explored Just A Fraction Of What We Have To Offer. Keep Exploring For Other Exciting Events.`}
          data={eventsResults}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: get(state, 'event.loading', false),
  eventsResults: get(state, 'event.eventsResults', []),
});

export default connect(mapStateToProps, {
  searchEvent,
})(SearchPage);
