import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Disclosure } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { logOut } from '../../redux/actions/userActions';
import { BiLogOut, BiLogIn, BiSearch, BiMenu } from 'react-icons/bi';
import { CTImage, CTWrapper } from '../common';
import AnchorLink from 'react-anchor-link-smooth-scroll';
// import { XIcon  } from '@heroicons/react/solid';
import { IoClose } from 'react-icons/io5';
import greetLogo from '../../Images/common/logo_.png';
import cat from '../../Images/auth/cat.svg';
import dog from '../../Images/auth/dog.svg';
import horse from '../../Images/auth/horse.svg';
import monkey from '../../Images/auth/monkey.svg';
import robot from '../../Images/auth/robot.svg';

const Navbar = ({ user, token, handleOpen, logOut, isAuthenticated }) => {
  const navigate = useNavigate();
  const headerRef = useRef(null);
  const images = [cat, dog, horse, monkey, robot];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [query, setQuery] = useState('');

  useEffect(() => {
    const changeImage = () => {
      const randomNumber = Math.floor(Math.random() * images.length);
      setCurrentImageIndex(randomNumber);
    };
    changeImage();

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleLogOut = async () => {
    await logOut();
  };

  const handleClickOutside = (event) => {
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      setIsSearchOpen(false);
      setToggleMenu(false);
    }
  };

  const searchQueryHandler = (event) => {
    if (event.key === 'Enter' && query.length > 0) {
      navigate(`/search/${query}`);
      setTimeout(() => {
        setIsSearchOpen(false);
      }, 1000);
    }
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    setToggleMenu(false);
  };

  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu);
    setIsSearchOpen(false);
  };

  return (
    <header ref={headerRef} className="fixed top-0 z-[30] block w-full bg-black text-white sm:-top-1 md:top-0">
      <CTWrapper className="relative">
        <div className="relative flex items-center justify-between p-0 py-3">
          <div className="float-left mr-[auto] flex max-w-full flex-auto items-center">
            <div className="flex flex-wrap items-center">
              <div className="relative flex list-none flex-row flex-wrap">
                <Link to="/" className="text-primary-100 flex cursor-pointer pl-0 pr-[16px] leading-none no-underline">
                  <CTImage
                    src={greetLogo}
                    alt="Greet"
                    title="Greet"
                    className="h-[30px] max-w-[170px] md:h-8 md:max-w-[180px]"
                  />
                </Link>
              </div>
              <nav className="hidden items-center gap-8 lg:flex">
                <AnchorLink href="#footer">
                  <button aria-label="Contact Us" className="hover:text-rose-500">
                    Contact Us
                  </button>
                </AnchorLink>
                <AnchorLink href="#FAQ">
                  <button aria-label="FAQs" className="hover:text-rose-500">
                    FAQs
                  </button>
                </AnchorLink>
              </nav>
            </div>
          </div>

          <Disclosure>
            <div className="ml-[auto] max-w-full flex-auto">
              <div className=" flex items-center justify-end">
                <div className="flex items-center">
                  <Link to="/organiser">
                    <button className="text-primary-100 mr-4 hidden cursor-pointer justify-end rounded-full bg-[#FE3838] px-4  py-2 text-xs font-semibold uppercase leading-5 tracking-wider text-white no-underline lg:flex">
                      Organiser Dashboard
                    </button>
                  </Link>
                  <button
                    className="text-primary-100 mr-4 flex cursor-pointer justify-end leading-none text-white no-underline"
                    onClick={toggleSearch}
                  >
                    <BiSearch className="size-6" />
                  </button>
                  {token && isAuthenticated ? (
                    <Disclosure.Button aria-label="Toggle Menu" className={'hidden lg:flex '}>
                      <img
                        src={user.profilePicurl ? user.profilePicurl : images[currentImageIndex]}
                        alt={user.name}
                        className="size-10 rounded-full"
                      />
                    </Disclosure.Button>
                  ) : (
                    <button className="hidden cursor-pointer bg-transparent no-underline lg:flex" onClick={handleOpen}>
                      <div className="mr-3 inline-block w-full cursor-pointer content-center rounded-lg px-2 py-1 text-center text-sm font-bold text-white hover:bg-transparent hover:text-red-500 sm:max-w-none md:max-w-max">
                        <BiLogIn className="size-6" />
                      </div>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <button className="pl-2 lg:hidden" onClick={handleToggleMenu} aria-label="Toggle Menu">
              <BiMenu className="size-6 text-white" />
            </button>
            <Disclosure.Panel>
              {token && isAuthenticated && (
                <div className="absolute right-6 mt-6 w-40 rounded-lg bg-white shadow-2xl">
                  <div className="flex w-full flex-row items-center justify-center rounded-t-lg px-4 py-2 text-gray-800 hover:bg-[#ef4444] hover:text-white">
                    <div className="flex flex-row items-center">
                      Hello,
                      <div className="px-1">{/* <MdWavingHand /> */}</div>
                    </div>
                    <div>{user.name.split(' ')[0]}</div>
                  </div>
                  <hr className="bg-black" />
                  <Link to="/mybooking">
                    <button
                      className="flex w-full items-center justify-center rounded-b-lg px-4 py-2 text-gray-800 hover:bg-[#ef4444] hover:text-white"
                      aria-label="Tickets"
                    >
                      My Tickets
                    </button>
                  </Link>
                  <hr className="bg-black" />

                  <button
                    className="flex w-full items-center justify-center rounded-b-lg px-4 py-2 text-gray-800 hover:bg-[#ef4444] hover:text-white"
                    onClick={token && isAuthenticated ? handleLogOut : handleOpen}
                  >
                    <div className="pr-2">
                      <BiLogOut />
                    </div>
                    Logout
                  </button>
                </div>
              )}
            </Disclosure.Panel>
          </Disclosure>
        </div>
        <div
          className={`flex w-full flex-col gap-4 overflow-hidden text-white transition-all duration-500 ${
            !toggleMenu ? 'h-0' : 'h-auto'
          }`}
        >
          <div className="justify-right mt-2 flex flex-col items-end gap-4 py-2">
            <AnchorLink href="#footer">
              <button aria-label="Contact Us" className="hover:text-rose-500">
                Contact Us
              </button>
            </AnchorLink>
            <AnchorLink href="#FAQ">
              <button aria-label="FAQs" className="hover:text-rose-500">
                FAQs
              </button>
            </AnchorLink>
            <Link to="/organiser">
              <button aria-label="FAQs" className="hover:text-rose-500">
                Organiser Dashboard
              </button>
            </Link>
            {/* <Link to='/organiser'>
              <button
                className="text-primary-100 flex cursor-pointer font-semibold bg-[#FE3838] uppercase  py-2 px-4 tracking-wider rounded-full justify-end leading-5  text-white no-underline"
              >
                Organiser Dashboard
              </button>
            </Link> */}
            <div>
              {token && isAuthenticated ? (
                <div className="flex flex-col items-end justify-end">
                  <div className="flex flex-row">
                    <img
                      src={user.profilePicurl ? user.profilePicurl : images[currentImageIndex]}
                      alt={user.name}
                      className="size-9 rounded-full pr-2"
                    />
                    <div className="flex w-full flex-row items-center justify-end rounded-t-lg pb-2 text-white hover:text-rose-500">
                      <div className="flex flex-row items-center">
                        Hello,
                        <div className="px-1">{/* <MdWavingHand /> */}</div>
                      </div>
                      <div>{user.name.split(' ')[0]}</div>
                    </div>
                  </div>
                  <Link to="/mybooking">
                    <button
                      aria-label="Tickets"
                      className="flex w-full items-end justify-end rounded-b-lg pb-2  text-right text-white hover:text-rose-500"
                    >
                      My Tickets
                    </button>
                  </Link>

                  <button
                    className="flex w-full items-center justify-end rounded-b-lg  text-white hover:text-rose-500"
                    onClick={token && isAuthenticated ? handleLogOut : handleOpen}
                  >
                    <div className="items-center pb-2 pr-2">
                      <BiLogOut />
                    </div>
                    Logout
                  </button>
                </div>
              ) : (
                <button className="cursor-pointer bg-transparent no-underline" onClick={handleOpen}>
                  <div className="justify-right flex w-full items-end rounded-b-lg pb-2 text-white hover:text-rose-500">
                    Login{' '}
                    <div className="pl-2">
                      {' '}
                      <BiLogIn className="size-6" />
                    </div>
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>
      </CTWrapper>
      {isSearchOpen && (
        <div className="absolute left-0 top-full w-full border-none bg-rose-50 py-2 outline-none focus:outline-none">
          <CTWrapper>
            <button className="absolute inset-y-0 flex items-center justify-center text-rose-600 hover:text-red-700 focus:outline-none">
              <BiSearch className="size-5" />
            </button>
            <input
              type="text"
              onChange={(e) => setQuery(e.target.value)}
              onKeyUp={searchQueryHandler}
              aria-label="Search"
              className="block w-full border-none bg-rose-50 py-2 pl-7 text-sm text-rose-900 placeholder:text-rose-500 focus:border-transparent focus:outline-none"
              placeholder="Eg. Music Concerts Near Me..."
              autoFocus
            />
            <button
              onClick={toggleSearch}
              className="absolute inset-y-0 right-8 justify-end text-rose-600 hover:text-red-700 focus:outline-none md:right-12 xl:right-36"
            >
              <IoClose className="size-5" />
            </button>
          </CTWrapper>
        </div>
      )}
    </header>
  );
};

const mapStateToProps = (state) => ({
  token: get(state, 'auth.token'),
  isAuthenticated: get(state, 'auth.isAuthenticated'),
  user: get(state, 'auth.user', {}),
});

export default connect(mapStateToProps, { logOut })(Navbar);
