import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { clearErrors } from '../../redux/actions/userActions';
import { get, isEmpty } from 'lodash';
const MessageDialog = ({ isOpen, handleClose, message, eventInfo }) => {
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const history = useNavigate();
  const goToMyBooking = () => {
    history(`/${eventInfo?.eventCode}/mybooking`);
    handleClose();
  };
  const goToHome = () => {
    if (!isEmpty(eventInfo)) {
      history(`/${eventInfo?.eventCode}`);
    }
    handleClose();
  };
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-30 overflow-y-auto" initialFocus={cancelButtonRef} onClose={() => {}}>
        <div
          className="flex min-h-screen items-center justify-center p-0 px-4 pb-20
       pt-4"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative size-full max-w-md p-4 md:h-auto">
              {/* Modal content */}
              <div className="relative rounded-lg bg-white shadow">
                <button
                  type="button"
                  className="absolute right-2.5 top-3 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
                  onClick={() => {
                    dispatch(clearErrors());
                    handleClose();
                  }}
                >
                  <svg
                    aria-hidden="true"
                    className="size-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="p-6 lg:px-8">
                  <h3 className="mb-4 text-xl font-medium text-gray-900">{message?.userText || message?.userText}</h3>
                  <p className="mb-4 text-xl font-medium text-gray-500">{message?.transactionText}</p>
                  {!message?.userText && (
                    <button
                      type="submit"
                      className="w-full rounded-lg bg-rose-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-rose-600 focus:outline-none focus:ring-4 focus:ring-blue-300"
                      onClick={message?.transactionSuccessful ? goToMyBooking : goToHome}
                    >
                      {message?.transactionSuccessful ? 'OK' : 'Go To Home'}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
const mapStateToProps = (state) => ({
  eventInfo: get(state, 'event.eventInfo', {}),
});
export default connect(mapStateToProps)(MessageDialog);
