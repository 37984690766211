import {
  CLEAR_ERRORS,
  EVENT_INFO_REQUEST,
  EVENT_INFO_SUCCESS,
  EVENT_INFO_FAIL,
  ADD_EVENT_NEW_ITEM,
  REMOVE_EVENT_NEW_ITEM,
  ADD_NEW_ANSWER,
  REMOVE_NEW_ANSWER,
  EVENT_CATEGORY_REQUEST,
  EVENT_CATEGORY_SUCCESS,
  EVENT_CATEGORY_FAIL,
  FETCH_EVENTS_REQUEST,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_FAILURE,
  EVENT_GEOLOCATION_REQUEST,
  EVENT_GEOLOCATION_SUCCESS,
  EVENT_GEOLOCATION_FAIL,
  EVENT_LIVE_REQUEST,
  EVENT_LIVE_SUCCESS,
  EVENT_LIVE_FAIL,
  EVENT_EXPIRED_REQUEST,
  EVENT_EXPIRED_SUCCESS,
  EVENT_EXPIRED_FAIL,
  EVENT_TRENDING_REQUEST,
  EVENT_TRENDING_SUCCESS,
  EVENT_TRENDING_FAIL,
  EVENT_ALLEXPIRED_REQUEST,
  EVENT_ALLEXPIRED_SUCCESS,
  EVENT_ALLEXPIRED_FAIL,
  SEARCH_EVENT_REQUEST,
  SEARCH_EVENT_SUCCESS,
  SEARCH_EVENT_FAILURE,
  CATEGORY_INFO_FAIL,
  CATEGORY_INFO_REQUEST,
  CATEGORY_INFO_SUCCESS,
  CATEGORY_BY_EVENT_REQUEST,
  CATEGORY_BY_EVENT_SUCCESS,
  CATEGORY_BY_EVENT_FAIL,
  UPLOAD_IMAGE_FAIL,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
  EVENT_SLIDER_FAIL,
  EVENT_SLIDER_REQUEST,
  EVENT_SLIDER_SUCCESS,
  CATEGORY1_BY_EVENT_FAIL,
  CATEGORY1_BY_EVENT_REQUEST,
  CATEGORY1_BY_EVENT_SUCCESS,
  APPLY_COUPON_FAILURE,
  APPLY_COUPON_REQUEST,
  APPLY_COUPON_SUCCESS,
  FETCH_ACTIVE_DATES_FAILURE,
  FETCH_ACTIVE_DATES_SUCCESS,
  FETCH_ACTIVE_DATES_REQUEST,
  FETCH_ACTIVE_TICKETS_REQUEST,
  FETCH_ACTIVE_TICKETS_SUCCESS,
  FETCH_ACTIVE_TICKETS_FAILURE,
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPLOAD_PROFILE_PIC_REQUEST,
  UPLOAD_PROFILE_PIC_SUCCESS,
  UPLOAD_PROFILE_PIC_FAILURE,
} from '../constants';
const initState = {
  loading: false,
  eventInfo: {},
  eventGeoLocation: null,
  eventExpired: null,
  eventLive: null,
  eventAllexpired: null,
  eventTrending: null,
  categoryInfo: null,
  eventsbycategory: null,
  categoryEvents: [],
  category1Events: [],
  eventsResults: [],
  uploading: false,
  imageUrl: [],
  error: {},
  eventSlider: null,
  coupon: null,
  activeDates: [],
  activeTickets: [],
  user: {},
};
// Event reducer
export const eventReducer = (state = initState, action) => {
  switch (action.type) {
    case EVENT_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case EVENT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        eventInfo: {
          ...action?.payload,
          ticketTypes: action?.payload?.ticketTypes.map((item) => ({
            ...item,
            quantity: 0,
          })),
        },
        error: {},
      };
    case EVENT_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_ACTIVE_DATES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ACTIVE_DATES_SUCCESS:
      return {
        ...state,
        activeDates: action.payload,
        loading: false,
        error: null,
      };
    case FETCH_ACTIVE_DATES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case FETCH_ACTIVE_TICKETS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ACTIVE_TICKETS_SUCCESS:
      return {
        ...state,
        activeTickets: action.payload,
        loading: false,
        error: null,
      };
    case FETCH_ACTIVE_TICKETS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case FETCH_PROFILE_REQUEST:
    case UPDATE_PROFILE_REQUEST:
    case UPLOAD_PROFILE_PIC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case FETCH_PROFILE_FAILURE:
    case UPDATE_PROFILE_FAILURE:
    case UPLOAD_PROFILE_PIC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case UPLOAD_PROFILE_PIC_SUCCESS:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          profilePictureUrl: action.payload,
        },
      };
    case UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        uploading: true,
        error: '',
      };
    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        uploading: false,
        imageUrl: action.payload,
        error: '',
      };
    case UPLOAD_IMAGE_FAIL:
      return {
        ...state,
        uploading: false,
        error: action.payload.message,
      };
    case 'STORE_IMAGE_URL':
      const { imageUrl, fileType } = action.payload;
      return {
        ...state,
        [fileType]: imageUrl,
      };
    case CATEGORY_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case CATEGORY_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryInfo: action?.payload,
        error: {},
      };
    case CATEGORY_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EVENT_SLIDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case EVENT_SLIDER_SUCCESS:
      return {
        ...state,
        loading: false,
        eventSlider: action?.payload,
        error: {},
      };
    case EVENT_SLIDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EVENT_GEOLOCATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case EVENT_GEOLOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        eventGeoLocation: action?.payload,
        error: {},
      };
    case EVENT_GEOLOCATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SEARCH_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case SEARCH_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        eventsResults: action?.payload,
        error: {},
      };
    case SEARCH_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case APPLY_COUPON_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case APPLY_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        coupon: action?.payload,
        error: {},
      };
    case APPLY_COUPON_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CATEGORY_BY_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case CATEGORY_BY_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryEvents: action?.payload,
        error: {},
      };
    case CATEGORY_BY_EVENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CATEGORY1_BY_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case CATEGORY1_BY_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        category1Events: action?.payload,
        error: {},
      };
    case CATEGORY1_BY_EVENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EVENT_TRENDING_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case EVENT_TRENDING_SUCCESS:
      return {
        ...state,
        loading: false,
        eventTrending: action?.payload,
        error: {},
      };
    case EVENT_TRENDING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EVENT_ALLEXPIRED_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case EVENT_ALLEXPIRED_SUCCESS:
      return {
        ...state,
        loading: false,
        eventAllExpired: action?.payload,
        error: {},
      };
    case EVENT_ALLEXPIRED_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_EVENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        eventsbycategory: action.payload,
      };
    case FETCH_EVENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case EVENT_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case EVENT_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        eventCategory: action?.payload,
        error: {},
      };
    case EVENT_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EVENT_LIVE_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case EVENT_LIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        eventLive: action?.payload,
        error: {},
      };
    case EVENT_LIVE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EVENT_EXPIRED_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case EVENT_EXPIRED_SUCCESS:
      return {
        ...state,
        loading: false,
        eventExpired: action?.payload,
        error: {},
      };
    case EVENT_EXPIRED_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_EVENT_NEW_ITEM:
      /* eslint-disable no-unneeded-ternary */
      return {
        ...state,
        eventInfo: {
          ...state.eventInfo,
          ticketTypes: state.eventInfo.ticketTypes.map((item) =>
            item.code === action.payload.code
              ? item.singleton
                ? {
                    ...item,
                    quantity: action.payload.quantity,
                    disabled: false,
                  }
                : {
                    ...item,
                    quantity: action.payload.quantity,
                  }
              : item.singleton
              ? {
                  ...item,
                  disabled: action.payload.quantity === 0 ? false : true,
                }
              : item,
          ),
        },
      };
    case REMOVE_EVENT_NEW_ITEM:
      return {
        ...state,
        eventInfo: {
          ...state.eventInfo,
          ticketTypes: state.eventInfo.ticketTypes.map((item) => ({
            ...item,
            quantity: 0,
            disabled: false,
          })),
        },
      };
    case ADD_NEW_ANSWER:
      return {
        ...state,
        eventInfo: {
          ...state.eventInfo,
          newExtraQuestions: action.payload,
        },
      };
    case REMOVE_NEW_ANSWER:
      return {
        ...state,
        eventInfo: {
          ...state.eventInfo,
          newExtraQuestions: '',
        },
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: {},
      };
    default:
      return state;
  }
};
