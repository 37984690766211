// Rating.js
import React from 'react';
import { StarIcon } from '@heroicons/react/solid';

const Rating = ({ rating }) => {
  // Assuming rating is a decimal number (e.g., 4.8)
  const stars = [];

  for (let i = 0; i < 5; i++) {
    if (i < Math.floor(rating)) {
      stars.push(<StarIcon key={i} className="size-5 text-yellow-500" />);
    } else if (i === Math.floor(rating) && rating % 1 !== 0) {
      stars.push(<StarIcon key={i} className="size-5 text-yellow-500" />);
    } else {
      stars.push(<StarIcon key={i} className="size-5 text-gray-400" />);
    }
  }

  return (
    <div className="flex flex-col items-center gap-2">
      <div className="flex flex-row gap-1">{stars}</div>
      <span className="text-gray-500">{rating}/5</span>
    </div>
  );
};

export default Rating;
