import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/solid';
import CTImage from './CTImage';
import GreetFydo from '../../Images/home/greetOne.png';
import { isEmpty, truncate } from 'lodash';
import { GrLocation } from 'react-icons/gr';

const NewEventCard = ({
  eventCode,
  name,
  ticketPriceDisplay,
  eventAddress,
  dateString,
  categoryDisplay,
  poster,
  urlHidden,
  eventCoupons,
}) => {
  const imageUrl = useMemo(() => (!isEmpty(poster) && poster[0]?.url) || GreetFydo, [poster]);
  const firstPercentageDiscount = useMemo(() => {
    if (
      !isEmpty(eventCoupons) &&
      !isEmpty(eventCoupons[0]) &&
      eventCoupons[0].isActive &&
      eventCoupons[0].eventDiscountPercentage
    ) {
      return eventCoupons[0].eventDiscountPercentage;
    }
    return null;
  }, [eventCoupons]);

  return (
    <article className="flex size-auto cursor-default flex-row overflow-hidden rounded-xl bg-white shadow-md transition-transform duration-300 hover:translate-y-2 hover:shadow-lg sm:h-[323px] sm:w-[266px] mm:h-[352px] mm:w-[313px] lm:h-[377px] lm:w-[353px] md:h-[358px] md:w-[310px] lg:h-[323px] lg:w-[255px] xl:h-[383px] xl:w-[359px]">
      <Link to={!urlHidden ? `/${eventCode}` : '/'}>
        <div className="flex flex-col">
          <div className="relative aspect-[18/10] size-auto overflow-hidden sm:h-[146px] sm:w-[266px] mm:h-[175px] mm:w-[313px] lm:h-[200px] lm:w-[353px] md:aspect-[25/10] md:h-[175px] md:w-[310px] lg:h-[140px] lg:w-[255px] xl:h-[200px] xl:w-[359px]">
            <CTImage src={imageUrl} title={name} alt={`Image for ${name}`} className="size-full  object-center" />
            {/* <div className="absolute right-4 top-4 rounded-md bg-rose-500 px-[8px] py-[6px] text-xs font-semibold uppercase text-white md:text-sm lg:text-xs">
              {categoryDisplay}
            </div> */}
            {firstPercentageDiscount && (
              <div className="absolute left-0 top-4 rounded-r-md bg-green-700 px-[8px] py-[6px] text-xs font-semibold uppercase text-white md:text-sm lg:text-xs">
                {firstPercentageDiscount}% OFF
              </div>
            )}
          </div>
          <div className="flex size-auto flex-col justify-between gap-2 p-[9px] sm:h-[177px] sm:w-[266px] mm:w-[313px] lm:w-[353px] md:h-[183px] md:w-[310px] lg:w-[255px] xl:w-[359px] ">
            <div className="flex flex-row justify-between">
              <h3 className="overflow-hidden text-sm font-bold leading-relaxed text-rose-600 hover:text-gray-900 md:text-base ">
                {truncate(name, { length: 50 })}
              </h3>
              <div className="-mr-3 ml-2 h-[35px] items-center text-nowrap rounded-l-lg bg-rose-600 p-3 text-xs font-semibold uppercase text-white">
                {categoryDisplay}
              </div>
            </div>
            <div className="mt-[3px] flex flex-col justify-start gap-[6px]">
              <div className="flex flex-row items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24">
                  <path
                    fill="#0ea5e9"
                    d="M15 13h1.5v2.82l2.44 1.41l-.75 1.3L15 16.69zm4-5H5v11h4.67c-.43-.91-.67-1.93-.67-3a7 7 0 0 1 7-7c1.07 0 2.09.24 3 .67zM5 21a2 2 0 0 1-2-2V5c0-1.11.89-2 2-2h1V1h2v2h8V1h2v2h1a2 2 0 0 1 2 2v6.1c1.24 1.26 2 2.99 2 4.9a7 7 0 0 1-7 7c-1.91 0-3.64-.76-4.9-2zm11-9.85A4.85 4.85 0 0 0 11.15 16c0 2.68 2.17 4.85 4.85 4.85A4.85 4.85 0 0 0 20.85 16c0-2.68-2.17-4.85-4.85-4.85"
                  />
                </svg>
                <span className="overflow-hidden text-nowrap pl-2 text-xs font-semibold leading-relaxed text-[#585163] md:text-sm">
                  {dateString && truncate(dateString, { length: 30 })}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <GrLocation size={20} className="text-sky-500" />
                <span className="overflow-hidden text-nowrap pl-2 text-xs font-semibold leading-relaxed text-[#585163] md:text-sm">
                  {eventAddress && truncate(eventAddress, { length: 30 })}
                </span>
              </div>
            </div>
            <div className="flex h-[38px] flex-row items-center justify-between gap-3 text-nowrap rounded-lg bg-rose-50 p-[9px] text-sm font-semibold uppercase leading-relaxed text-rose-600">
              <p className="truncate">{ticketPriceDisplay}</p>
              <div className="flex items-center">
                <p className="whitespace-nowrap border-l border-rose-500 pl-4 uppercase">Register Now</p>
                <ArrowRightIcon className="size-4" />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </article>
  );
};

export default NewEventCard;
