import { combineReducers } from 'redux'
import { eventReducer } from './eventReducers'
import { authReducer } from './userReducers'

const reducer = combineReducers({
  auth: authReducer,
  event: eventReducer,
})

export default reducer
