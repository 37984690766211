import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
//import CCarousel from '../../common/CCarousel';
import CTCarousel from '../../common/CTCarousel';

const Entertainment = ({ categoryEvents }) => {
  return (
    <div>
      <CTCarousel
        title={'Campus Events'}
        subTitle={'Explore what’s going on around you!'}
        data={categoryEvents}
        link={'allevents/campus'}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  categoryEvents: get(state, 'event.categoryEvents', []),
});

export default connect(mapStateToProps)(Entertainment);
