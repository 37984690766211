import React from 'react';
import { CTWrapper } from '../../common';
import { pricing } from '../../../data';

const PricingSection = () => {
  return (
    <CTWrapper className="flex flex-col items-center gap-10 py-20 lg:flex-row lg:gap-4">
      <div className="mx-auto">
        <p className="text-[1.125rem] font-bold uppercase tracking-wider text-[#FE3838]">{pricing.title}</p>
        <p className="mt-3 text-[2rem] font-bold leading-[38px] text-[#170F49] md:text-[2.5rem] md:leading-[48px]">
          {pricing.subTitle}
        </p>
      </div>
      <div className="flex flex-col gap-4 md:flex-row">
        {pricing.price.map((item, index) => (
          <div key={index} className="rounded-[32px] border-[0.787px] border-[#E5F4F2] px-8 py-12 shadow-lg">
            <div className="flex items-center gap-[14px]">
              <div className="size-[58px] items-center justify-center rounded-lg border border-[#ECEBFF] p-2">
                {item?.icon}
              </div>
              <p className="text-[0.875rem] font-medium text-[#6F6C90]">
                {item?.head} <br /> <span className="text-[1.125rem] font-bold text-[#170F49]">{item?.mode}</span>
              </p>
            </div>
            <p className="mt-4 text-[1rem] text-[#6F6C90]">{item?.description}</p>
          </div>
        ))}
      </div>
    </CTWrapper>
  );
};

export default PricingSection;
