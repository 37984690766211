import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { SearchIcon, MenuIcon, XIcon } from '@heroicons/react/solid';
import { BiLogIn, BiLogOut } from 'react-icons/bi';
import { Disclosure } from '@headlessui/react';
import { connect } from 'react-redux';
import greet from '../../Images/common/logo-dark.png';
import { logOut } from '../../redux/actions/userActions';
import imageFydo from '../../Images/common/logo-fydo.png';
import greetLogo from '../../Images/common/logo.png';
import cat from '../../Images/auth/cat.svg';
import dog from '../../Images/auth/dog.svg';
import horse from '../../Images/auth/horse.svg';
import monkey from '../../Images/auth/monkey.svg';
import robot from '../../Images/auth/robot.svg';
import { get } from 'lodash';
import CTWrapper from './CTWrapper';

const CTHeader = ({ user, token, handleOpen, logOut, isAuthenticated }) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [fixedHeader, setFixedHeader] = useState(false);

  const headerRef = useRef(null);

  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  const searchBar = () => {
    setShowSearch(!showSearch);
  };

  const handleLogOut = async () => {
    await logOut();
  };

  const images = [cat, dog, horse, monkey, robot];
  const [currentImageIndex, setCurrentImageIndex] = useState();

  const changeImage = () => {
    const randomNumber = Math.floor(Math.random() * images.length);
    setCurrentImageIndex(randomNumber);
  };

  useEffect(() => {
    changeImage();
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleScroll = () => {
    setFixedHeader(window.scrollY > 0);
  };

  const handleClickOutside = (event) => {
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      setShowSearch(false);
      setToggleMenu(false);
    }
  };

  const getLogoSrc = () => {
    return fixedHeader ? greetLogo : greet;
  };

  return (
    <header
      ref={headerRef}
      className={`block w-full ${fixedHeader ? 'fixed top-0 z-[55] bg-black text-white' : 'bg-white text-[#FE3838] '}`}
    >
      <nav className={` ${toggleMenu ? 'relative' : ''}`}>
        <CTWrapper className="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between p-4 ">
          {/* Mobile view */}
          <div className="w-full lg:hidden">
            <div className="flex items-center justify-between">
              {/* Logo and Fydo Image */}
              <div className="flex cursor-pointer items-center space-x-3 pl-0 leading-none no-underline">
                <Link to="/" aria-label="Home">
                  <img
                    src={getLogoSrc()}
                    alt="Greet Logo"
                    title="Greet"
                    className="h-[25px] max-w-[80px] md:h-8 md:max-w-[90px]"
                  />
                </Link>
                <span
                  className={`mx-2 h-8 w-0.5 rounded md:mx-2 md:h-8 ${
                    fixedHeader ? 'bg-white text-black' : 'bg-black text-white'
                  }`}
                />
                <Link to="/" className="flex cursor-pointer pl-0 pr-[16px] leading-none no-underline">
                  <img src={imageFydo} alt="Fydo Logo" className="h-[25px] max-w-[60px] md:h-8 md:max-w-[90px]" />
                </Link>
              </div>
              {/* Toggle Menu Button */}
              <div className="flex items-center space-x-1">
                <button
                  onClick={searchBar}
                  aria-label="Toggle Search Bar"
                  className={`rounded-lg p-2 pr-0 text-center text-sm font-medium ${
                    fixedHeader ? 'text-white hover:bg-rose-600' : 'text-rose-600 hover:text-red-700'
                  }`}
                >
                  <SearchIcon className="size-6" />
                </button>
                <Disclosure>
                  <div className="ml-[auto] max-w-full flex-auto">
                    <div className=" flex items-center justify-end">
                      <div className="flex items-center">
                        {token && isAuthenticated ? (
                          <Disclosure.Button aria-label="Toggle Menu">
                            <img
                              src={user?.profilePicurl ? user?.profilePicurl : images[currentImageIndex]}
                              alt={user?.name}
                              className="size-10 rounded-full"
                            />
                          </Disclosure.Button>
                        ) : (
                          <button
                            type="button"
                            aria-label="Login"
                            className={`rounded-lg p-2 text-center text-sm font-medium ${
                              fixedHeader ? 'text-white hover:bg-rose-600' : 'text-rose-600 hover:text-red-700'
                            }`}
                            onClick={handleOpen}
                          >
                            <BiLogIn className="size-6" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  <Disclosure.Panel>
                    {token && isAuthenticated && (
                      <div className="absolute right-0 mt-6 w-48 rounded-lg bg-white shadow-2xl">
                        <div className="flex w-full flex-row items-center justify-center rounded-t-lg px-4 py-2 text-gray-800 hover:bg-[#ef4444] hover:text-white">
                          <div className="flex flex-row items-center">
                            Hello,
                            <div className="px-1">{/* <MdWavingHand /> */}</div>
                          </div>
                          <div>{user.name.split(' ')[0]}</div>
                        </div>
                        <hr className="bg-black" />
                        <button
                          className="flex w-full items-center justify-center rounded-b-lg px-4 py-2 text-gray-800 hover:bg-[#ef4444] hover:text-white"
                          onClick={token && isAuthenticated ? handleLogOut : handleOpen}
                        >
                          <div className="pr-2">
                            <BiLogOut />
                          </div>
                          Logout
                        </button>
                      </div>
                    )}
                  </Disclosure.Panel>
                </Disclosure>
                <button onClick={handleToggleMenu} aria-label="Toggle Menu">
                  <MenuIcon className="size-6" />
                </button>
              </div>
            </div>
            {/* Mobile Menu */}
            <div
              className={`flex w-full flex-col gap-4 overflow-hidden transition-all duration-500 ${
                !toggleMenu ? 'h-0' : 'h-auto'
              }`}
            >
              <div className="mt-2 flex flex-col gap-4 pt-2">
                <Link to="/" aria-label="Contact Us" className="hover:text-rose-500">
                  Contact Us
                </Link>
                <Link to="/" aria-label="FAQs" className="hover:text-rose-500">
                  FAQs
                </Link>
              </div>
            </div>
          </div>

          {/* Desktop view */}
          <div className="hidden w-full items-center justify-between lg:flex">
            {/* Logo and Fydo Image */}
            <div className="flex cursor-pointer items-center space-x-3 pl-0 leading-none no-underline">
              <Link to="/" aria-label="Home">
                <img
                  src={getLogoSrc()}
                  alt="Greet Logo"
                  title="Greet"
                  className="h-[30px] max-w-[150px] md:h-8 md:max-w-[150px]"
                />
              </Link>
              <span
                className={`mx-2 h-8 w-0.5 rounded md:mx-2 md:h-8 ${
                  fixedHeader ? 'bg-white text-black' : 'bg-black text-white'
                }`}
              />
              <Link to="/" className="flex cursor-pointer pl-0 pr-[16px] leading-none no-underline">
                <img src={imageFydo} alt="Fydo Logo" className="h-[30px] max-w-[90px] md:h-8 md:max-w-[90px]" />
              </Link>
            </div>
            {/* Navigation Links */}
            <nav className="flex grow items-center gap-8">
              <Link to="/" aria-label="Contact Us" className="hover:text-rose-500">
                Contact Us
              </Link>
              <Link to="/" aria-label="FAQs" className="hover:text-rose-500">
                FAQs
              </Link>
            </nav>
            {/* Search Bar and Login Button */}
            <div className="flex items-center space-x-2">
              <button
                onClick={searchBar}
                aria-label="Toggle Search Bar"
                className={`rounded-lg px-4 py-2 pr-0 text-center text-sm font-semibold uppercase tracking-wider ${
                  fixedHeader ? 'text-white hover:bg-rose-600' : 'text-rose-600 hover:text-red-700'
                }`}
              >
                <SearchIcon className="size-6" />
              </button>
              <Disclosure>
                <div className="ml-[auto] max-w-full flex-auto">
                  <div className=" flex items-center justify-end">
                    <div className="flex items-center">
                      {token && isAuthenticated ? (
                        <Disclosure.Button aria-label="Toggle Menu">
                          <img
                            src={user?.profilePicurl ? user?.profilePicurl : images[currentImageIndex]}
                            alt={user?.name}
                            className="size-10 rounded-full"
                          />
                        </Disclosure.Button>
                      ) : (
                        <button
                          type="button"
                          aria-label="Login"
                          className={`rounded-lg px-4 py-2 text-center text-sm font-semibold uppercase tracking-wider ${
                            fixedHeader ? 'text-white hover:bg-rose-600' : 'text-rose-600 hover:text-red-700'
                          }`}
                          onClick={handleOpen}
                        >
                          <BiLogIn className="size-6" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <Disclosure.Panel>
                  {token && isAuthenticated && (
                    <div className="absolute right-0 mt-6 w-48 rounded-lg bg-white shadow-2xl">
                      <div className="flex w-full flex-row items-center justify-center rounded-t-lg px-4 py-2 text-gray-800 hover:bg-[#ef4444] hover:text-white">
                        <div className="flex flex-row items-center">
                          Hello,
                          <div className="px-1">{/* <MdWavingHand /> */}</div>
                        </div>
                        <div>{user.name.split(' ')[0]}</div>
                      </div>
                      <hr className="bg-black" />
                      <button
                        className="flex w-full items-center justify-center rounded-b-lg px-4 py-2 text-gray-800 hover:bg-[#ef4444] hover:text-white"
                        onClick={token && isAuthenticated ? handleLogOut : handleOpen}
                      >
                        <div className="pr-2">
                          <BiLogOut />
                        </div>
                        Logout
                      </button>
                    </div>
                  )}
                </Disclosure.Panel>
              </Disclosure>
            </div>
          </div>
        </CTWrapper>
      </nav>

      <div
        className={`flex w-full flex-col gap-4 overflow-hidden transition-all duration-500 ${
          !showSearch ? 'h-0' : 'h-auto'
        }`}
      >
        <div className="relative justify-center">
          <button className="absolute inset-y-0 left-0 flex items-center justify-center px-3 pl-5 text-rose-600 hover:text-red-700 focus:outline-none md:pl-10 xl:pl-[120px]">
            <SearchIcon className="size-5" />
          </button>
          <input
            type="text"
            aria-label="Search"
            className="block w-full border-none bg-rose-50 px-12 py-4 text-sm text-rose-900 placeholder:text-rose-500 focus:border-transparent focus:outline-none md:pl-20 xl:pl-[154px]"
            placeholder="Eg. Music Concerts Near Me..."
          />
          <button
            onClick={searchBar}
            className="absolute inset-y-0 right-0 flex items-center justify-center px-3 pr-5 text-rose-600 hover:text-red-700 focus:outline-none md:pr-10 lg:pr-14 xl:pr-[138px]"
          >
            <XIcon className="size-5" />
          </button>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => ({
  token: get(state, 'auth.token'),
  isAuthenticated: get(state, 'auth.isAuthenticated'),
  eventInfo: get(state, 'event.eventInfo', {}),
  singleTicket: get(state, 'auth.singleTicket', {}),
  user: get(state, 'auth.user', {}),
});

export default connect(mapStateToProps, { logOut })(CTHeader);
