import React, { useState, useEffect } from 'react';
import { CTWrapper } from '../../common';
import { featureData } from '../../../data';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

const FeatureSection = () => {
  const [currentCard, setCurrentCard] = useState(0);

  const handleDotClick = (index) => {
    setCurrentCard(index);
  };
  const nextCard = () => {
    setCurrentCard((prevCard) => (prevCard + 1) % featureData.length);
  };

  const prevCard = () => {
    setCurrentCard((prevCard) => (prevCard - 1 + featureData.length) % featureData.length);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextCard();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [currentCard]);

  return (
    <>
      <CTWrapper className="py-10 md:py-20">
        <div className="flex flex-col gap-2 text-center md:gap-4">
          <h1 className="text-xl font-extrabold md:text-2xl lg:text-[32px] xl:text-5xl">Features</h1>
          <p className="text-sm font-normal text-[#2D2D2D] md:text-base xl:text-lg">
            Few good reasons why you should choose Greet as your ticketing partner.
          </p>
        </div>

        <div className="mx-auto mt-4 hidden grid-cols-1 gap-6 md:grid md:grid-cols-2 xl:grid-cols-4">
          {featureData?.map((item, index) => (
            <div
              key={item.id + index}
              className="flex flex-col items-center rounded-2xl border-[0.787px] border-[#E5F4F2] px-[20px] py-[31.473px] text-center shadow-lg"
            >
              <div className={`items-center justify-center rounded-lg p-[10px]  ${item.color} size-[64px]`}>
                {item?.icon}
              </div>
              <h3 className="mt-6 text-base font-semibold text-[#2D2D2D] md:text-xl lg:text-2xl">{item.name}</h3>
              <p className="mt-4 text-sm font-normal text-[#475467] lg:text-base">{item.desc}</p>
            </div>
          ))}
        </div>

        <div className="relative mx-auto mt-4 overflow-hidden md:hidden">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4">
            {featureData?.map((item, index) => (
              <div
                key={item.id + index}
                className={`flex flex-col items-center rounded-2xl border-[0.787px] border-[#E5F4F2] px-[23.605px] py-[31.473px] text-center ${
                  index === currentCard ? 'visible' : 'hidden'
                }`}
              >
                <div className={`items-center justify-center rounded-lg p-[10px]  ${item.color} size-[64px]`}>
                  {item?.icon}
                </div>
                <p className="mt-6 text-base font-semibold text-[#2D2D2D] md:text-xl lg:text-2xl">{item.name}</p>
                <p className="mt-4 text-sm font-normal text-[#475467] lg:text-base">{item.desc}</p>
              </div>
            ))}
          </div>

          <div className="bottom-7 mt-4 flex items-center justify-between md:hidden">
            <button className="left-4 text-3xl" onClick={prevCard}>
              <ChevronLeftIcon className="size-6 text-rose-600 md:size-8 " />
            </button>
            <div className="bottom-10 z-50 flex items-center justify-center gap-2">
              {featureData?.map((_, i) => (
                <span
                  key={i}
                  className={`block cursor-pointer  transition-all content-none ${
                    currentCard === i ? 'h-2 w-4 rounded-2xl bg-rose-600' : 'size-2 rounded-full bg-gray-400'
                  }`}
                  onClick={() => handleDotClick(i)}
                />
              ))}
            </div>
            <button className="right-4 text-3xl" onClick={nextCard}>
              <ChevronRightIcon className="size-6 text-rose-600 md:size-8 " />
            </button>
          </div>
        </div>
      </CTWrapper>
    </>
  );
};

export default FeatureSection;
