import { get, isEmpty } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/common/Loader';
import PageSeo from '../components/common/PageSeo';
import BookHeader from '../components/BookHeader';
import BookHeader_Testing from '../components/BookHeader_Testing';
import ExicitingEvent from '../components/BookHeader/ExcitingEvent';
import { getEventGeoLocation, fetchActiveDates, fetchActiveTickets } from '../redux/actions/eventActions';
import useGeoLocation from '../utils/useGeoLocation';

const TicketPrices = React.lazy(() => import(/* webpackChunkName: "TicketPrices" */ '../components/TicketPrices'));
const BookingHeader = React.lazy(() => import(/* webpackChunkName: "BookingHeader" */ '../components/BookingHeader'));
const Booking = ({
  handleOpen,
  eventInfo,
  fetchActiveDates,
  activeDates,
  activeTickets,
  fetchActiveTickets,
  loading,
  getEventGeoLocation,
  eventGeoLocation,
}) => {
  const location = useGeoLocation();
  const eventId = eventInfo?._id;
  const [selectedEventDate, setSelectedEventDate] = useState(null);
  const handleEventDateChange = (date) => {
    setSelectedEventDate(date);
  };
  const bookingScreenHandler = async () => {
    const coordinates = [location?.coordinates?.lng || '85.7777244', location?.coordinates?.lat || '20.2771104'];
    await Promise.all([
      !isEmpty(location) && !location?.loaded && getEventGeoLocation({ coordinates, skip: 0, limit: 10 }),
      window.scrollTo(0, 0),
      fetchActiveDates({ eventId }),
    ]);
  };
  useEffect(() => {
    bookingScreenHandler();
  }, [eventId, location]);
  useEffect(() => {
    const fetchTickets = async () => {
      if (selectedEventDate) {
        try {
          await fetchActiveTickets({ date: selectedEventDate });
        } catch (error) {
          console.error('Error fetching active tickets:', error);
        }
      }
    };

    fetchTickets();
    return () => {};
  }, []);
  // console.log(activeTickets);
  return !loading && !isEmpty(eventInfo) ? (
    <>
      <PageSeo {...eventInfo?.seo} />
      {eventInfo?.eventPageVersion === 'v2' ? (
        <>
          <BookHeader {...eventInfo} handleOpen={handleOpen} />
          {!isEmpty(eventGeoLocation) && <ExicitingEvent />}
        </>
      ) : eventInfo?.eventPageVersion === 'v3' ? (
        <>
          <BookHeader_Testing
            {...eventInfo}
            handleOpen={handleOpen}
            dates={activeDates}
            handleEventDateChange={handleEventDateChange}
            ticket={activeTickets}
          />
          {!isEmpty(eventGeoLocation) && <ExicitingEvent />}
        </>
      ) : (
        <>
          <BookingHeader {...eventInfo} />
          <TicketPrices handleOpen={handleOpen} />
        </>
      )}
      {/* {eventInfo?.eventPageVersion === 'v2' ? (
        <>
          <BookHeader {...eventInfo} handleOpen={handleOpen} dates={activeDates} handleEventDateChange={handleEventDateChange} ticket={activeTickets} />
          {!isEmpty(eventGeoLocation) && <ExicitingEvent />}
        </>
      ) : (
        <>
          <BookingHeader {...eventInfo} />
          <TicketPrices handleOpen={handleOpen} />
        </>
      )} */}
    </>
  ) : (
    <Loader />
  );
};
const mapStateToProps = (state) => ({
  eventInfo: get(state, 'event.eventInfo', {}),
  loading: get(state, 'event.loading', false),
  eventGeoLocation: get(state, 'event.eventGeoLocation', {}),
  activeDates: get(state, 'event.activeDates', []),
  activeTickets: get(state, 'event.activeTickets', {}),
});

export default connect(mapStateToProps, {
  getEventGeoLocation,
  fetchActiveDates,
  fetchActiveTickets,
})(memo(Booking));
