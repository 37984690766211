export const loadScript = (src) => {
  return new Promise((resovle) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resovle(true);
    };
    script.onerror = () => {
      resovle(false);
    };
    document.body.appendChild(script);
  });
};

export const percentage = (partialValue, totalValue) => {
  if (partialValue && totalValue) {
    return (totalValue * partialValue) / 100;
  }
};

export const mimNumber = (maxSize, maxBookingsAllowed) => {
  if (maxBookingsAllowed) {
    return Math.min(maxSize, maxBookingsAllowed);
  }
  return maxSize;
};
