import React from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { newUser } from '../../redux/actions/userActions'
import TextField from '../../utils/TextField'
import { signin } from '../../data'
import { get } from 'lodash'
const INITIAL_FORM_STATE = {
  name: '',
  email: '',
}
const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .test('email', 'Invalid email', (email) => {
      const tester =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!email) return false
      if (!tester.test(email.trim().toLowerCase())) return false
      return true
    })
    .max(45, 'Invalid Email')
    .required('Please enter your Email'),
})
const UserName = ({ loading, error, newUser }) => {
  return (
    <>
      <Formik
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={async (values, { resetForm }) => {
          const userdata = { name: values.name, email: values.email.trim() }
          await newUser(userdata)
          resetForm({ values: '' })
        }}
      >
        <Form className="space-y-6">
          <TextField
            label="Your Name"
            name="name"
            type="text"
            placeholder="Enter Name"
            inputFocus={true}
          />
          <TextField
            label="Your Email"
            name="email"
            type="text"
            placeholder="Enter Email"
          />
          <button
            type="submit"
            className="w-full text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            disabled={loading}
          >
            {loading ? '...' : signin.button}
          </button>
        </Form>
      </Formik>
      {error && error?.code === 'NEW_USER_ERROR' && (
        <p className="m-2 text-sm font-medium text-red-500">{error?.message}</p>
      )}
    </>
  )
}
const mapStateToProps = (state) => ({
  error: get(state, 'auth.error', {}),
  loading: get(state, 'auth.loading', false),
})
export default connect(mapStateToProps, { newUser })(UserName)
