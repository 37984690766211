import React from 'react';
import { loader } from '../../data';

const Loader = () => {
  const circleCommonClasses = 'h-5 w-5 rounded-full';
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="absolute mb-6 flex">
        <div className={`${circleCommonClasses} mr-1 animate-bounce bg-[#ca4856]`} />
        <div className={`${circleCommonClasses} mr-1 animate-bounce200 bg-[#ecbc44]`} />
        <div className={`${circleCommonClasses} animate-bounce400 bg-[#82dda2]`} />
      </div>
      <h3 className="mt-6 flex text-black">{loader?.title}</h3>
    </div>
  );
};

export default Loader;
