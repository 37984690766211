import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import CTCarousel from '../../common/CTCarousel';

const Holi = ({ category1Events }) => {
  return (
    <div>
      <CTCarousel
        title={'Holi Events'}
        subTitle={'Explore what’s going on around you!'}
        data={category1Events}
        link={'allevents/holi'}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  category1Events: get(state, 'event.category1Events', []),
});

export default connect(mapStateToProps)(Holi);
