import React from 'react';
import CTWrapper from './CTWrapper';
import CategoryCard from './CategoryCard';
import { isEmpty } from 'lodash';

const Box = ({ title, subTitle, data }) => {
  return (
    <CTWrapper className={'flex flex-col gap-5 pb-10'}>
      <div className="flex flex-col gap-2">
        <h2 className="text-xl font-bold text-black md:text-2xl">{title}</h2>
        <h4 className="text-base font-medium tracking-normal text-[#7A7C82]">{subTitle}</h4>
      </div>
      <div className="grid w-full max-w-7xl grid-cols-2 gap-2 md:grid-cols-3 md:gap-5 lg:grid-cols-6 lg:gap-4 xl:grid-cols-6 xl:gap-5">
        {!isEmpty(data) &&
          Array.isArray(data) &&
          data.map((item, index) => <CategoryCard {...item} key={index} urlHidden={false} />)}
      </div>
    </CTWrapper>
  );
};

export default Box;
