import React from 'react';
import GoogleMapReact from 'google-map-react';
import { LocationMarkerIcon } from '@heroicons/react/solid';

const LocationMap = ({ location }) => {
  const [lng, lat] = location;
  const defaultCenter = { lng, lat };
  const defaultZoom = 15;
  // console.log(eventName);

  return (
    <div style={{ height: '200px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        google={window.google}
        loadingElement={<div style={{ height: '100%' }} />}
      >
        <Marker
          lat={lat}
          lng={lng}
          // text={eventName}
        />
      </GoogleMapReact>
    </div>
  );
};

const Marker = () => (
  <div
    style={{
      color: 'red',
      // background: 'red',
      padding: '3px 3px',
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      // borderRadius: '100%',
      transform: 'translate(-50%, -50%)',
    }}
  >
    {/* {text}  */}
    <LocationMarkerIcon className="size-10" />
  </div>
);

export default LocationMap;
