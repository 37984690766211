import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import CTInnerCarousel from '../common/CTInnerCarousel';

const ExicitingEvent = ({ eventGeoLocation }) => {
  return (
    <div>
      <CTInnerCarousel title={'Discover Exciting Plans!'} subTitle={''} data={eventGeoLocation} link={'/allevents'} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  eventGeoLocation: get(state, 'event.eventGeoLocation', {}),
});

export default connect(mapStateToProps)(ExicitingEvent);
