import React from 'react'
import { sendOtp } from '../../redux/actions/userActions'
import { connect } from 'react-redux'
import { Form, Formik } from 'formik'
import { signin } from '../../data'
import * as Yup from 'yup'
import TextField from '../../utils/TextField'
import { get } from 'lodash'
const INITIAL_FORM_STATE = {
  mobile: '',
}
const FORM_VALIDATION = Yup.object().shape({
  mobile: Yup.number()
    .integer()
    .typeError('Please enter a valid mobile number')
    .required('Required mobile number'),
})
const OtpPhoneNumber = ({ sendOtp, error, loading }) => {
  return (
    <>
      <Formik
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values, { resetForm }) => {
          const user = { mobile: values.mobile.trim(), role: 'C' }
          sendOtp(user)
          resetForm({ values: '' })
        }}
      >
        <Form className="space-y-6">
          <TextField
            label={signin.mobileInputLabel}
            name="mobile"
            type="text"
            placeholder={signin.mobileInputPlaceholder}
            inputFocus={true}
          />
          <button
            type="submit"
            className="w-full text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
            disabled={loading}
          >
            {loading ? '...' : signin.button}
          </button>
        </Form>
      </Formik>
      {error && error?.code === 'SEND_OTP_ERROR' && (
        <p className="m-2 text-sm font-medium text-red-500">{error?.message}</p>
      )}
    </>
  )
}
const mapStateToProps = (state) => ({
  error: get(state, 'auth.error', {}),
  loading: get(state, 'auth.loading', false),
})
export default connect(mapStateToProps, { sendOtp })(OtpPhoneNumber)
