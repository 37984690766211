import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { NODE_ENV } from '../config/endPoints'
import reducers from './reducers/redcuers'

const bindMiddlware = (middlware) => {
  if (NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middlware))
  }
  return applyMiddleware(...middlware)
}

const saveToLocalStorage = (state) => {
  try {
    const serialisedState = JSON.stringify(state)
    if (typeof window !== 'undefined') {
      localStorage.setItem('persistantState', serialisedState)
    }
  } catch (e) {
    console.warn(e)
  }
}

const loadFromLocalStorage = () => {
  try {
    const serialisedState = localStorage.getItem('persistantState')
    if (serialisedState === null) return undefined
    return JSON.parse(serialisedState)
  } catch (e) {
    console.warn(e)
    return undefined
  }
}

const store = createStore(
  reducers,
  loadFromLocalStorage(),
  bindMiddlware([thunkMiddleware]),
)
store.subscribe(() => saveToLocalStorage(store.getState()))

export default store
