import React from 'react';
import graphics from '../../../Images/merchant/graphics.svg';
import { CTImage, CTWrapper } from '../../common';
import { merchantappsection } from '../../../data';
import Rating from './Rating';

const AppSection = () => {
  return (
    <div className={'max-w-full bg-black'}>
      <div
        className="relative flex flex-col bg-cover object-cover object-center xl:block"
        style={{ backgroundImage: `url(${graphics})` }}
      >
        <CTWrapper className="flex flex-col items-center justify-center gap-10 py-10 md:flex-row md:gap-20 md:py-20 lg:gap-40">
          {merchantappsection.apps.map((app, index) => (
            <div key={index} className="flex flex-col items-center gap-4">
              <CTImage src={app.img} alt={app.alt} className="size-16 lg:size-24" />
              <Rating rating={app.rating} />
            </div>
          ))}
        </CTWrapper>
      </div>
    </div>
  );
};

export default AppSection;

{
  /* <CTImage src={logo3} alt="img 3" className="-mx-20 md:-mx-32 lg:-mx-20 xl:mx-0" />
          <CTImage src={logo2} alt="img 2" className="-mx-20  md:-mx-32 lg:-mx-20 xl:mx-0" /> */
}
