import { get, isEmpty } from 'lodash'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  authenticated,
  clearErrors,
  getEvent,
} from '../../redux/actions/userActions'
import OtpNumber from './OtpNumber'
import UserName from './UserName'
const VerfiyOtpNumber = ({
  handleClose,
  clearErrors,
  user,
  authenticated,
  eventInfo,
  getEvent,
  isAuthenticated,
}) => {
  const [newUser, setNewUser] = useState(false)
  useEffect(() => {
    const users = async () => {
      if (!isEmpty(user)) {
        if (user?.name || user?.email) {
          await authenticated()
          if (!isEmpty(eventInfo) && isAuthenticated) {
            await getEvent({ eventId: eventInfo?._id, skip: 0 })
          }
          clearErrors()
          handleClose()
        } else {
          setNewUser(true)
        }
      }
    }
    users()
  }, [
    user,
    isAuthenticated,
    clearErrors,
    handleClose,
    authenticated,
    eventInfo,
  ])
  return <>{!newUser ? <OtpNumber /> : <UserName />}</>
}
const mapStateToProps = (state) => ({
  otpId: get(state, 'auth.otpId', null),
  error: get(state, 'auth.error', {}),
  loading: get(state, 'auth.loading', false),
  user: get(state, 'auth.user', {}),
  isAuthenticated: get(state, 'auth.isAuthenticated', false),
  eventInfo: get(state, 'event.eventInfo', {}),
})

export default connect(mapStateToProps, {
  clearErrors,
  authenticated,
  getEvent,
})(VerfiyOtpNumber)
