import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchEventbyCategory, getInfoCategory, getEventSlider } from '../redux/actions/eventActions';
import useGeoLocation from '../utils/useGeoLocation';
import { get, isEmpty } from 'lodash';
import Loader from '../components/common/Loader';
import { useParams } from 'react-router-dom';
import AllCTCarousel from '../components/common/AllCTCarousel';
import EventSlider from '../components/Home/EventSlider';
const CategoryPage = ({
  loading,
  fetchEventbyCategory,
  categoryInfo,
  getInfoCategory,
  eventsbycategory,
  getEventSlider,
  eventSlider,
}) => {
  const location = useGeoLocation();
  const skip = 0;
  const limit = 100;

  useEffect(() => {
    if (!isEmpty(location) && !location?.loaded) {
      const coordinates = [location?.coordinates?.lng, location?.coordinates?.lat];
      getEventSlider({ coordinates, skip, limit });
    }
  }, [location, skip, limit]);
  const { categoryId } = useParams();
  useEffect(() => {
    getInfoCategory(categoryId);
  }, [categoryId]);
  useEffect(() => {
    if (!isEmpty(location) && !location?.loaded) {
      const coordinates = [location?.coordinates?.lng, location?.coordinates?.lat];
      fetchEventbyCategory({ categoryId, coordinates });
    }
  }, [categoryId, location]);
  if (loading || !Array.isArray(eventsbycategory)) {
    return <Loader initial={true} />;
  }

  const categoryName = categoryInfo.length > 0 ? categoryInfo[0].name : '';
  return (
    <div>
      {!isEmpty(eventSlider) && <EventSlider />}
      {loading && <Loader initial={true} />}
      {!loading && (
        <AllCTCarousel
          title={`${categoryName} Events`}
          subTitle={'Explore what’s going on around you!'}
          msg={
            "World Is Full Of Excitement, And You've Explored Just A Fraction Of What We Have To Offer. Keep Exploring For Other Exciting Events."
          }
          data={eventsbycategory}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  categoryInfo: get(state, 'event.categoryInfo', {}),
  loading: get(state, 'event.loading', false),
  eventsbycategory: get(state, 'event.eventsbycategory', []),
  eventSlider: get(state, 'event.eventSlider', []),
});

export default connect(mapStateToProps, {
  getInfoCategory,
  fetchEventbyCategory,
  getEventSlider,
})(CategoryPage);
