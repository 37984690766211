import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import NewEventCard from './NewEventCard';
import CTWrapper from './CTWrapper';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from './Loader';

const AllCTCarousel = ({ data, title, subTitle, urlHidden, msg }) => {
  const [itemsPerPage, setItemsPerPage] = useState(6);

  const loadMore = () => {
    setItemsPerPage((prevItemsPerPage) => prevItemsPerPage + 6);
  };

  const renderedData = useMemo(() => {
    return data && !isEmpty(data) ? data.slice(0, itemsPerPage) : [];
  }, [data, itemsPerPage]);

  return (
    <section className="bg-white py-5 pb-10 text-black">
      <CTWrapper className="flex flex-col pb-4">
        <header className="flex flex-row items-center justify-center">
          <h2 className="text-center text-2xl font-bold text-rose-600">{title}</h2>
        </header>
        <div className="mb-6 mt-2 flex justify-center md:mt-0">
          <h4 className="text-center text-base font-medium tracking-normal text-[#7A7C82]">{subTitle}</h4>
        </div>
        <div className="flex w-full flex-col lg:min-h-96">
          <InfiniteScroll
            dataLength={itemsPerPage}
            next={loadMore}
            hasMore={itemsPerPage < data.length}
            loader={<Loader />}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>{msg}</b>
              </p>
            }
          >
            <div className="flex flex-wrap justify-center gap-4 md:gap-8 lg:gap-12">
              {renderedData.map((item, index) => (
                <div key={index} className="mb-4 shrink-0">
                  <NewEventCard {...item} urlHidden={urlHidden} />
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </div>
      </CTWrapper>
    </section>
  );
};

AllCTCarousel.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  urlHidden: PropTypes.bool,
  msg: PropTypes.string.isRequired,
};

export default React.memo(AllCTCarousel);
