import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const TransactionStatus = ({ paymentInfo, eventInfo }) => {
  const history = useNavigate();
  const goToHome = () => {
    history(`/${eventInfo?.eventCode}`);
  };
  const goToMyBooking = () => {
    history(`/${eventInfo?.eventCode}/mybooking`);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="mx-auto mt-20 h-screen w-full md:w-1/3">
      <div className="flex flex-col bg-white p-5">
        <div className="flex flex-col items-center justify-center text-center">
          <div
            className={`inline-block p-4 ${
              paymentInfo?.transactionSuccessful ? 'bg-green-50' : 'bg-red-50'
            } rounded-full`}
          >
            <svg
              className={`size-12 fill-current ${
                paymentInfo?.transactionSuccessful ? 'text-green-500' : 'text-red-500'
              }`}
              viewBox="0 0 20 20"
            >
              {paymentInfo?.transactionSuccessful ? (
                <path d="M10.219,1.688c-4.471,0-8.094,3.623-8.094,8.094s3.623,8.094,8.094,8.094s8.094-3.623,8.094-8.094S14.689,1.688,10.219,1.688 M10.219,17.022c-3.994,0-7.242-3.247-7.242-7.241c0-3.994,3.248-7.242,7.242-7.242c3.994,0,7.241,3.248,7.241,7.242C17.46,13.775,14.213,17.022,10.219,17.022 M15.099,7.03c-0.167-0.167-0.438-0.167-0.604,0.002L9.062,12.48l-2.269-2.277c-0.166-0.167-0.437-0.167-0.603,0c-0.166,0.166-0.168,0.437-0.002,0.603l2.573,2.578c0.079,0.08,0.188,0.125,0.3,0.125s0.222-0.045,0.303-0.125l5.736-5.751C15.268,7.466,15.265,7.196,15.099,7.03" />
              ) : (
                <path d="M10.185,1.417c-4.741,0-8.583,3.842-8.583,8.583c0,4.74,3.842,8.582,8.583,8.582S18.768,14.74,18.768,10C18.768,5.259,14.926,1.417,10.185,1.417 M10.185,17.68c-4.235,0-7.679-3.445-7.679-7.68c0-4.235,3.444-7.679,7.679-7.679S17.864,5.765,17.864,10C17.864,14.234,14.42,17.68,10.185,17.68 M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z" />
              )}
            </svg>
          </div>
          <h2 className="mt-2 font-semibold text-gray-800">{paymentInfo?.userText}</h2>
          <p className="mt-2 text-sm leading-relaxed text-gray-600">{paymentInfo?.transactionText}</p>
        </div>
        <div className="mt-3 flex items-center">
          <button
            onClick={goToHome}
            className="flex-1 rounded-md bg-gray-100 px-4 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200"
          >
            Go to Home
          </button>
          {paymentInfo?.transactionSuccessful && (
            <button
              onClick={goToMyBooking}
              className="ml-2 flex-1 rounded-md bg-yellow-500 px-4 py-2 text-sm font-medium text-white hover:bg-yellow-600"
            >
              View Your Ticket
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  paymentInfo: get(state, 'auth.payment', null),
  eventInfo: get(state, 'event.eventInfo', {}),
});

export default connect(mapStateToProps)(TransactionStatus);
