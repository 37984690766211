import { razorpay } from '../../../data';
import React, { useState, useEffect, useMemo, useCallback, memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { loadScript, percentage } from '../../../config/common';
// import CouponForm from './CouponForm';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { removeEventItem, removeNewAnswer } from '../../../redux/actions/eventActions';
import { getEvent, seatVerifyStatusFalse, setSeatVerify } from '../../../redux/actions/userActions';
import { cashfreeSandbox, cashfreeProd } from 'cashfree-pg-sdk-javascript';
import Cashfree from '../../../utils/Cashfree';
import { RAZORPAY_KEY, RAZORPAY_SCRIPT, NODE_ENV, PASSES_ALL_CHECK } from '../../../config/endPoints';
import MessageDialog from '../../MessageDialog';
import NewUser from '../../NewUser';
import FirstComeFirstServe from './FirstComeFirstServe';
import BookingClose from '../../BookingClose';
import QuestionsDialog from '../../QuestionsDialog';
import instance from '../../../utils/instance';

//import { ArrowCircleRightIcon } from "@heroicons/react/solid";

const CheckoutPart = ({
  token,
  isAuthenticated,
  // event,
  handleOpen,
  eventInfo,
  setSeatVerify,
  seatVerify,
  user,
  error,
  removeEventItem,
  seatVerifySuccess,
  seatVerifyStatusFalse,
  removeNewAnswer,
  getEvent,
}) => {
  const [allowNavigate, setAllowNavigate] = React.useState(false);
  const [newUser, setNewUser] = React.useState(false);
  const [cashFree, setCashFree] = React.useState(false);
  const [isError, setError] = React.useState(false);
  const [cashFreeError, setCashFreeError] = React.useState(null);
  const [loginUser, setLoginUser] = React.useState(false);
  const [bookingClose, setBookingClose] = React.useState(false);
  const [questionClose, setQuestionClose] = React.useState(false);
  const [errorMobileMessage, setErrorMobileMessage] = React.useState(null);
  const [eventDiscountAmount, setEventDiscountAmount] = useState(0);
  const [eventCouponCode, setEventCouponCode] = useState();
  const { type } = useParams();

  const totalPrice = useMemo(() => {
    if (!eventInfo) return 0;
    return eventInfo.ticketTypes.reduce((acc, item) => acc + item.quantity * item.price, 0);
  }, [eventInfo]);

  const totalQty = useMemo(() => {
    if (!eventInfo) return 0;
    return eventInfo.ticketTypes.reduce((acc, item) => acc + Number(item.quantity), 0);
  }, [eventInfo]);

  const CGST = useMemo(() => {
    if (!eventInfo || !eventInfo.cgstPercentage || !totalPrice) return 0;
    return percentage(eventInfo.cgstPercentage, totalPrice);
  }, [eventInfo, totalPrice]);

  const SGST = useMemo(() => {
    if (!eventInfo || !eventInfo.sgstPercentage || !totalPrice) return 0;
    return percentage(eventInfo.sgstPercentage, totalPrice);
  }, [eventInfo, totalPrice]);

  const convenienceFee = useMemo(() => {
    return eventInfo?.convenienceFee || 0;
  }, [eventInfo]);

  const bookingPercentage = useMemo(() => {
    if (!eventInfo || !eventInfo.bookingPercentage || !totalPrice) return 0;
    return percentage(eventInfo.bookingPercentage, totalPrice);
  }, [eventInfo, totalPrice]);

  const subTotal = totalPrice + CGST + SGST + convenienceFee + bookingPercentage;
  // const toggleDiscount = (percentage) => {
  //   const discount = (percentage / 100) * (subTotal);
  //   setEventDiscountAmount(discount);
  // };
  const eventDiscount = parseFloat(eventDiscountAmount.toFixed(2));

  const eventCoupon = eventCouponCode;

  const totalPayAmount = useMemo(() => {
    return parseFloat((totalPrice + CGST + SGST + convenienceFee + bookingPercentage - eventDiscount).toFixed(2));
  }, [totalPrice, CGST, SGST, convenienceFee, bookingPercentage, eventDiscount]);
  const history = useNavigate();
  const handSubmit = useCallback(async () => {
    // console.log(totalPayAmount, 'pay');
    await removeNewAnswer();
    if (
      !isEmpty(eventInfo) &&
      eventInfo?.onlineBookingEndText &&
      eventInfo?.onlineBookingEndTime &&
      eventInfo?.onlineBookingEndTime < new Date().getTime()
    ) {
      setBookingClose(true);
    } else {
      if (!token && !isAuthenticated) {
        handleOpen();
        setLoginUser(true);
      } else {
        if (!isEmpty(eventInfo) && totalPrice && totalQty) {
          setLoginUser(true);
        }
      }
    }
  }, [
    token,
    isAuthenticated,
    eventInfo,
    handleOpen,
    setSeatVerify,
    bookingPercentage,
    eventDiscount,
    eventCoupon,
    totalPrice,
    convenienceFee,
    totalPayAmount,
    totalQty,
    CGST,
    SGST,
  ]);

  useMemo(() => {
    if (loginUser && !isEmpty(eventInfo) && totalPrice && totalQty && isAuthenticated && token) {
      const loginDone = async () => {
        if (!isEmpty(eventInfo) && isEmpty(eventInfo.extraQuestions)) {
          let donationAmount = [];
          if (eventInfo?.newExtraQuestions || eventInfo?.newExtraQuestions !== '') {
            donationAmount = eventInfo?.newExtraQuestions
              ?.filter((item) => item.answerType === 'DONATION')
              .map((item) => item.answer);
          }
          const newdonationAmount = !isEmpty(donationAmount) && donationAmount[0] !== undefined ? donationAmount[0] : 0;
          const data = {
            originalAmount: totalPrice,
            paymentAmount: totalPayAmount + newdonationAmount,
            convenienceAmount: convenienceFee,
            bookingAmount: bookingPercentage,
            specialDiscountAmount: eventDiscount,
            specialEventCouponCode: eventCoupon,
            donationAmount: newdonationAmount,
            taxAmount: CGST + SGST,
            eventId: eventInfo?._id,
            walletPart: 0,
            eventName: eventInfo?.name,
            numberOfSeats: totalQty,
            ticketTypeArray: eventInfo?.ticketTypes.map(({ code, name, quantity }) => ({ code, name, quantity })),
            selfPart: totalPayAmount + newdonationAmount,
            extraQuestions: eventInfo?.newExtraQuestions,
          };
          if (!isEmpty(type) && type === 'fydo-customer-app' && !isEmpty(data)) {
            try {
              const res = await instance({
                method: 'POST',
                url: PASSES_ALL_CHECK,
                data,
              });
              if (!isEmpty(res)) {
                const { passedChecks } = res.data;
                if (passedChecks === false) {
                  console.log('!res?.data?.passedChecks', !res?.data?.passedChecks);
                  setError(true);
                  setErrorMobileMessage(res?.data);
                } else {
                  window && window?.ReactNativeWebView?.postMessage(JSON.stringify(data));
                }
              }
            } catch (error) {
              console.log('error', error);
            }
          } else {
            await setSeatVerify(data).then(() => setAllowNavigate(true), setCashFree(true));
          }
        } else if (!isEmpty(eventInfo) && eventInfo?.newExtraQuestions) {
          const donationAmount = eventInfo?.newExtraQuestions
            ?.filter((item) => item.answerType === 'DONATION')
            .map((item) => item.answer);
          const newdonationAmount = !isEmpty(donationAmount) && donationAmount[0] !== undefined ? donationAmount[0] : 0;
          const data = {
            originalAmount: totalPrice,
            paymentAmount: totalPayAmount + newdonationAmount,
            convenienceAmount: convenienceFee,
            bookingAmount: bookingPercentage,
            specialDiscountAmount: eventDiscount,
            specialEventCouponCode: eventCoupon,
            donationAmount: newdonationAmount,
            taxAmount: CGST + SGST,
            eventId: eventInfo?._id,
            walletPart: 0,
            eventName: eventInfo?.name,
            numberOfSeats: totalQty,
            ticketTypeArray: eventInfo?.ticketTypes.map(({ code, name, quantity }) => ({ code, name, quantity })),
            selfPart: totalPayAmount + newdonationAmount,
            extraQuestions: eventInfo?.newExtraQuestions,
          };
          if (!isEmpty(type) && type === 'fydo-customer-app' && !isEmpty(data)) {
            try {
              const res = await instance({
                method: 'POST',
                url: PASSES_ALL_CHECK,
                data,
              });
              if (!isEmpty(res)) {
                const { passedChecks } = res.data;
                if (passedChecks === false) {
                  setError(true);
                  setErrorMobileMessage(res?.data);
                } else {
                  window && window?.ReactNativeWebView?.postMessage(JSON.stringify(data));
                }
              }
            } catch (error) {
              console.log('error', error);
            }
          } else {
            await setSeatVerify(data).then(() => setAllowNavigate(true), setCashFree(true));
          }
        } else {
          setQuestionClose(true);
        }
      };
      loginDone();
    }
  }, [
    loginUser,
    eventInfo,
    totalPrice,
    totalPayAmount,
    convenienceFee,
    totalQty,
    CGST,
    SGST,
    bookingPercentage,
    eventDiscount,
    eventCoupon,
    setSeatVerify,
    token,
    isAuthenticated,
    type,
  ]);
  const paymentHandler = useCallback(async () => {
    if (allowNavigate && !isEmpty(seatVerify) && !isEmpty(eventInfo)) {
      const paymentData = {
        amount: totalPayAmount,
        orderId: seatVerify?.transactionId,
        razorpayOrderId: seatVerify?.razorpayOrderId,
        name: user?.name,
        mobile: user?.mobile,
        email: user?.email,
      };
      if (!isEmpty(seatVerify) && seatVerify?.transactionValid) {
        if (seatVerify?.paymentGateway === 'RZP') {
          const res = await loadScript(RAZORPAY_SCRIPT);
          if (!res) {
            alert('You are offline... Failed to load Razorpay SDK');
            return;
          }
          const options = {
            key: RAZORPAY_KEY,
            amount: paymentData?.amount * 100,
            currency: razorpay?.currency || 'INR',
            name: razorpay?.name,
            description: razorpay?.description,
            image: razorpay?.image,
            order_id: paymentData?.razorpayOrderId,
            handler: async () => {
              history(`/${eventInfo?.eventCode}/paymentstatus`);
            },
            prefill: {
              name: paymentData?.name,
              contact: paymentData?.mobile,
            },
          };
          const rzp1 = new window.Razorpay(options);
          rzp1.on('payment.failed', () => {
            history(`/${eventInfo?.eventCode}/paymentstatus`);
          });
          rzp1.open();
        } else if (seatVerify?.paymentGateway === 'CASHFREE') {
          try {
            const parent = document.getElementById('drop_in_container');
            if (!isEmpty(parent) && seatVerify?.paymentSessionId) {
              parent.innerHTML = '';
              let cashfree;
              if (NODE_ENV === 'production') {
                cashfree = new cashfreeProd.Cashfree(seatVerify?.paymentSessionId);
              } else {
                cashfree = new cashfreeSandbox.Cashfree(seatVerify?.paymentSessionId);
              }
              const dropConfig = {
                components: [
                  'order-details',
                  'card',
                  'netbanking',
                  'app',
                  'upi',
                  'paylater',
                  'credicardemi',
                  'cardlessemi',
                ],
                paymentSessionId: seatVerify?.paymentSessionId,
                orderId: seatVerify?.transactionId,
                onSuccess: (data) => {
                  removeEventItem();
                  if (data?.order && data?.order?.status === 'PAID') {
                    // history(`/${eventInfo?.eventCode}/paymentstatus`)
                    window.open(`/${eventInfo?.eventCode}/paymentstatus`, '_self');
                  } else {
                    // history(`/${eventInfo?.eventCode}/paymentstatus`)
                    window.open(`/${eventInfo?.eventCode}/paymentstatus`, '_self');
                  }
                },
                onFailure: (data) => {
                  if (data?.order && data.order.status === 'ACTIVE' && data?.transaction?.txStatus === 'FAILED') {
                    history(`/${eventInfo?.eventCode}/paymentstatus`);
                    removeEventItem();
                  }
                  setCashFreeError(data?.order?.errorText);
                },
                style: {
                  backgroundColor: '#ffffff',
                  color: '#11385b',
                  fontFamily: 'Lato',
                  fontSize: '14px',
                  errorColor: '#ff0000',
                  theme: 'light',
                },
              };
              cashfree.drop(parent, dropConfig);
            }
          } catch (error) {
            console.log('error-->', error);
          }
        }
      } else if (!isEmpty(seatVerify) && seatVerify?.isFreeEvent && seatVerify?.bookingDetails?.ticketCode) {
        await getEvent({ eventId: eventInfo?._id, skip: 0 });
        history(`/ticket/${seatVerify?.bookingDetails?.ticketCode}`);
      } else {
        setCashFree(false);
        setError(true);
      }
    }
  }, [seatVerify, user, history, totalPayAmount, eventInfo, removeEventItem, allowNavigate]);

  useEffect(() => {
    if (allowNavigate && seatVerifySuccess) {
      paymentHandler();
    }
  }, [allowNavigate, seatVerifySuccess, paymentHandler]);
  useEffect(() => {
    if (!isEmpty(cashFreeError)) {
      const timeId = setTimeout(() => {
        setCashFreeError(null);
      }, 4000);
      return () => {
        clearTimeout(timeId);
      };
    }
  }, [cashFreeError, history]);
  useEffect(() => {
    if (!isEmpty(error) && error.code === 'SEAT_VERIFY_ERROR') {
      history('/error');
    }
  }, [error, history]);
  useEffect(() => {
    if (isAuthenticated && token) {
      if (!user?.email || !user?.name) {
        setNewUser(true);
      }
    }
  }, [user, isAuthenticated, token]);

  const handleViewTicket = () => {
    history(`/${eventInfo?.eventCode}/mybooking`);
  };
  const handleNewUserClose = () => {
    setNewUser(false);
  };
  const handleCashFreeClose = async () => {
    await seatVerifyStatusFalse();
    await removeEventItem();
    document.getElementById('drop_in_container').remove();
    setCashFreeError(null);
    setCashFree(false);
    setLoginUser(false);
    setQuestionClose(false);
    window.location.reload(false);
  };
  const handleErrorClose = async () => {
    await removeEventItem();
    await seatVerifyStatusFalse();
    setLoginUser(false);
    setBookingClose(false);
    setQuestionClose(false);
    setError(false);
    setErrorMobileMessage(null);
    if (!isEmpty(type) && type !== 'fydo-customer-app') {
      window.location.reload(false);
    }
  };
  const handleBookingClose = async () => {
    await removeEventItem();
    await seatVerifyStatusFalse();
    setLoginUser(false);
    setError(false);
    setBookingClose(false);
    setQuestionClose(false);
  };
  const handleQuestionClose = async () => {
    setQuestionClose(false);
    await removeEventItem();
    await seatVerifyStatusFalse();
    setLoginUser(false);
    setBookingClose(false);
    setQuestionClose(false);
    setError(false);
  };
  return (
    <>
      <div className="items-left flex  flex-col items-center justify-center gap-4 md:hidden">
        <div className="flex flex-col items-center gap-2 mm:flex-row">
          <p className="flex-1 text-lg font-bold md:text-xl lg:text-3xl ">{eventInfo?.name}</p>
          <img
            className="aspect-[18/10] h-[75px] w-full flex-1 rounded-xl	shadow-lg md:hidden md:aspect-[25/10]"
            src={eventInfo?.poster[0]?.url}
            alt={eventInfo?.name}
          />
        </div>

        <div className="mx-auto border-b bg-gray-600 sm:w-full lg:w-[304px]" />
        <p className="text-center text-sm tracking-wide text-secondary">
          {eventInfo?.eventAddress} • {eventInfo?.dateString} • {eventInfo?.eventTime}
        </p>
      </div>
      <div className="hidden items-center justify-between gap-6 md:flex">
        <div className="flex flex-1 flex-col items-start gap-4">
          <p className="flex font-bold md:text-xl lg:text-2xl ">{eventInfo?.name}</p>
          <div className="mx-auto border-b bg-gray-600 sm:w-full lg:w-[304px]" />
          <p className="text-sm tracking-wide text-secondary lg:text-base">
            {eventInfo?.eventAddress} • {eventInfo?.dateString} • {eventInfo?.eventTime}
          </p>
        </div>
        <div className="flex flex-1 items-center justify-center">
          <img
            className="hidden rounded-xl	shadow-lg md:mt-6 md:flex md:aspect-video lg:mt-0"
            src={eventInfo?.poster[0]?.url}
            alt={eventInfo?.name}
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:gap-8">
        {eventInfo?.eventType === 'FCFS' || eventInfo?.eventType === 'FREE_FCFS' ? (
          <FirstComeFirstServe
            totalPrice={totalPrice}
            totalPayAmount={totalPayAmount}
            SGST={SGST}
            CGST={CGST}
            convenienceFee={convenienceFee}
            bookingPercentage={bookingPercentage}
            totalAmount={subTotal}
            //toggleDiscount={toggleDiscount}
            setEventCouponCode={setEventCouponCode}
            eventDiscountAmount={eventDiscountAmount}
            setEventDiscountAmount={setEventDiscountAmount}
            handSubmit={handSubmit}
            handleOpen={handleOpen}
            handleViewTicket={handleViewTicket}
          />
        ) : (
          <div className="flex w-full ">
            <div className="flex w-full flex-col items-center justify-center text-center ">
              {eventInfo?.ticketTypes.map((item, index) => (
                <div
                  className="my-4 flex w-full flex-col items-start gap-2 rounded-lg border  border-gray-200 p-2 md:gap-4"
                  key={index}
                >
                  <div className="flex flex-col items-start ">
                    <p className="text-lg font-bold">{item?.name} </p>
                    {item?.desc && (
                      <p className="md:text-md text-left text-sm text-secondary md:text-center">({item?.desc})</p>
                    )}
                  </div>
                  <div className="mx-auto w-full border-b bg-gray-600" />
                  <div className="flex w-full items-center justify-between">
                    <div className="text-lg font-bold tracking-wide md:text-2xl">₹{item.price}/-</div>
                    <div>
                      <button
                        className="flex size-[25px] items-center justify-center rounded-[5px] bg-[#F2F4F7] px-3 py-1 text-[#000] md:size-[32px]"
                        onClick={() => {
                          decreasePerson(item);
                        }}
                        disabled={!item?.quantity === 0}
                      >
                        <p className="m-0 p-0 text-sm  md:text-lg"> - </p>
                      </button>
                      <p className=" text-medium px-2 text-sm text-black md:text-lg">{item?.quantity}</p>
                      <button
                        className="flex  size-[25px] items-center justify-center rounded-[5px] bg-btnPrimary px-3 py-1 text-white md:size-[32px]"
                        onClick={() => {
                          increasePerson(item);
                        }}
                      >
                        <p className="m-0 p-0 text-sm  md:text-lg"> + </p>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {isError && !isEmpty(seatVerify) && !seatVerify?.transactionValid && (
        <MessageDialog isOpen={isError} handleClose={handleErrorClose} message={seatVerify} />
      )}
      {!isEmpty(type) && type === 'fydo-customer-app' && !isEmpty(errorMobileMessage) && (
        <MessageDialog isOpen={isError} handleClose={handleErrorClose} message={errorMobileMessage} />
      )}
      {newUser && <NewUser isOpen={newUser} handleClose={handleNewUserClose} />}

      {cashFree && !isEmpty(seatVerify) && seatVerify?.paymentGateway === 'CASHFREE' && (
        <Cashfree isOpen={cashFree} error={cashFreeError} handleClose={handleCashFreeClose} />
      )}
      {bookingClose && <BookingClose isOpen={bookingClose} handleClose={handleBookingClose} />}
      {questionClose && <QuestionsDialog isOpen={questionClose} handleClose={handleQuestionClose} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  token: get(state, 'auth.token', null),
  isAuthenticated: get(state, 'auth.isAuthenticated', false),
  event: get(state, 'auth.event', {}),
  eventInfo: get(state, 'event.eventInfo', {}),
  seatVerify: get(state, 'auth.seatVerify', null),
  loading: get(state, 'auth.seatVerifyLoading', false),
  seatVerifySuccess: get(state, 'auth.seatVerifySuccess', false),
  user: get(state, 'auth.user', null),
  error: get(state, 'auth.error', {}),
});
export default connect(mapStateToProps, {
  setSeatVerify,
  removeNewAnswer,
  removeEventItem,
  seatVerifyStatusFalse,
  getEvent,
})(memo(CheckoutPart));
