import React from 'react';
import { Helmet } from 'react-helmet-async';
import greet from '../../Images/common/logo_.png';
const PageSeo = ({ title, description, keywords, preview }) => {
  const defaultTitle = 'Greet - Book events now!';
  const defaultDescription = 'Greet - Book events now!';
  const defaultKeywords =
    'Greet Event booking,Local events,Nearby events,Event reservation,Event ticket booking,Event calendar,Event finder,Event scheduling,Event organizer,Event discovery,Event listings,Event Search,Upcoming events,Local event calendar,Event booking app,Event Registration,Event directory,Find events near me,Fydo Bhubaneswar,Event booking in Bhubaneswar,Local events in Bhubaneswar,Nearby events in Bhubaneswar,Event reservation in Bhubaneswar,Event ticket booking in Bhubaneswar,Event calendar Bhubaneswar,Event finder Bhubaneswar,Event scheduling Bhubaneswar,Event organizer in Bhubaneswar,Event management Bhubaneswar,Fydo event planning,Event discovery in Bhubaneswar,Event listings in Bhubaneswar,Event search in Bhubaneswar,Upcoming events in Bhubaneswar,Local event calendar Bhubaneswar,Fydo event booking app,Event registration in Bhubaneswar,Event directory Bhubaneswar';

  const titleKeywords = title ? title.split(' ').join(',') + ',' : '';
  const combinedKeywords = `${titleKeywords}${keywords || defaultKeywords}`;

  return (
    <Helmet>
      <title>{title || defaultTitle}</title>
      <meta name="keywords" content={combinedKeywords} />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:url" content="https://greet.fydo.in/" />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:image" content={preview || greet} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content="Greet" />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};

export default PageSeo;
